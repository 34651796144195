import { css } from "@emotion/react";
import {
  black,
  border,
  fontBlack,
  fontBody,
  hoverBlue,
} from "../../../../styles/common.css";

export const checkBoxItem = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;
    &:hover {
      & > div {
        svg {
          background-color: ${hoverBlue};
          transition: 0.2s ease;
        }
      }
    }
    > div {
      align-items: center;
      display: flex;
      svg {
        width: 20px;
        height: 20px;
        padding: 4px;
        border-radius: 50px;
      }
    }
  }
  /* justify-content: center; */
  input {
    display: none;
  }

  label {
    color: ${fontBody};
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
  }

  a {
    color: #777;
    font-size: 0.875em;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  a {
    font-size: 12px;
  }
`;

export const checkboxes = css`
  .checkbox-item {
    &:not(:last-of-type) {
      margin-bottom: 2px;
    }
  }
`;
