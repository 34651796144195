import { css } from "@emotion/react";
import { black06, fontBlack } from "../../../../styles/common.css";

export default css`
  &.embeded_web {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    margin-bottom: 20px;
    height: 127px;
    transition: 0.2s ease;
    /* z-index: -1; */

    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
      transform: scale(1.03);
    }

    .thumbnail {
      min-width: 178px;
      min-height: 128px;
      border-radius: 4px !important;
      background-size: cover !important;
      background-position: center !important;
      margin-right: 40px;

      button {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }

    .contents {
      display: flex;
      flex-direction: column;
      color: ${fontBlack};
      margin: 0;
      width: 100%;

      p {
        &:first-of-type {
          font-weight: 800;
          font-size: 16px;
          line-height: 1.6;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        &:nth-of-type(2) {
          font-weight: 400;
          font-size: 14px;
          line-height: 1.6;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          margin-top: 4px;
        }

        &:last-of-type {
          margin-top: 12px;
          font-weight: 700;
          font-size: 14px;
          line-height: 1.6;

          color: rgba(136, 143, 156, 0.8);
        }
        /* &:not(:last-of-type) {
          margin-bottom: 10px;
        } */
      }
    }
  }

  @media screen and (max-width: 800px) {
    height: 80px;

    .contents {
      justify-content: center;
      align-items: flex-start;
    }

    &.embeded_web {
      height: auto;
      padding: 16px;
      align-items: center;
      flex-direction: column;

      a {
        width: 100%;

        .thumbnail {
          width: 100%;
          padding-top: 60%;
          margin-bottom: 20px;
          min-height: 0px;
          min-width: 78px;
        }
      }
    }
  }
`;
