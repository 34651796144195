export const FORM_ITEM_TYPE = {
	UPLOAD: "upload",
	TEXTAREA: "textarea",
	CHECKBOX: "checkbox",
	TAG: "tag",
	LINKS: "links",
	INPUT: "input",
	TEXT: "text",
	TEXT_TAG: "text_tag",
	TEXT_LINKS: "text_links",
	TEXT_PLATFORM: "text_platform",
	TEXT_LINK_IMAGE: "text_link_IMAGE",
	TEXT_LINK_FAVICON: "text_link_favicon",
	READ_GALLERY: "read_gallery",
	RADIO: "radio",
	DRAGANDDROP: "dnd",
	USERTAG: "usertag",
	PROFILE: "profile",
	YOUTUBE: "youtube",
	MAILTO: "mailto",
	SELECT: "select",
	PROMOTION: "promotion",
	BANNER: "banner",
};
