import { css } from "@emotion/react";
import {
  black,
  blur_bg,
  catHover,
  catYellow,
  fontBlack,
  fontBody,
} from "../../../../styles/common.css";

export default css`
  .link {
    cursor: pointer;
  }

  .text-image-form-item {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: ${blur_bg};
    border-radius: 40px;
    padding: 6px 16px;
    cursor: pointer;

    > span {
      border-radius: 4px;
    }

    p {
      font-size: 14px;
      color: ${fontBlack};
      font-weight: 600;
    }

    &.external {
      background-color: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      cursor: default;

      > p {
        color: ${fontBody};
      }
    }
  }

  p,
  a {
    font-weight: 500;
    line-height: 1.6;
    color: #777777;
    word-break: break-all;
  }

  &.widget-no-data {
    p {
      margin-left: 0;
    }
  }

  label {
    margin-bottom: 14px;
  }

  .platform {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &.flex-grid {
    display: grid;
    grid-template-columns: 0.5fr 2fr;
    cursor: default;

    label {
      margin-bottom: 0;
    }

    a:hover {
      color: ${catYellow};
      cursor: pointer;
      transition: 0.2s ease;
    }
  }

  .text {
    white-space: break-spaces;
  }

  .value-container {
    display: flex;
    gap: 4px;
    flex-direction: row;
    width: fit-content;
    flex-wrap: wrap;

    a {
      display: flex;
      align-items: center;
      border: 1px solid #0000001a;
      border-radius: 40px;
      padding: 11.5px 16px;
      background-color: #ffffff;
      color: ${fontBlack};
      opacity: 0.7;
      font-size: 14px;
      line-height: 17px;
      font-weight: 600;

      &:hover {
        color: #222222;
        opacity: 1;
        background: rgba(231, 237, 242, 0.5);
        border: 1px solid rgba(0, 0, 0, 0.1);
        transition: 0.2s ease;
      }
    }
  }

  .tag {
    color: ${black};
  }
`;
