import { LAYOUT_TYPES } from "../../../utils/basic.constants";
import AdminLayout from "./Admin";
import ClientLayout from "./Client";

export default function Layouts({
  variant = LAYOUT_TYPES.CLIENT,
  children,
  hideMenu,
  hideWidget,
  onlyImage,
  className,
}) {
  if (variant === LAYOUT_TYPES.ADMIN) {
    return <AdminLayout>{children}</AdminLayout>;
  }

  return (
    <ClientLayout
      hideMenu={hideMenu}
      hideWidget={hideWidget}
      onlyImage={onlyImage}
      className={className}
    >
      {children}
    </ClientLayout>
  );
}
