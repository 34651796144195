import { css } from "@emotion/react";
import {
  catYellow,
  fontBody,
  labelStyle,
  pri,
} from "../../../../styles/common.css";

export default css`
  display: flex;
  margin-bottom: 4px;
  label {
    &[aria-required="true"]::after {
      margin-left: 4px;
      content: "*";
      color: ${catYellow};
      font-weight: 800;
    }
    ${labelStyle}
  }

  span {
    cursor: default;
    color: ${fontBody};
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;
