export const productModifyCreate = [
  {
    title: "커버 이미지",
    description: "권장 사이즈 : 940 x 320 (JPG, PNG, GIF, WEBP)",
  },
  {
    title: "프로필 이미지",
    description: [
      "- 권장 사이즈 : 300 x 300 (JPG, PNG, GIF, WEBP)",
      "- GIF 움직이는 이미지로도 설정 가능해요! (5MB 이하)",
    ],
  },
  {
    title: "외부 링크",
    description: "유튜브, SNS, 블로그, 웹사이트 등 외부 링크를 연결하세요.",
  },
];

export const bottomLinks = [
  {
    label: "커뮤니티 소개",
    url: "https://info.sofans.io/about?utm_source=footer&utm_medium=referral",
  },
  {
    label: "공지/이벤트",
    url: "https://info.sofans.io/notice?utm_source=footer&utm_medium=referral",
  },
  {
    label: "FAQ",
    url: "https://info.sofans.io/faq?utm_source=footer&utm_medium=referral",
  },
  {
    label: "문의하기",
    url: "https://sofans.channel.io/?utm_source=footer&utm_medium=referral",
  },
  {
    label: "업데이트",
    url: "https://info.sofans.io/release?utm_source=footer&utm_medium=referral",
  },
  {
    label: "카톡 채팅방",
    url: "https://open.kakao.com/o/gXwZ5zkf",
  },

  {
    label: "이용약관",
    url: "https://teamflowcorp.notion.site/4a109c2c553c4a018dd3e11de88f64fa",
  },
  {
    label: "개인정보처리방침",
    url: "https://plip.kr/pcc/a9021ce6-3372-4a1b-94fb-c1557f9aa131/privacy/2.html",
  },
];

export const settingsConstantsProfile = [
  {
    title: "계정 정보",
    description: [
      "Sofans에 가입된 계정 정보입니다.",
      "수정 후 ‘저장하기’ 버튼을 눌러야 저장이 됩니다.",
    ],
  },
  {
    title: "프로필 이미지",
    description: [
      "- 권장 사이즈 : 300 x 300 (JPG, PNG, GIF, WEBP)",
      "- GIF 움직이는 이미지로도 설정 가능해요! (5MB 이하)",
    ],
  },
];

export const settingsConstantsAlram = [
  {
    title: "푸시 알림",
    description: [
      "- 내가 자리를 비운 동안 Sofans에서 일어난 일에 대한 알림을 받아보세요. 데스크톱과 모바일 (추후 지원)에 알림을 보내드려요.",
      "- 지원 : Chrome, Edge, Whale, Safari (추후 지원)",
      "- 브라우저에서 보내는 알림 수신 권한을 허용해줘야 해요.",
    ],
  },
];
