import css from "./popup.menu.css";

import Router from "next/router";

export default function PopupMenu({ list = [], close }) {
  function onClickHandle(item) {
    const link = item?.link || item?.url;
    close();
    if (link) {
      Router.push(link);
      return;
    }

    if (item?.onClick) {
      item?.onClick();
    }
  }

  return (
    <ul css={css}>
      {list?.map((item, index) => {
        const link = item?.link || item?.url;
        return item?.target === "inner" ? (
          <li key={`popup_menu_${index}`} onClick={() => onClickHandle(item)}>
            {item.name}
          </li>
        ) : (
          <li key={`popup_menu_${index}`} onClick={item?.onClick}>
            <a
              target="_blank"
              href={`${link}?utm_source=sofans.io&utm_medium=referral`}
              rel="noopener noreferrer"
            >
              {item.name}
            </a>
          </li>
        );
      })}
      {/* 외부 링크 팝업 */}
      {list.length === 0 && <li>외부 링크가 없다옹.</li>}
    </ul>
  );
}
