export default function PictureIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <path
          d="M14.1667 2.375H5.83333C3.65 2.375 1.875 4.15 1.875 6.33333V14.6667C1.875 16.85 3.65 18.625 5.83333 18.625H14.1667C16.35 18.625 18.125 16.85 18.125 14.6667V6.33333C18.125 4.15 16.35 2.375 14.1667 2.375ZM5.83333 3.625H14.1667C15.6583 3.625 16.875 4.84167 16.875 6.33333V12.5L14.2917 10.35C13.8 9.94167 13.1583 9.75833 12.525 9.84167C11.8917 9.925 11.3167 10.275 10.95 10.8L9.3 13.1583L7.36667 11.7083C6.45833 11.0333 5.175 11.1167 4.375 11.925L3.13333 13.1667V6.33333C3.13333 4.84167 4.35 3.625 5.84167 3.625H5.83333ZM3.15 14.9L5.25833 12.7917C5.625 12.425 6.20833 12.3833 6.61667 12.6917L8.58333 14.1667L6.34167 17.3667H5.83333C4.41667 17.3667 3.26667 16.275 3.15 14.8917V14.9ZM14.1667 17.375H7.86667L11.975 11.5083C12.1417 11.2667 12.4 11.1083 12.6917 11.075C12.9833 11.0417 13.2667 11.1167 13.4917 11.3083L16.875 14.125V14.6667C16.875 16.1583 15.6583 17.375 14.1667 17.375Z"
          fill="black"
        />
        <path
          d="M9.16667 10.2918C10.4333 10.2918 11.4583 9.26683 11.4583 8.00016C11.4583 6.7335 10.4333 5.7085 9.16667 5.7085C7.9 5.7085 6.875 6.7335 6.875 8.00016C6.875 9.26683 7.9 10.2918 9.16667 10.2918ZM9.16667 6.9585C9.74167 6.9585 10.2083 7.42516 10.2083 8.00016C10.2083 8.57516 9.74167 9.04183 9.16667 9.04183C8.59167 9.04183 8.125 8.57516 8.125 8.00016C8.125 7.42516 8.59167 6.9585 9.16667 6.9585Z"
          fill="black"
        />
      </g>
    </svg>
  );
}
