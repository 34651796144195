export default function TrashYellowIcon() {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66675 2.125H11.3334C11.6751 2.125 11.9584 1.84167 11.9584 1.5C11.9584 1.15833 11.6751 0.875 11.3334 0.875H4.66675C4.32508 0.875 4.04175 1.15833 4.04175 1.5C4.04175 1.84167 4.32508 2.125 4.66675 2.125Z"
        fill="#F18609"
      />
      <path
        d="M14.6667 3.375H1.33337C0.991707 3.375 0.708374 3.65833 0.708374 4C0.708374 4.34167 0.991707 4.625 1.33337 4.625H1.58337L2.16671 13.4333C2.30837 15.5083 4.04171 17.125 6.11671 17.125H9.88337C11.9584 17.125 13.6917 15.5 13.8334 13.4333L14.4167 4.625H14.6667C15.0084 4.625 15.2917 4.34167 15.2917 4C15.2917 3.65833 15.0084 3.375 14.6667 3.375ZM12.5834 13.35C12.4917 14.7667 11.3 15.875 9.88337 15.875H6.11671C4.69171 15.875 3.50837 14.7667 3.41671 13.35L2.83337 4.625H13.1667L12.5834 13.35Z"
        fill="#F18609"
      />
      <path
        d="M8 13.7918C8.34167 13.7918 8.625 13.5085 8.625 13.1668V7.3335C8.625 6.99183 8.34167 6.7085 8 6.7085C7.65833 6.7085 7.375 6.99183 7.375 7.3335V13.1668C7.375 13.5085 7.65833 13.7918 8 13.7918Z"
        fill="#F18609"
      />
      <path
        d="M10.5 13.7918C10.8417 13.7918 11.125 13.5085 11.125 13.1668V7.3335C11.125 6.99183 10.8417 6.7085 10.5 6.7085C10.1583 6.7085 9.875 6.99183 9.875 7.3335V13.1668C9.875 13.5085 10.1583 13.7918 10.5 13.7918Z"
        fill="#F18609"
      />
      <path
        d="M5.5 13.7918C5.84167 13.7918 6.125 13.5085 6.125 13.1668V7.3335C6.125 6.99183 5.84167 6.7085 5.5 6.7085C5.15833 6.7085 4.875 6.99183 4.875 7.3335V13.1668C4.875 13.5085 5.15833 13.7918 5.5 13.7918Z"
        fill="#F18609"
      />
    </svg>
  );
}
