import { forwardRef } from "react";
import CancelIcon from "../../../assets/icons/Cancel.icon";
import style from "./tag.css";

function TagComponent({
	index,
	value,
	item,
	handleOnClick,
	name,
	handleRemoveTag,
	isShow,
}) {
	return (
		<li
			key={`${index}-${value}`}
			className={`tag ${item?.className ?? ""}`}
			role="button"
			id={item?.id}
			name={name}
			value={value}
			tabIndex={index + 1}
			onClick={(e) => handleOnClick(e, { ...item, name, value })}
		>
			<span>{name}</span>
			{!isShow && (
				<button
					type="button"
					aria-label="태그 삭제 버튼"
					onClick={(e) => handleRemoveTag(item?.id, e)}
				>
					<CancelIcon size="small" />
				</button>
			)}
		</li>
	);
}

const Tags = forwardRef(
	(
		{ tags = [], isShow = false, className = "", id, removeTag, onClick, onKeyDown },
		ref
	) => {
		const isEdit = !isShow ? "isEdit" : "";

		function handleKeyDown(e) {
			e.preventDefault();
			e.stopPropagation();

			if (onKeyDown) onKeyDown(e);
		}

		function handleOnClick(e, item) {
			e.preventDefault();
			e.stopPropagation();

			if (onClick) onClick(item);
		}

		function handleRemoveTag(itemId, e) {
			e.preventDefault();
			e.stopPropagation();

			if (removeTag) removeTag(itemId, e);
		}

		return (
			<ul
				id={id}
				ref={ref}
				className={`tag-container ${className} ${isEdit}`}
				css={style}
				onKeyDown={handleKeyDown}
				// tabIndex={0}
			>
				{Array.isArray(tags) &&
					tags?.map((item, index) => {
						const name = typeof item?.name === "string" ? item.name : item;
						const value = item?.value ? item.value : `${index}${item}`;

						return (
							<TagComponent
								key={value}
								item={item}
								index={index}
								value={value}
								name={name}
								handleOnClick={handleOnClick}
								handleRemoveTag={handleRemoveTag}
								isShow={isShow}
							/>
						);
					})}

				{!Array.isArray(tags) && typeof tags === "object" && (
					<TagComponent
						item={tags}
						index={0}
						value={tags.value}
						name={tags.name}
						handleOnClick={handleOnClick}
						handleRemoveTag={handleRemoveTag}
						isShow={isShow}
					/>
				)}
			</ul>
		);
	}
);

Tags.displayName = "Tags";
export default Tags;
