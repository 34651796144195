import axiosWrapper from "../utils/axios-wrapper";
import { API_ERROR_CODE } from "../utils/json/error.json";
import queryString from "query-string";

const { post } = axiosWrapper();
export const fileMultipleUpload = async (data, query) => {
	const queryData = queryString.stringify(query);

	try {
		const response = await post(`file?${queryData}`, null, data, true);

		return response;
	} catch (error) {
		const message = API_ERROR_CODE?.[error?.statusCode] ?? "문의 부탁드려요.";
		throw message;
	}
};
