import Router from "next/router";
import { PATH_PAGE } from "../../../paths";
import { BASIC_CONSTANT } from "../../../utils/basic.constants";
import { useHandleOpenItem } from "../../../utils/common-function";

export const USER_LOGINNED = [
  {
    name: "커뮤니티 안내",
    url: "https://info.sofans.io/?utm_source=gnb&utm_medium=referral",
  },
  {
    name: "FAQ",
    url: "https://info.sofans.io/faq?utm_source=gnb&utm_medium=referral",
  },
  {
    name: "나의 프로필",
    url: "/",
    target: "inner",
  },
  {
    name: "로그아웃",
    target: "inner",
    onClick: () => {
      localStorage.removeItem(BASIC_CONSTANT.CLIENT_TOKEN);
      window.location.href = PATH_PAGE.root.url;
    },
  },
];

export const ITEM_POPUP = [
  { id: "edit", target: "inner", name: "수정하기", onClick: () => {} },
  { id: "remove", target: "inner", name: "삭제하기", onClick: () => {} },
  // {
  //   id: "declaration",
  //   target: "inner",
  //   name: "신고하기",
  // },
];

export const VIDEO_POPUP = {
  title: "YouTube 동영상 링크",
  placeholder: "https://youtu.be/0000000",
};
export const MENTION_POPUP = {
  title: "멘션 입력",
  placeholder: "멘션을 선택해주세요.",
};
export const TAG_POPUP = {
  title: "태그 입력",
  placeholder: "태그를 입력하거나 선택해주세요.",
  isTag: true,
};
