export function handleMentionData(randomId) {
  const targetDom = document.getElementById(randomId);
  const editor = targetDom.querySelector(".ck-editor__main");
  const productDoms = editor.querySelectorAll('[data-mention-type="product"]');
  const userDoms = editor.querySelectorAll('[data-mention-type="user"]');
  const products = [],
    users = [];

  productDoms.forEach((product) => {
    const id = product.getAttribute("data-id");
    if (id) products.push({ id: parseInt(id) });
  });

  userDoms.forEach((user) => {
    const id = user.getAttribute("data-id");
    if (id) users.push({ id: parseInt(id) });
  });

  return { products, users };
}
