import { useEffect, useState } from "react";
import ButtonIcon from "../../Button/Button.Icon";
import Input from "../../Input";
import SelectTags from "../../SelectTags";
import css from "./popup.input.css";

export default function PopupInput({ list, close }) {
  const {
    title,
    description,
    placeholder,
    onClick,
    isTag = false,
    value: paramsValue,
  } = list;

  const [value, setValue] = useState();
  const [tags, setTags] = useState();

  const handleClick = () => {
    onClick(isTag ? tags : value);
    close();
  };

  const onChangeTags = ({ target }) => {
    setTags(target?.value);
  };

  useEffect(() => {
    setValue(paramsValue);
  }, [paramsValue]);

  return (
    <div className="popup-input" css={css}>
      {isTag ? (
        <SelectTags
          onChange={onChangeTags}
          label={title}
          description={description}
          placeholder={placeholder}
        />
      ) : (
        <Input
          className="popup-input-wrapper"
          label={title}
          description={description}
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      )}

      <ButtonIcon
        className="black-btn"
        icon="확인"
        ariaLabel={"확인 버튼"}
        onClick={handleClick}
      />
    </div>
  );
}
