import { css } from "@emotion/react";

export default css`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 20px;

  .background {
    position: relative;
    width: 49.5%;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    /* z-index: -1; */
  }

  &.grid-1 {
    .background {
      width: 100%;
      height: 400px;
    }
  }
  &.grid-2 {
    flex-direction: row;
    flex-wrap: nowrap;
    .background {
      width: 100%;
    }
  }
  &.grid-3 {
    flex-direction: column;
    max-height: 405px;
    .background {
      &:first-of-type {
        height: 405px;
      }
    }
  }

  .remove-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    transition: 0.2s ease;

    &:hover {
      filter: invert(100%);
    }
  }

  @media screen and (max-width: 800px) {
    .background {
      width: 49.3%;
    }
    &.grid-1 {
      .background {
        height: 200px;
      }
    }

    &.grid-3 {
      .background {
        height: 135px;
        &:first-of-type {
          height: 274px;
        }
      }
    }
    &.grid-4 {
      .background {
        height: 135px;
      }
    }
  }
`;
