import Image from "next/image";
import TextComponent from "./Text.component";

export default function TextLinkFavicon({
  name,
  link,
  image,
  className = "",
  target = "_blank",
  rel = "noopener noreferrer",
  onClick,
}) {
  function onClick(e) {
    e.preventDefault();
    window.open(link, target, rel);
  }

  return (
    <div className={`text-image-form-item link ${className}`} onClick={onClick}>
      <Image
        src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${link}&size=28`}
        alt={`${name}_favicon`}
        width={28}
        height={28}
      />
      <TextComponent name={name} />
    </div>
  );
}
