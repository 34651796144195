import { useEffect, useState } from "react";
import LabelComponent from "../LabelComponent";
import css from "./select.css";
import Tags from "../Tags";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

const isEmptyHandle = (data) => {
	if (!data) return true;
	if (Array.isArray(data) && data.length === 0) return true;
	if (typeof data === "object" && Object.keys(data).length === 0) return true;

	return false;
};

const isHaveValue = (data, value) => {
	if (Array.isArray(data) && data.length > 0) {
		return data.some((item) => item.value === value);
	}

	return data.value === value;
};

const isMaxlength = (data, maxLength) => {
	if (!maxLength || maxLength === 0) return false;
	if (Array.isArray(data) && data.length > maxLength) return true;
	return false;
};

export default function Select(params) {
	const {
		label,
		data = [],
		name,
		onChange,
		onBlur,
		className = "",
		isMultiple = false,
		value,
		placeholder,
		description = "",
		required = false,
		maxLength = { value: 0, message: "" },
	} = params;
	const [selected, setSelected] = useState();
	const [isShow, setIsShow] = useState(false);
	const isEmpty = isEmptyHandle(selected);

	const handleOpen = () => {
		setIsShow((prev) => !prev);
	};

	const handleValue = (data) => {
		if (!isMultiple) return [data];
		if (isEmpty) return [data];

		if (isHaveValue(selected, data.value)) {
			const newSelected = selected.filter((item) => item.value !== data.value);

			return newSelected;
		}

		const index = selected.findIndex((item) => item.value === data.value);

		if (index === -1) return [...selected, data];
	};

	const handleBlur = (event) => {
		const target = event.target;
		const isSelect = target.closest(".select-container");

		if (!isSelect) {
			setIsShow(false);
		}

		return;
	};

	const handleSelect = (item, e) => {
		e.stopPropagation();

		const value = handleValue(item);

		if (isMultiple) {
			const isMax = isMaxlength(value, maxLength.value);

			if (isMax) {
				alert(maxLength.message);
				return;
			}
		}

		if (onChange) {
			onChange({
				target: {
					name: name,
					value,
				},
				selectedValue: item,
			});
		} else setSelected(value);

		if (!isMultiple) handleOpen();
	};

	const handleRemove = (id, e) => {
		e.stopPropagation();

		const data = selected.filter((item) => item.id !== id);

		if (onChange) {
			onChange({
				target: {
					name: name,
					value: data,
				},
			});
		} else setSelected(data);
	};

	useEffect(() => {
		if (value) setSelected(value);
	}, [value]);

	useEffect(() => {
		if (isShow) {
			document.addEventListener("click", handleBlur);
		}

		return () => {
			document.removeEventListener("click", handleBlur);
			return;
		};
	}, [isShow]);

	return (
		<div css={css} className={className} onClick={handleOpen}>
			{label && (
				<LabelComponent
					name={name}
					label={label}
					description={description}
					required={required}
				/>
			)}
			<div className={`select-container ${isShow ? "focus" : ""}`}>
				<div role="select">
					{isEmpty && placeholder && <p className="placeholder">{placeholder}</p>}
					{!isEmpty && (
						<Tags id={`${name}_tags`} tags={selected} removeTag={handleRemove} isShow />
					)}
					{isShow ? (
						<ArrowDropUp style={{ fill: "#777777" }} />
					) : (
						<ArrowDropDown style={{ fill: "#777777" }} />
					)}
				</div>

				<div
					className={`select-list-container ${isShow ? "show" : ""}`}
					isopen={String(isShow)}
				>
					<ul className="select-list">
						{data.map((item, index) => {
							const isSelected =
								isMultiple && selected
									? selected.some((select) => select.value === item.value)
									: selected && selected.value === item.value;

							return (
								<li
									key={index}
									onClick={(e) => handleSelect(item, e)}
									role="option"
									aria-selected={isSelected}
								>
									{item.name}
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</div>
	);
}
