import Alert from "./Present";

export default function AlertContainer({
  open = false,
  title = "",
  description = "",
  image = null,
  buttons = [],
  onClose,
  maxWidth,
}) {
  function outsideClick(e) {
    e.stopPropagation();

    if (onClose) {
      return onClose();
    }
  }

  return (
    <Alert
      title={title}
      description={description}
      image={image}
      buttons={buttons}
      open={open}
      outsideClick={outsideClick}
      onClose={onClose}
      maxWidth={maxWidth}
    />
  );
}
