import { css } from "@emotion/react";
import { fontBlack } from "../../../../styles/common.css";

export const GalleryComponentsCss = css`
  position: relative;
  width: 100%;
  /* display: flex; */

  label {
    display: flex;
    font-weight: 700;
    margin-bottom: 14px;
  }

  .gallery-item {
    cursor: pointer;

    &-image {
      border-radius: 8px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      min-height: 160px;
    }

    button {
      position: absolute;
      top: 10px;
      right: 5px;
      transition: 0.2s ease;

      &:hover {
        filter: invert(100%);
      }
    }
  }
  .swiper {
    z-index: 0;
    display: flex;
  }
  .swiper-button-next,
  .swiper-button-prev {
    background-color: rgba(255, 255, 255, 0.6);
    width: 32px;
    height: 32px;
    border-radius: 32px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    filter: invert(0);
    transition: 0.2s ease;

    &::after {
      font-size: 1em;
      text-shadow: 0px 0px 1px;
      content: "";
      border-top: 4px solid #000;
      border-right: 4px solid #000;
      width: 10px;
      height: 10px;
      position: relative;
      transform: rotate(45deg) translateX(-2px) translateY(2px);
    }

    &:hover {
      filter: invert(1);
    }
  }
  .swiper-button-prev {
    &::after {
      transform: rotate(-135deg) translateX(-2px) translateY(2px);
    }
  }
  .swiper-button-disabled {
    opacity: 0.1;
  }
  .slide-arrow-wrapper {
    position: absolute;
    bottom: 22%;
    left: 2%;
    transform: translate(0, -50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 96%;
    z-index: 0;
  }
  .swiper-pagination {
    background-color: rgba(0, 0, 0, 0.7);
    color: rgba(255, 255, 255, 0.4);
    width: fit-content;
    padding: 4px 8px;
    border-radius: 30px;
    font-size: 12px;
    transform: translateY(10px);
    cursor: default;

    .swiper-pagination-current {
      color: white;
    }
    .swiper-pagination-total {
    }
  }

  @media screen and (max-width: 800px) {
    /*  */
  }
`;
