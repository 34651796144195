import { css } from "@emotion/react";
import {
	black,
	catYellow,
	gray_01,
	fontBlack,
	fontBody,
	catYellowHover,
} from "../../../../styles/common.css";

export default css`
	&.tag-container {
		display: flex;
		flex-wrap: wrap;
		gap: 4px;

		&.isEdit {
			margin: 10px auto;
		}

		.yellow-cat {
			background-color: ${catYellow};
			color: white;
		}

		li {
			display: flex;
			flex-direction: row;
			background-color: ${gray_01};
			border-radius: 30px;
			padding: 5px 8px;

			&:focus {
				outline: none;
				border: 1px solid ${catYellow};
				background-color: ${catYellowHover};
			}

			/* cursor: pointer; */
			/* transition: 0.2s ease; */

			/* &:hover {
        box-shadow: 0px 0px 0px 1px ${fontBody};
      } */

			span {
				font-size: 12px;
				line-height: 18px;
			}
			&:not(:last-of-type) {
			}

			button {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
`;
