const LinkComponent = ({
	name,
	link,
	className = "",
	target = "_blank",
	rel = "noopener noreferrer",
	onClick,
}) => {
	const url = link ?? name;

	return (
		<a href={url} target={target} rel={rel} className={className} onClick={onClick}>
			{/* 외부 링크 태그 */}
			<span>{name ?? "-"}</span>
		</a>
	);
};

export default LinkComponent;
