import { atom } from "recoil";

export const notificationDefaultData = {
  isActive: false,
};

export const notificationStore = atom({
  key: `notificationStore`,
  default: notificationDefaultData,
});
