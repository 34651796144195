import { css } from "@emotion/react";
import { white } from "../../../../../styles/common.css";

export default css`
  display: flex;
  width: 420px;
  gap: 4px;

  background: ${white};

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 20px 24px;

  .black-btn {
    max-width: 76px;
    max-height: 6px;
    white-space: nowrap;
    margin-top: 34px;

    &:hover {
      span {
        opacity: 1;
        transition: 0.2 ease;
      }
    }
  }

  @media screen and (max-width: 800px) {
    width: 95%;
    padding: 12px 18px;
    flex-direction: column;
    align-items: flex-end;

    .black-btn {
      margin-top: 10px;
    }
    .popup-input-wrapper {
      .input-wrapper {
        /* width: 30%; */
      }
    }
  }
`;
