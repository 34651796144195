import { css } from "@emotion/react";
import { layoutBorder, white } from "../../../../../styles/common.css";

export default css`
  /* * {
    z-index: 0 !important;
  } */

  .bottom {
    position: sticky;
    bottom: 0;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      background-color: white;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .editor-action-buttons {
      display: flex;

      .btn {
        width: 20px;
        height: 20px;
        padding: 10px;
        border-radius: 50%;

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);

          button {
            background-color: transparent;
          }
        }
      }
    }

    .black-btn {
      height: 5px;

      &:hover {
        span {
          opacity: 1;
          transition: 0.2s ease;
        }
      }
    }

    .buttons {
      display: flex;
      gap: 4px;

      .cancel {
        width: 89px;
        border-radius: 50px;
        padding: 10px 16px;
        color: #ffffff;
        font-weight: 600;
        line-height: 18px;
      }

      span {
        white-space: nowrap;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .video-wrap {
    margin-bottom: 20px;
  }

  .tag-input > .input-wrapper {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1074px) {
    width: 100%;
    .bottom > div {
      /* min-width: 80vw;
      max-width: 80vw; */
    }
  }

  @media screen and (max-width: 800px) {
    .bottom {
      bottom: 0px;

      .black-btn {
        width: 65%;
      }
    }
    .create-community-modal {
      width: 90%;
    }

    .emoji {
      display: none;
    }
  }
`;
