import { useRecoilState } from "recoil";
import { alertStore } from "../recoils/alert.recoil";
import { handleAlertButtons } from "../components/common/Alert/utils/alert.utils";

export default function useAlert() {
	const [alertState, setAlertState] = useRecoilState(alertStore);

	const { open, title, description, image, buttons } = alertState;

	function openAlert({ title, description, image, buttons, events }) {
		const newButtons = handleAlertButtons({ buttons, events });

		setAlertState({
			open: true,
			title,
			description,
			image,
			buttons: newButtons,
		});
	}

	function closeAlert() {
		setAlertState({
			open: false,
			title: "",
			description: "",
			image: null,
			buttons: [],
		});
	}

	return {
		open,
		title,
		description,
		image,
		buttons,
		openAlert,
		closeAlert,
	};
}
