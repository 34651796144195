import { FORM_ITEM_TYPE } from "../../../utils/form.constants";

import style from "./text.css";
import LinkComponent from "./Text-Link.component";
import TextComponent from "./Text.component";
import TextLinkImage from "./Text-link-image.component";
import TextLinkFavicon from "./Text-link-favicon.component";

const HandleComponent = ({ data, type = FORM_ITEM_TYPE.TEXT, onClick }) => {
	if (type === FORM_ITEM_TYPE.TEXT_LINKS) {
		if (!data.link && !data.name) return <TextComponent name="-" />;

		data.link = (data.link ?? data.name) + `?utm_source=sofans.io&utm_medium=referral`;
		return (
			<LinkComponent
				{...data}
				rel="noopener noreferrer"
				target="_blank"
				onClick={onClick}
			/>
		);
	}
	if (type === FORM_ITEM_TYPE.TEXT) return <TextComponent {...data} />;
	if (type === FORM_ITEM_TYPE.TEXT_TAG)
		return <TextComponent {...data} className="tag" />;
	if (type === FORM_ITEM_TYPE.TEXT_PLATFORM) {
		const obj = { ...data.name };

		const isAllNotAvailable = Object.keys(obj).every((key) => obj[key] === false);

		return (
			<div className="platform">
				{/* 플랫폼 데이터 */}
				{isAllNotAvailable ? (
					<TextComponent name="-" />
				) : (
					Object.keys(obj).map((key) => {
						const value = data?.name[key];
						let name = key;
						if (key === "ChromeExtension" && value) name = "Chrome Extension";
						return value && <TextComponent key={key} name={name} onClick={onClick} />;
					})
				)}
			</div>
		);
	}
	if (type === FORM_ITEM_TYPE.MAILTO) {
		return <LinkComponent link={`mailto:${data.name}`} name={data.name} />;
	}

	if (type === FORM_ITEM_TYPE.TEXT_LINK_IMAGE) {
		return <TextLinkImage {...data} />;
	}
	if (type === FORM_ITEM_TYPE.TEXT_LINK_FAVICON) {
		return <TextLinkFavicon {...data} />;
	}
};

const ValueComponent = ({ value, type, onClick }) => {
	const isArray = Array.isArray(value);

	if (isArray) {
		const isNodata = value.length === 0;
		return (
			<div className="value-container">
				{!isNodata ? (
					value?.map((v) => {
						return (
							<HandleComponent type={type} key={v.name} data={v} onClick={onClick} />
						);
					})
				) : (
					// 관심주제
					<TextComponent name="-" />
				)}
			</div>
		);
	}

	return <HandleComponent data={{ name: value }} type={type} onClick={onClick} />;
};

export default function Text({ label, value, type, className = "", ...props }) {
	return (
		<div className={className} css={style}>
			{label && <label>{label}</label>}
			<ValueComponent value={value} type={type} onClick={props?.onClick} />
		</div>
	);
}
