export default function CancelIcon({ size = "normal" }) {
  if (size === "normal") return <Normal />;
  if (size === "small") return <Small />;
  if (size === "medium") return <Medium />;
}

function Normal() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="black" />
      <g opacity="0.8">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3825 22.7705C14.8725 23.2805 14.8725 24.1074 15.3825 24.6175C15.8926 25.1275 16.7195 25.1275 17.2295 24.6175L20 21.847L22.7705 24.6175C23.2805 25.1276 24.1075 25.1276 24.6175 24.6175C25.1275 24.1075 25.1275 23.2806 24.6175 22.7705L21.847 20L24.6175 17.2295C25.1275 16.7195 25.1275 15.8926 24.6175 15.3825C24.1074 14.8725 23.2805 14.8725 22.7705 15.3825L20 18.153L17.2295 15.3826C16.7195 14.8725 15.8926 14.8725 15.3825 15.3826C14.8725 15.8926 14.8725 16.7195 15.3825 17.2296L18.153 20L15.3825 22.7705Z"
          fill="white"
        />
      </g>
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="black" />
    </svg>
  );
}

function Medium() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="11" fill="white" />
      <path
        d="M11 1C5.48205 1 1 5.48205 1 11C1 16.5179 5.48205 21 11 21C16.5179 21 21 16.5179 21 11C21 5.48205 16.5179 1 11 1ZM14.6205 13.5333C14.9179 13.8308 14.9179 14.3231 14.6205 14.6205C14.4667 14.7744 14.2718 14.8462 14.0769 14.8462C13.8821 14.8462 13.6872 14.7744 13.5333 14.6205L11 12.0872L8.46667 14.6205C8.31282 14.7744 8.11795 14.8462 7.92308 14.8462C7.7282 14.8462 7.53333 14.7744 7.37949 14.6205C7.08205 14.3231 7.08205 13.8308 7.37949 13.5333L9.91282 11L7.37949 8.46667C7.08205 8.16923 7.08205 7.67692 7.37949 7.37949C7.67692 7.08205 8.16923 7.08205 8.46667 7.37949L11 9.91282L13.5333 7.37949C13.8308 7.08205 14.3231 7.08205 14.6205 7.37949C14.9179 7.67692 14.9179 8.16923 14.6205 8.46667L12.0872 11L14.6205 13.5333Z"
        fill="#222222"
      />
    </svg>
  );
}

function Small() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0.5C3.41333 0.5 0.5 3.41333 0.5 7C0.5 10.5867 3.41333 13.5 7 13.5C10.5867 13.5 13.5 10.5867 13.5 7C13.5 3.41333 10.5867 0.5 7 0.5ZM9.35333 8.64667C9.54667 8.84 9.54667 9.16 9.35333 9.35333C9.25333 9.45333 9.12667 9.5 9 9.5C8.87333 9.5 8.74667 9.45333 8.64667 9.35333L7 7.70667L5.35333 9.35333C5.25333 9.45333 5.12667 9.5 5 9.5C4.87333 9.5 4.74667 9.45333 4.64667 9.35333C4.45333 9.16 4.45333 8.84 4.64667 8.64667L6.29333 7L4.64667 5.35333C4.45333 5.16 4.45333 4.84 4.64667 4.64667C4.84 4.45333 5.16 4.45333 5.35333 4.64667L7 6.29333L8.64667 4.64667C8.84 4.45333 9.16 4.45333 9.35333 4.64667C9.54667 4.84 9.54667 5.16 9.35333 5.35333L7.70667 7L9.35333 8.64667Z"
        fill="#222222"
      />
    </svg>
  );
}
