export default function EmojiIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 2.375C5.51667 2.375 1.875 6.01667 1.875 10.5C1.875 14.9833 5.51667 18.625 10 18.625C14.4833 18.625 18.125 14.9833 18.125 10.5C18.125 6.01667 14.4833 2.375 10 2.375ZM10 17.375C6.20833 17.375 3.125 14.2917 3.125 10.5C3.125 6.70833 6.20833 3.625 10 3.625C13.7917 3.625 16.875 6.70833 16.875 10.5C16.875 14.2917 13.7917 17.375 10 17.375Z"
        fill="black"
      />
      <path
        d="M7.08317 9.45833C7.65817 9.45833 8.12484 8.99167 8.12484 8.41667C8.12484 7.84167 7.65817 7.375 7.08317 7.375C6.50817 7.375 6.0415 7.84167 6.0415 8.41667C6.0415 8.99167 6.50817 9.45833 7.08317 9.45833ZM7.08317 8.20833C7.19984 8.20833 7.2915 8.3 7.2915 8.41667C7.2915 8.65 6.87484 8.65 6.87484 8.41667C6.87484 8.3 6.9665 8.20833 7.08317 8.20833Z"
        fill="black"
      />
      <path
        d="M12.9167 7.375C12.3417 7.375 11.875 7.84167 11.875 8.41667C11.875 8.99167 12.3417 9.45833 12.9167 9.45833C13.4917 9.45833 13.9583 8.99167 13.9583 8.41667C13.9583 7.84167 13.4917 7.375 12.9167 7.375ZM12.7083 8.41667C12.7083 8.3 12.8 8.20833 12.9167 8.20833C13.0333 8.20833 13.125 8.3 13.125 8.41667C13.125 8.65 12.7083 8.65 12.7083 8.41667Z"
        fill="black"
      />
      <path
        d="M13.5082 11.5665C13.1832 11.4748 12.8332 11.6582 12.7332 11.9915C12.3832 13.1998 11.2582 14.0415 9.9999 14.0415C8.74156 14.0415 7.61656 13.1998 7.26656 11.9915C7.16656 11.6582 6.81656 11.4748 6.49156 11.5665C6.15823 11.6665 5.96656 12.0082 6.06656 12.3415C6.5749 14.0748 8.19156 15.2915 9.9999 15.2915C11.8082 15.2915 13.4249 14.0748 13.9332 12.3415C14.0332 12.0082 13.8416 11.6665 13.5082 11.5665Z"
        fill="black"
      />
    </svg>
  );
}
