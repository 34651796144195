import { useCallback, useEffect, useState } from "react";

const throttle = (handler, timeout = 300) => {
  let invokedTime;
  let timer;
  return function (data, ...args) {
    if (!invokedTime) {
      handler.apply(data, args);
      invokedTime = Date.now();
    } else {
      clearTimeout(timer);
      timer = window.setTimeout(() => {
        if (Date.now() - invokedTime >= timeout) {
          handler.apply(data, args);
          invokedTime = Date.now();
        }
      }, Math.max(timeout - (Date.now() - invokedTime), 0));
    }
  };
};

export default function InfiniteScroll({
  onChangeQuery,
  children,
  isLast = false,
  query,
  isModal = false,
}) {
  const [isFetching, setFetching] = useState(false);

  const executeFetch = useCallback(() => {
    if (onChangeQuery) onChangeQuery();

    setFetching(false);
  }, [query.offset]);

  useEffect(() => {
    const target = isModal
      ? document.querySelector(".modal-container")
      : window;

    const handleScroll = throttle(() => {
      if (isModal) {
        const { scrollTop, offsetHeight } = target;

        const isLastView = window.innerHeight + scrollTop >= offsetHeight + 80;

        if (isLastView) setFetching(true);
      } else {
        const { scrollTop, offsetHeight } = document.documentElement;

        const isLastView = window.innerHeight + scrollTop + 30 >= offsetHeight;

        if (isLastView) setFetching(true);
      }
    });

    target.addEventListener("scroll", handleScroll);
    return () => target.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (isFetching && !isLast) executeFetch();
    else if (isLast) setFetching(false);
  }, [isFetching]);

  return children;
}
