import Image from "next/image.js";
import { alertCss } from "./alert.css.js";

export default function Alert({
	title = "",
	description = "",
	image = null,
	buttons = [],
	open,
	outsideClick = () => {},
	onClose = () => {},
	maxWidth = "360px",
}) {
	function preventClick(e) {
		e.stopPropagation();
	}

	return (
		<div
			css={(props) => alertCss({ ...props, open, maxWidth })}
			role="alert"
			onClick={outsideClick}
		>
			<div className="alert" onClick={preventClick}>
				<div className="alert__contents">
					<div className="alert__contents__title">{title}</div>
					{image && (
						<div className="alert__contents__image">
							<Image
								src={image}
								className="alert_image"
								alt="sofans_alert_image"
								width="100%"
								height="100%"
							/>
						</div>
					)}
					<div className="alert__contents__description">{description}</div>
				</div>
				<div className="alert__buttons">
					{buttons.map((btn, index) => {
						const buttonClassName = btn?.className ?? "";

						async function handleButtonClick(e) {
							if (btn?.onClick) {
								const result = await btn.onClick();

								if (result !== false) onClose();
								return;
							}

							if (!btn?.onClick) onClose();
						}

						return (
							<button
								key={index}
								className={`alert__button ${btn.id} ${buttonClassName}`}
								onClick={handleButtonClick}
							>
								<span>{btn.label}</span>
							</button>
						);
					})}
				</div>
			</div>
		</div>
	);
}
