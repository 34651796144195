import { useMutation, useQuery } from "@tanstack/react-query";
import React, {
	forwardRef,
	useCallback,
	useEffect,
	useId,
	useRef,
	useState,
} from "react";
import { createTag, getListTag } from "../../../apis/tag.api";

import Input from "../Input";
import LabelComponent from "../LabelComponent";
import TagsComponent from "../Tags";
import style from "./selecttags.css";
import useAlert from "../../../hooks/useAlert";
import { ALERT_TAGS } from "../Alert/utils/alert.constants";
import { debounce } from "lodash";
import { postRelatedProductTag } from "../../../apis/product.api";

function SelectTags(params, ref) {
	const {
		onChange,
		value,
		name,
		label,
		placeholder,
		required,
		className = "",
		description = "",
		isStaticTag = false,
		staticList = [],
		length = 5,
		api = null,
		tagId = "tags",
		createApiTagType = null,
	} = params;

	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState("");
	const [tags, setTags] = useState([]);
	const [list, setList] = useState([]);
	const listRef = useRef();
	const inputRef = useRef();

	const { openAlert } = useAlert();

	async function tagApis(props, api) {
		if (!props) return [];

		if (api) return await api(props);

		const { data } = await getListTag(props);
		return data;
	}

	const isMaxLimit = tags.length >= length;

	const addTags = (item) => {
		if (isMaxLimit) {
			openAlert(ALERT_TAGS);
			return;
		}

		handleAddTags(item);
		setSearch("");
	};

	// useQuery([`tag?name=${search}`, search], () => getListTag(search), {
	//   select: (data) => data.data,
	//   onSuccess: (data) => {
	//     setList(data);
	//     setLoading(false);
	//   },
	// });

	// const { mutate } = useMutation(createTag, {
	//   onSuccess: ({ data }) => {
	//     handleAddTags(data);
	//     setLoading(false);
	//   },
	// });

	const onChangeTags = (value) => {
		if (onChange) onChange({ target: { name, value } });
	};

	const handleAddTags = (data) => {
		setTags((prev) => {
			onChangeTags([...prev, data]);
			return [...prev, data];
		});
	};

	async function queryListTag(props) {
		setLoading(true);

		try {
			if (!isStaticTag) {
				const result = await tagApis(props, api);

				setList([...result]);
			} else setList(staticList);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	}

	async function mutateCreateTag(props) {
		try {
			if (createApiTagType === "product") {
				const { data } = await postRelatedProductTag(props);
				handleAddTags(data);
				return;
			}

			const { data } = await createTag(props);
			handleAddTags(data);
		} catch (err) {
			console.log("error", err);
		}

		setLoading(false);
	}

	const handleCreateTag = (value) => {
		mutateCreateTag({ name: value });
		// mutate({ name: value });
	};

	const convertSearchValue = (value) => {
		// 띄워쓰기 없애기
		return value.replace(/(\s*)/g, "");
	};

	const handleOnChange = useCallback(
		debounce(({ target }) => {
			const value = convertSearchValue(target.value);

			setSearch(value);
			queryListTag(value);
		}, 350),
		[]
	);

	const handleKeyDown = (e) => {
		const { target, keyCode } = e;
		if (keyCode === 13 && !!target?.value) {
			e.preventDefault();
			e.stopPropagation();

			const tag = list?.find(
				(item) => item.value.toLowerCase() === target?.value.toLowerCase()
			);
			if (!tag) {
				if (!isStaticTag) handleCreateTag(convertSearchValue(target.value));
				if (isStaticTag) handleCreateTag(target.value);
			} else {
				handleAddTags(tag);
			}
			setSearch("");
			setLoading(true);
		}

		if (keyCode === 40) {
			e.preventDefault();
			e.stopPropagation();
			const tagContainerDom = listRef?.current;
			if (tagContainerDom) {
				if (tagContainerDom?.childNodes?.length > 0) {
					tagContainerDom?.childNodes[0].focus();
				}
			}
		}
	};

	function selectOnKeyDown(e) {
		const { target, keyCode } = e;

		switch (keyCode) {
			case 13:
				// Enter
				const findItem = list?.[target.tabIndex - 1];
				if (findItem) addTags(findItem);
				inputRef.current.focus();
				break;
			case 39:
				// ArrowRight
				target.nextSibling?.focus();
				break;
			case 38:
				// ArrowUp
				inputRef.current.focus();
				break;
			case 37:
				// ArrowLeft
				target.previousSibling?.focus();
			default:
				break;
		}
	}

	const removeTag = (id) => {
		setTags((prev) => {
			const newTags = prev.filter((item) => item.id !== id);
			onChangeTags(newTags);
			return newTags;
		});
	};

	// useEffect(() => {
	// 	queryListTag(search);
	// }, [search]);

	useEffect(() => {
		if (value) setTags(value);
	}, [value]);

	return (
		<div css={style} className={className}>
			{label && (
				<LabelComponent
					label={label}
					name={name}
					required={required}
					description={description}
				/>
			)}
			<div className="tag-wrapper">
				{tags.length > 0 && (
					<TagsComponent id={tagId} tags={tags} removeTag={removeTag} />
				)}
				<Input
					className="tag-input"
					type="text"
					id={name}
					name={name}
					value={search}
					onKeyDown={handleKeyDown}
					onChange={handleOnChange}
					placeholder={placeholder}
					ref={inputRef}
					readOnly={isMaxLimit}
				/>
			</div>
			{!loading && search && list?.length > 0 && (
				<TagsComponent
					ref={listRef}
					tags={list}
					className="tag-container-list"
					onClick={addTags}
					isShow
					onKeyDown={selectOnKeyDown}
				/>
			)}
		</div>
	);
}

export default forwardRef(SelectTags);
