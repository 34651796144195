import Image from "next/image";
import TrashYellowIcon from "../../assets/icons/Trash.Yello.icon";
import ButtonIcon from "../common/Button/Button.Icon";
import BellImg from "../../assets/images/bell.png";
import BellAnimationImg from "../../assets/images/bell.webp";
import noData from "../../assets/images/notification_no_data.png";

import { notificationPresenterCss } from "./notification.css";
import NotificationItem from "./NotificationItem";
import InfiniteScroll from "../common/InfiniteScroll";

export default function NotificationPresenter({
  data = [],
  onChangeQuery,
  query,
  isLast,
  toUserNickname,
  handleRemoveNotification,
  count,
}) {
  return (
    <div className="notification-wrapper" css={notificationPresenterCss}>
      <div className="notification-header">
        <div className="title">
          <h3>
            그대를 찾는
            <br className="mobile" /> 알림이다옹~
          </h3>
          {data.length > 0 && (
            <div className="grid-image">
              <Image
                src={BellAnimationImg}
                layout="fill"
                alt="bell-animation"
              />
            </div>
          )}
          {data.length === 0 && (
            <div className="grid-image">
              <Image src={BellImg} layout="fill" alt="bell" />
            </div>
          )}
        </div>
        <ButtonIcon
          className="bolder"
          icon={<TrashYellowIcon />}
          count={"알림 삭제"}
          ariaLabel={"알림 삭제 버튼"}
          onClick={() => handleRemoveNotification()}
        />
      </div>
      <p className="notification-counts">
        <span>{count}</span>개의 새로운 알림이 있다옹.
      </p>

      {data.length === 0 && (
        <div className="no-data-wrapper">
          <p>현재 알림이 없다옹..</p>
          <div className="no-data-img">
            <Image
              src={noData}
              quality={80}
              alt="no data"
              layout="fill"
              objectFit="contain"
            />
          </div>
        </div>
      )}

      {data.length > 0 && (
        <InfiniteScroll
          onChangeQuery={onChangeQuery}
          query={query}
          isLast={isLast}
          isModal
        >
          <ul className="notification-list">
            {data.map((item, index) => (
              <NotificationItem
                key={`notification_${index}`}
                {...item}
                toUserNickname={toUserNickname}
                isActive={!item?.isRead}
              />
            ))}
          </ul>
        </InfiniteScroll>
      )}
    </div>
  );
}
