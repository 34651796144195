import { css } from "@emotion/react";
import {
  layoutBorder,
  pri,
  textBoxShadow,
  white,
} from "../../../../styles/common.css";

export default css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  inset: 0;
  z-index: 10001;
  > div {
    position: absolute;
    ${textBoxShadow};
  }

  @media screen and (max-width: 800px) {
    > div {
      width: 155px;
    }

    &.input {
      > div {
        width: calc(100% - 24px);
        left: 0 !important;
      }
    }
  }
`;
