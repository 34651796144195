import { css } from "@emotion/react";

export default css`
  display: flex;
  flex-direction: column;

  margin-top: 20px;

  h4 {
    font-weight: 700;
    line-height: 24px;
    font-size: 14px;
    color: #222222
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    color: #777777;
  }

  .widget-text-component {
    margin-bottom: 20px;
  }
`;
