import { likePost } from "../apis/like.api";

export function likeAPI(token, targetId, type, createdUserId, itemId = null) {
	if (token) {
		const convertData = {
			targetId,
			type,
			toUserId: createdUserId,
			itemId,
		};

		likePost(token, convertData);
	} else throw new Error("로그인이 필요합니다.");
}
