/**
 * Page가 로그인이 필요할 경우 마지막 인자를 false로 넣어준다.
 */

function path(...options) {
	const links = options.slice(0, options.length - 1);
	const isPublic = options[options.length - 1];

	const url = `/${links.join("/")}`;

	return {
		url,
		isPublic,
	};
}

const ROOTS = "";
const ROOTS_ERROR = "error";
const ROOTS_COMMUNITY = "community";
const ROOTS_PRODUCT = "product";
const ROOTS_RANKING = "ranking";
const ROOTS_AUTH = "auth";
const ROOTS_USER = "user";

export const PATH_ERROR = {
	root: path(ROOTS_ERROR, null),
	page404: path(ROOTS, "404", true),
	page500: path(ROOTS_ERROR, "500"),
};

export const PATH_AUTH = {
	root: path(ROOTS_AUTH),
	login: path(ROOTS_AUTH, "login", true),
	success: path(ROOTS_AUTH, "success", true),
	signup: path(ROOTS_AUTH, "signup", true),
};

export const PATH_COMMUNITY = {
	root: path(ROOTS_COMMUNITY, true),
	detail: (id) => path(ROOTS_COMMUNITY, id, true),
};
export const PATH_PRODUCT = {
	root: path(ROOTS_PRODUCT, true),
	create: path(ROOTS_PRODUCT, "create", false),
	detail: (id) => path(ROOTS_PRODUCT, id, true),
};
export const PATH_RANKING = {
	root: path(ROOTS_RANKING, true),
};

export const PATH_PAGE = {
	root: path(ROOTS, true),
};

export const PATH_USER = {
	user: (id) => path(ROOTS_USER, id, true),
};

export const PATH_SEARCH = {
	root: path(ROOTS, "search", true),
};

export const PATH_ARTICLE = {
	root: path("article", true),
};
