import { css } from "@emotion/react";
import {
  layoutBorder,
  pri,
  catYellow,
  fontBlack,
} from "../../../../../styles/common.css";

export default css`
  position: relative;
  ${layoutBorder};
  padding: 12px 14px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 4px 12px 32px -2px rgb(0 0 0 / 9%);

  li {
    min-width: 160px;
    padding: 12px 8px;
    color: ${fontBlack};

    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    cursor: pointer;

    a {
      width: 100%;
      display: flex;
    }

    &:hover {
      color: ${catYellow};
      transition: 0.2s ease;

      a {
        color: ${catYellow};
        transition: 0.2s ease;
      }
    }
  }

  @media screen and (max-width: 800px) {
    padding: 12px 14px;
  }
`;
