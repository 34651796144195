import { css } from "@emotion/react";
import {
  border,
  gray_01,
  black,
  fontBlack,
  fontBody,
  hoverBlue,
  boxHover,
  labelStyle,
  catYellow,
} from "../../../../styles/common.css";

export default css`
  display: flex;
  flex-direction: column;
  width: 100%;
  &.mainSearch {
    div {
      &::before {
        content: "🔍";
        width: 20px;
        height: 20px;
        display: flex;
        position: absolute;
        font-size: 16px;
        line-height: 1.5em;
        margin-left: 20px;
      }
      input {
        border-radius: 50px;
        text-indent: 32px;
        padding: 10px 16px;
      }
    }
  }
  .input {
    &-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      input {
        border: solid 1px rgba(0, 0, 0, 0.1);
        padding: 18px 20px;
        border-radius: 6px;
        width: 100%;
        color: ${fontBlack};
        font-size: 16px;
        font-weight: 500;
        &:hover {
          ${boxHover}
        }
        &:focus {
          border: solid 1px rgba(0, 0, 0, 0.8);
        }

        &:read-only {
          color: ${fontBody};
          cursor: not-allowed;

          &:hover {
            background-color: #eee;
          }
          &:focus {
            border-color: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }

  &.read-only {
    .input {
      &-wrapper {
      }
    }
    input {
      background-color: #eeeeee;
    }
  }

  /* 인풋 값에 에러가 있을 경우*/
  [aria-error="true"] {
    input {
      border-color: ${catYellow};
    }
  }

  /* 인풋에 값이 있는 경우*/
  [aria-have-value="true"] {
    input {
    }
  }

  /* 인풋에 값이 없는 경우*/
  [aria-have-value="false"] {
    input {
    }
  }

  @media screen and (max-width: 800px) {
    /*  */
  }
`;
