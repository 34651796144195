import css from "./labelComponent.css";

export default function LabelComponent({ name, required, label, description }) {
  return (
    <p css={css} className="label-wrapper">
      <label htmlFor={name} aria-required={!!required}>
        {label}
      </label>
      {description && <span>{description}</span>}
    </p>
  );
}
