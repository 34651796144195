import axiosWrapper from "../utils/axios-wrapper";

const { get, post } = axiosWrapper();

export const getArticles = async (
	id = "public",
	category,
	sort,
	query,
	scraped = false
) => {
	const search = query?.search ?? null;
	let url = `article/list/${id}?category=${category}&scraped=${scraped}&offset=${query?.offset}&limit=${query?.limit}&search=${search}&sort=${sort}`;

	if (query?.type) url += `&type=${query?.type}`;
	if (query?.productId) url += `&productId=${query?.productId}`;

	const response = await get(url);

	return response?.data ?? response;
};

export const scrapArticle = (id, token) => {
	const response = post(`article/scrap/${id}`, token);

	return response;
};

export const postArticle = (data) => {
	try {
		return post(`article`, null, data);
	} catch (e) {
		throw e;
	}
};

export const getCategories = () => {
	const response = get(`article/category/list`);

	return response;
};

export const articleAddView = (id) => {
	const response = post(`article/${id}/views`);

	return response;
};
