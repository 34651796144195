export const BASIC_CONSTANT = {
	BACKEND_URL: process.env.NEXT_PUBLIC_BACKEND_URL,
	REDIRECT_PATH: process.env.NEXT_PUBLIC_REDIRECT_PATH,
	CLIENT_TOKEN: "sofans.client.token",
	CLIENT_SNS_TOKEN: "sofans.sns.client.token",
	ADMIN_TOKEN: "sofans.admin.token",
	FORMTYPE: "multipart/form-data;",
	FILE_HOST: process.env.NEXT_PUBLIC_FILE_HOST,
	SITE_URL: process.env.NEXT_PUBLIC_SITE_URL,
	PREV_SITE_URL: "sofans.prev.site.url",
	PREVENT_POPUP: "sofans.prevent.popup",
};

export const LAYOUT_TYPES = {
	CLIENT: "client",
	ADMIN: "admin",
};

export const GALLERY_TYPES = {
	ORIGINAL: "original",
	THUMBNAIL: "thumbnail",
};
