import { useEffect, useState } from "react";
import Image from "next/image";

import Widget from "../../Widget";
import Navigations, { NAVIGATION_TYPE } from "../Navigation";

import catImage from "../../../../assets/images/cat.png";
import Logo from "../../../../assets/images/fronticon.png";
import Bell from "../../../../assets/images/bell.png";
import ActiveBell from "../../../../assets/images/bell.webp";
import Magnifier from "../../../../assets/images/magnifier.png";

import style from "./client.css";
import Router, { useRouter } from "next/router";
import { PATH_SEARCH } from "../../../../paths";
import useHandleNotification from "../../../../hooks/useNotification";
import { useRecoilState } from "recoil";
import { isMobileStore } from "../../../../recoils/isMobile";

function classNameHandle(hideMenu, hideWidget, onlyImage) {
  let className = "";

  if (hideMenu) className += " hide-menu";
  if (hideWidget) className += " hide-widget";
  if (onlyImage) className += " only-image";

  return className;
}

export default function ClientLayout({
  children,
  hideMenu = false,
  hideWidget = false,
  onlyImage = false,
  className: classNameProps,
}) {
  const className = classNameHandle(hideMenu, hideWidget, onlyImage);
  const [variant, setVariant] = useState(NAVIGATION_TYPE.VERTICAL);
  const [isMobile, setIsMobile] = useRecoilState(isMobileStore);
  const { notificationOpen, notificationData } = useHandleNotification();
  const router = useRouter();

  function handleResize() {
    if (window.innerWidth <= 800) {
      setVariant(NAVIGATION_TYPE.HORIZONTAL);
      setIsMobile(true);
    } else {
      setVariant(NAVIGATION_TYPE.VERTICAL);
      setIsMobile(false);
    }
  }

  function handleClick(type) {
    if (type === "search") {
      Router.push(`${PATH_SEARCH.root.url}`);
    }

    if (type === "notification") {
      notificationOpen();
      Router.push(router.asPath, `${router.asPath}?type=noti`, {
        shallow: true,
      });
    }

    if (type === "home") {
      Router.push("/");
    }
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        css={style}
        id="client-layout"
        className={`${className} ${
          isMobile ? "mobile-layout" : ""
        } ${classNameProps}`}
      >
        {!hideMenu && isMobile && (
          <div className="header-layout">
            <div>
              <Image
                src={Logo}
                width={26.73}
                height={28}
                alt="logo"
                onClick={() => handleClick("home")}
              />

              <div className="header-right">
                <div className="search" onClick={() => handleClick("search")}>
                  <Image
                    src={Magnifier}
                    width={24}
                    height={24}
                    alt="search-icon"
                  />
                </div>
                <div
                  onClick={() => handleClick("notification")}
                  className={
                    notificationData?.isActive
                      ? "active-notification notification"
                      : "notification"
                  }
                >
                  <Image
                    src={notificationData?.isActive ? ActiveBell : Bell}
                    width={24}
                    height={24}
                    alt="notification-icon"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <Navigations
          hideMenu={hideMenu}
          variant={variant}
          notificationOpen={notificationOpen}
          notificationData={notificationData}
        />
        <main>{children}</main>
        {!hideWidget && (
          <div
            className={`right-side-bar ${
              !onlyImage ? "grid-left" : "only-image"
            }`}
          >
            {onlyImage ? <img src={catImage.src} /> : <Widget />}
          </div>
        )}
      </div>
    </>
  );
}
