export default function VideoIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1667 2.375H5.83333C3.65 2.375 1.875 4.15 1.875 6.33333V11.3333C1.875 13.5167 3.65 15.2917 5.83333 15.2917H6.49167L5.275 17.7167C5.11667 18.025 5.24167 18.4 5.55833 18.5583C5.875 18.7167 6.24167 18.5917 6.4 18.275L7.89167 15.2833H12.1167L13.6083 18.275C13.7167 18.4917 13.9417 18.6167 14.1667 18.6167C14.2583 18.6167 14.3583 18.5917 14.4417 18.55C14.75 18.3917 14.875 18.0167 14.725 17.7083L13.5083 15.2833H14.1667C16.35 15.2833 18.125 13.5083 18.125 11.325V6.33333C18.125 4.15 16.35 2.375 14.1667 2.375ZM16.875 11.3333C16.875 12.825 15.6583 14.0417 14.1667 14.0417H5.83333C4.34167 14.0417 3.125 12.825 3.125 11.3333V6.33333C3.125 4.84167 4.34167 3.625 5.83333 3.625H14.1667C15.6583 3.625 16.875 4.84167 16.875 6.33333V11.3333Z"
        fill="black"
      />
      <path
        d="M12.6418 7.61665L9.97516 5.84165C9.52516 5.54165 8.95016 5.51665 8.47516 5.76665C8.00016 6.02498 7.7085 6.51665 7.7085 7.04998V10.6C7.7085 11.1416 8.00016 11.6333 8.47516 11.8833C8.69183 12 8.92516 12.0583 9.16683 12.0583C9.45016 12.0583 9.7335 11.975 9.97516 11.8166L12.6418 10.0416C13.0502 9.76665 13.2918 9.31665 13.2918 8.82498C13.2918 8.33331 13.0502 7.88331 12.6418 7.60831V7.61665ZM11.9502 8.99998L9.2835 10.775C9.19183 10.8333 9.1085 10.8083 9.06683 10.7833C9.02516 10.7583 8.9585 10.7083 8.9585 10.6V7.05831C8.9585 6.94998 9.02516 6.89998 9.06683 6.87498C9.09183 6.86665 9.12516 6.84998 9.16683 6.84998C9.20016 6.84998 9.24183 6.85831 9.2835 6.88331L11.9502 8.65831C12.0335 8.70831 12.0418 8.79165 12.0418 8.83331C12.0418 8.87498 12.0335 8.94998 11.9502 9.00831V8.99998Z"
        fill="black"
      />
    </svg>
  );
}
