import { useTheme } from "@emotion/react";
import style from "./style.css";

export default function EmbededWeb({
  webDomain,
  webTitle,
  webDescription,
  webImage,
  webUrl,
  readonly = false,
  onClick,
}) {
  return (
    <div css={style} className="embeded_web">
      {webImage && (
        <a
          href={`${webUrl}?utm_source=sofans.io&utm_medium=referral`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            style={{
              background: `url(${webImage})`,
            }}
            className="thumbnail"
          >
            {/* {readonly && <button onClick={onClick}>X</button>} */}
          </div>
        </a>
      )}
      <a
        className="contents"
        href={`${webUrl}?utm_source=sofans.io&utm_medium=referral`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>{webTitle}</p>
        <p>{webDescription}</p>
        <p>{webDomain}</p>
      </a>
    </div>
  );
}
