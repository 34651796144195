import axiosWrapper from "../utils/axios-wrapper";

const { post, get } = axiosWrapper();
export const likePost = (token, data) => {
	try {
		post(`like`, token, data);
	} catch (error) {
		throw error;
	}
};

export const isLikeApi = (token, data) => {
	const result = get(`like/isLike/${data.type}/${data.targetId}`, token);

	return result;
};
