import { useRecoilState } from "recoil";
import {
  defaultGalleryModal,
  defaultModal,
  galleryModalStore,
  modalStore,
} from "../recoils/modal";

export default function useModal() {
  const [state, updateState] = useRecoilState(modalStore);
  const [galleryState, updateGalleryState] = useRecoilState(galleryModalStore);

  const openModal = (data, type = "modal") => {
    const createModal = document.createElement("div");

    if (type === "gallery") {
      createModal.id = "gallery_root";
      document.body.appendChild(createModal);

      updateGalleryState({
        ...data,
        type,
        isOpen: true,
      });
    } else {
      createModal.id = "modal_root";
      document.body.appendChild(createModal);
      updateState({
        ...data,
        type,
        isOpen: true,
      });
    }
  };

  const closeModal = (type) => {
    let modalRoot = document.getElementById("modal_root");

    if (type === "gallery") {
      modalRoot = document.getElementById("gallery_root");
      updateGalleryState({ ...defaultGalleryModal, isOpen: false });
    } else {
      updateState({ ...defaultModal, isOpen: false });
    }

    modalRoot?.remove();
  };

  return { openModal, closeModal, modalData: state, galleryState };
}
