import axiosWrapper from "../utils/axios-wrapper";
import queryString from "query-string";

const { post, get, put, remove } = axiosWrapper();
export const createItem = (token, data) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const response = post("item", token, data);

	return response;
};

export const getListItem = (query, token) => {
	const queryData = queryString.stringify(query);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const response = get(`item/list?${queryData}`, token);

	return response;
};

export const postOGS = (token, data) => {
	const response = post("item/embeded", token, data);

	return response;
};

export const modifyItem = (token, id, data) => {
	const response = put(`item/${id}`, token, data);

	return response;
};

export const deleteItem = (token, id) => {
	const response = remove(`item/${id}`, token);

	return response;
};

export const getDetailItem = (data, userId) => {
	let id = data;
	if (typeof data === "object") id = data.id;
	let url = `item/${id}`;
	if (userId) url += `?userId=${userId}`;

	const response = get(url);

	return response;
};

export const getUserItems = (nickname, query, token) => {
	const queryData = queryString.stringify(query);

	const response = get(`item/list/${nickname}?${queryData}`, token);

	return response;
};

export const updateItemView = (id) => {
	try {
		const response = post(`item/${id}/views`);

		return response;
	} catch (error) {
		throw error;
	}
};

export const getItemsWithRelated = (productId, query, token) => {
	const queryData = queryString.stringify(query);
	try {
		const response = get(`item/related/${productId}?${queryData}`, token);

		return response;
	} catch (error) {}
};
