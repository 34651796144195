import { useRef } from "react";

import CheckIcon from "../../../assets/icons/Check.icon";
import ActiveCheckIcon from "../../../assets/icons/Active.Check.icon";

import { checkBoxItem } from "./checkbox.css";

export default function CheckboxItem(data) {
	const ref = useRef();

	const onClick = (e) => {
		e.preventDefault();
		e.stopPropagation();

		const checked = ref.current.checked;
		data.onChagne(!checked, data?.name);
	};

	return (
		<div css={checkBoxItem} className={data?.className || ""}>
			<div onClick={onClick}>
				{<div>{data.checked ? <ActiveCheckIcon /> : <CheckIcon />}</div>}
				<input
					type="checkbox"
					ref={ref}
					id={data.name}
					name={data.name}
					value={data.value}
					checked={data.checked}
				/>
				<label htmlFor={data.name}>{data.label}</label>
			</div>
			{data.link && (
				<a target="__blank" href={data.link.url}>
					{data.link.label}
				</a>
			)}
		</div>
	);
}
