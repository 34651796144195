import { ALERT_LOGIN } from "../components/common/Alert/utils/alert.constants";
import useAlert from "../hooks/useAlert";
import { BASIC_CONSTANT } from "./basic.constants";

export function useHandleLoginConfirm() {
	const { openAlert } = useAlert();

	const events = [
		{
			id: "confirm",
			onClick: () => {
				window.location.href = `${BASIC_CONSTANT.BACKEND_URL}/auth/google`;
			},
		},
	];

	return () => {
		return openAlert({
			...ALERT_LOGIN,
			events,
		});
	};
}
