import { buttonIconStyle } from "./button.css";

export default function ButtonIcon({
  icon,
  count,
  onClick,
  className,
  isActive,
  id,
  ariaLabel,
}) {
  const activeClassName = isActive ? "active" : "";

  const isHaveValue = count !== null && count !== undefined;

  return (
    <div
      className={`${className} ${activeClassName}`}
      id={`${id}`}
      css={buttonIconStyle}
      onClick={onClick}
    >
      <button aria-label={`${ariaLabel}`} id={`${id}`}>
        {typeof icon === "string" ? <span id={`${id}`}>{icon}</span> : icon}
        {isHaveValue ? <p id={`${id}`}>{count}</p> : null}
      </button>
    </div>
  );
}
