import { atom } from "recoil";
import { FORM_TYPE } from "../utils/constants";

export const DEFAULT_DATA_KEY = {
  CATEGORIES: "categories",
  WIDGET_DATA: "widgetData",
  WIDGET_DATA_TYPE: "widgetDataType",
  OPEN_BROWSER: "openBrowser",
  WIDGET_TEXT_DATA: "widgetTextData",
};

export const defaultData = {
  categories: [],
  widgetData: null,
  widgetDataType: FORM_TYPE.GET,
  widgetTextData: null,
  openBrowser: false,
};

export const defaultDataStore = atom({
  key: `defaultData`,
  default: defaultData,
});
