import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";

import NotificationPresenter from "./Presenter";
import { userInfo } from "../../recoils/user";
import {
	getNotificationList,
	getListCount,
	postReadNotification,
	postRemoveNotification,
} from "../../apis/notification.api";
import useNotification from "../../hooks/useNotification";
import useAlert from "../../hooks/useAlert";
import {
	ALERT_NOTIFICATION,
	ALERT_NOTIFICATION_DELETE,
} from "../common/Alert/utils/alert.constants";

export default function NotificationContainer() {
	const user = useRecoilValue(userInfo);
	const [data, setData] = useState([]);
	const [isLast, setIsLast] = useState(false);
	const [count, setCount] = useState(0);

	const { handleSetNotificationData } = useNotification();
	const { openAlert } = useAlert();

	const [query, setQuery] = useState({
		offset: 0,
		limit: 20,
	});

	const { refetch: refetchCount } = useQuery(
		["notification/count", query],
		() => getListCount(user.token),
		{
			onSuccess: ({ data }) => {
				setCount(data);
			},
		}
	);

	const { refetch } = useInfiniteQuery(
		["notification/list", query],
		({ pageParam }) => getNotificationList(user.token, query),
		{
			enabled: !!user.token,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			onSuccess: (data) => {
				const { pages } = data;
				const { isLast, list } = pages[0].data;
				setData((prev) => prev?.concat(list));
				setIsLast(isLast);
			},
		}
	);

	const { mutate } = useMutation((data) => postRemoveNotification(user.token, data), {
		onSuccess: ({ data }) => {
			if (data) {
				setData([]);
				if (isLast) {
					handleSetNotificationData({ isActive: false });
				} else {
					refetch();
				}
				refetchCount();
			}
		},
	});

	const { mutate: readMutate } = useMutation(
		(data) => postReadNotification(user.token, data),
		{
			onSuccess: ({ data }) => {
				if (data && isLast) handleSetNotificationData({ isActive: false });
			},
		}
	);

	const onChangeQuery = () => {
		setQuery((prev) => ({
			...prev,
			offset: prev.offset + 1,
		}));
	};

	const handleRemoveNotification = () => {
		if (data.length === 0) {
			openAlert({
				...ALERT_NOTIFICATION,
				description: "삭제할 알림이 없는 것 같아요.",
			});
			return;
		}

		const events = [
			{
				id: "confirm",
				onClick: () => {
					const ids = data.map((item) => item.id);
					mutate(ids);
				},
			},
		];

		openAlert({
			...ALERT_NOTIFICATION_DELETE,
			events,
		});
	};

	useEffect(() => {
		return () => {
			if (data.length === 0) return;
			const ids = data.map((item) => item.id);
			readMutate(ids);
		};
	}, [data]);

	return (
		<NotificationPresenter
			data={data}
			isLast={isLast}
			query={query}
			onChangeQuery={onChangeQuery}
			toUserNickname={user.profile.nickname}
			handleRemoveNotification={handleRemoveNotification}
			count={count}
		/>
	);
}
