import ModalPortal from "./ModalPortal";
import css from "./modal.css";
import ButtonIcon from "../Button/Button.Icon";
import { useEffect, useState } from "react";
import Button from "../Button";
import useAlert from "../../../hooks/useAlert";
import { ALERT_MODAL_DEFAULT } from "../Alert/utils/alert.constants";

export default function Modal(data) {
  const [width, setWidth] = useState(0);

  const isNoButtons = data?.isNoButtons ?? false;
  const showConfirmBtn = data?.showConfirmBtn ?? true;
  const { openAlert } = useAlert();

  useEffect(() => {
    const main = document.getElementsByTagName("main");
    const mainWidth = main[0].offsetWidth;

    setWidth(mainWidth);
  }, []);

  const className = data.className ?? "";
  const handleConfirm = (e) => {
    if (data.onConfirm) data.onConfirm();
    // data.closeModal();
  };

  const cancel = (e) => {
    e.stopPropagation();

    if (data?.isConfirmModal) {
      openAlert({
        ...ALERT_MODAL_DEFAULT,
        events: [
          {
            id: "confirm",
            onClick: () => {
              data.closeModal(data?.type);
              if (data?.onCancel) data.onCancel(data?.type);
            },
          },
        ],
      });
    } else {
      data.closeModal(data?.type);
      if (data?.onCancel) data.onCancel(data?.type);
    }
  };

  return (
    <ModalPortal type={data?.type ?? "modal"}>
      <div
        css={css}
        id={data?.id ?? ""}
        className={`modal-container ${className}`}
        style={{ width }}
      >
        <div className="modal-container-wrap">
          <div className="modal-wrapper">
            {/* close-btn */}
            {!isNoButtons && data.cancelText && (
              <div role="modal-buttons" className={`modal-buttons cancel-btn`}>
                {data.cancelText && (
                  <button aria-label="닫기 버튼" onClick={cancel}>
                    {data.cancelText}
                  </button>
                )}
              </div>
            )}

            {data.title && (
              <div role="modal-title" className="modal-title">
                {data.title}
              </div>
            )}
            <div role="modal-contents" className="modal-contents">
              {data.contents}
            </div>
            {!isNoButtons && showConfirmBtn && (
              <ButtonIcon
                className={`${data?.confirmClassName} confirm-btn`}
                onClick={handleConfirm}
                icon={data.confirmText || "확인"}
                ariaLabel={"확인 버튼"}
              />
            )}
          </div>

          {data?.description ?? null}
        </div>
        {/* communtiy-vertical-buttons */}
        {data?.btns?.length > 0 && (
          <div role="modal-buttons" className={`modal-buttons`}>
            {data?.btns?.map((btn, idx) => {
              return (
                <ButtonIcon
                  key={`btn-icon-${idx}`}
                  {...btn}
                  id={`btn_${idx}`}
                  ariaLabel={"상단 버튼"}
                />
              );
            })}
          </div>
        )}
      </div>
    </ModalPortal>
  );
}
