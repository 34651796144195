import { atom } from "recoil";

export const defaultModal = {
  isOpen: false,
  title: "",
  contents: "",
  confirmText: "",
  cancelText: "",
  isNoButtons: false,
  showConfirmBtn: true,
  isConfirmModal: false,
  btns: [],
  type: "modal",
  onConfirm: () => {},
  onCancel: () => {},
};

export const modalStore = atom({
  key: `modal`,
  default: defaultModal,
});

export const defaultGalleryModal = {
  isOpen: false,
  title: "",
  contents: "",
  confirmText: "",
  cancelText: "",
  isNoButtons: false,
  showConfirmBtn: true,
  isConfirmModal: false,
  btns: [],
  type: "modal",
  onConfirm: () => {},
  onCancel: () => {},
};

export const galleryModalStore = atom({
  key: `galleryModal`,
  default: defaultGalleryModal,
});
