export const LANGUAGE_JSON = [
	{
		name: "한국어",
		value: "kor",
	},
	{
		name: "영어",
		value: "eng",
	},
	{
		name: "일본어",
		value: "jpn",
	},
];

export const PRODUCT_PRICE_JSON = [
	{
		label: "무료",
		value: "free",
	},
	{
		label: "무료 체험(유료)",
		value: "freeTrial",
	},
	{
		label: "유료",
		value: "paid",
	},
];
