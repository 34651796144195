import Router from "next/router";
import productDefaultProfile from "../../../../assets/images/productDefaultThumbnail.png";
import profileThumb from "../../../../assets/images/profileThumb.png";
import linkImg from "../../../../assets/images/link.png";
import clapImg from "../../../../assets/images/clap.png";
import css from "./productWidget.css";

import ButtonComponent from "../../Button";
import Image from "next/image";
import WidgetMadeUser from "../WidgetMadeUser";
import WidgetLikeUser from "../WidgetLikeUser";
import Tags from "../../Tags";
import { FORM_TYPE } from "../../../../utils/constants";
import Text from "../../Text";
import { PATH_PRODUCT } from "../../../../paths";

export default function WidgetComponent({
  data,
  onClick,
  dataType,
  like,
  type,
  isActive = false,
  user = null,
  isMobile = false,
  isWidget = true,
}) {
  const isProduct = type === "product";
  const thumbnail = isProduct
    ? data?.productImage ?? productDefaultProfile?.src
    : data?.profile?.image ?? profileThumb?.src;

  const label = activeLabel(isProduct, isActive);

  return (
    <div css={css} className="widget-data-container">
      <div className="widget-data-wrapper">
        <div
          style={{
            backgroundImage: `url(${thumbnail})`,
          }}
          className={`widget-component-thumbnail background-image ${
            isProduct ? "product" : "user-profile"
          }`}
          role="img"
        />
        <h3>
          {isProduct ? `${data?.name_en} - ${data?.name}` : data?.profile?.name}
        </h3>

        <p className="widget-component-grey">
          @{isProduct ? data?.productUniqueId : data?.profile?.nickname}
        </p>
      </div>
      <div className="widget-component-by-user">
        {!isProduct && (
          <>
            <p>
              <span>{data?.followings?.length ?? 0}</span>
              <span>팔로우 중</span>
            </p>
            <p>
              <span>{data?.followers?.length ?? 0}</span>
              <span>팔로워</span>
            </p>
          </>
        )}
        {isProduct && (
          <p>
            <span>{data?.viewCount ?? 0}</span>
            <span>조회 수</span>
          </p>
        )}
        <p>
          <span>{data?.likes ?? 0}</span>
          <span>{isProduct ? "받은 응원" : "보낸 응원"}</span>
        </p>
        {isProduct && (
          <p>
            <span>{data?.mentionCount ?? 0}</span>
            <span>멘션 수</span>
          </p>
        )}
      </div>
      <p className="widget-component-linedescription">
        {/* 위젯쪽의 프로덕트랑 프로필 한 줄 소개 */}
        {isProduct
          ? data?.oneLineDescription ?? (
              <Text value="아직 한 줄 소개글이 없다옹." />
            )
          : data?.profile?.oneLineIntroduction ?? (
              <Text value="아직 한 줄 소개글이 없다옹." />
            )}
      </p>

      <div className="widget-component-btns">
        {!data?.isMe && (
          <ButtonComponent
            data={{
              className: "widget-component-btn",
              label: (
                <div id="like_widget">
                  <p>{label}</p>
                </div>
              ),
              onClick: () => like(data?.id, type, data?.users),
              isActive,
            }}
          />
        )}
        <ButtonComponent
          data={{
            className: "widget-component-btn link-btn",
            label: (
              <div>
                <img src={linkImg?.src} alt="button_icon" />
              </div>
            ),
            onClick: (e) =>
              onClick(
                "links",
                isProduct
                  ? [
                      {
                        id: "website",
                        link: data?.website,
                        name: "공식 웹사이트",
                      },
                      ...data?.links,
                    ]
                  : data.profile.links,
                e,
                isProduct
              ),
          }}
        />
        {data?.isMe && (
          <ButtonComponent
            data={{
              className: "widget-component-btn-border",
              label: isProduct
                ? dataType === FORM_TYPE.GET
                  ? "소개 수정"
                  : "수정 취소"
                : dataType === FORM_TYPE.GET
                ? "프로필 수정"
                : "프로필 수정 취소",
              onClick: () => onClick(dataType),
            }}
          />
        )}

        {/* {isProduct && data?.isMe && (
          <ButtonComponent
            data={{
              className: "widget-component-btn-border",
              label: dataType === FORM_TYPE.GET ? "소개 수정" : "수정 취소",
              onClick: () => onClick(dataType),
            }}
          />
        )} */}
      </div>

      {isProduct && (
        <>
          <div className="divider" />
          <div>
            <p className="widget-component-grey subtitle">Activities</p>
            <Tags tags={data?.activities} isShow />
          </div>
        </>
      )}
      {(!isMobile || (isMobile && isProduct)) && (
        <>
          {!isMobile && <div className="divider" />}
          <div className="fans">
            <p className="widget-component-grey subtitle">
              {isProduct ? `Fans` : `${data?.profile?.name}의 프로덕트`}
            </p>
            {isProduct ? (
              <WidgetLikeUser
                likeUsers={data?.likeUsers}
                productName={data?.name}
                user={user}
              />
            ) : (
              <WidgetProductComponent data={data?.products} />
            )}
          </div>
          {!isMobile && <div className="divider" />}
          <div className="makers">
            <p className="widget-component-grey subtitle">
              {isProduct ? `Makers` : "관심 주제"}
            </p>
            {isProduct ? (
              <WidgetMadeUser users={data?.users} data={data} />
            ) : data?.profile?.tags?.length > 0 ? (
              <Tags tags={data?.profile?.tags} isShow={true} />
            ) : (
              // 위젯 프로필 - 관심 주제
              <Text value="아직 관심 태그가 없다옹." />
            )}
          </div>
          {!isMobile && <div className="divider" />}
        </>
      )}
    </div>
  );
}

function WidgetProductComponent({ data }) {
  return (
    <div className="widget-product-components">
      {data?.length === 0 && <Text value="아직 프로덕트가 없다옹." />}
      {data?.map((item, idx) => {
        return (
          <div
            key={`widget_product_component_${idx}`}
            className="widget-product-item"
            onClick={() =>
              Router.push(
                PATH_PRODUCT.detail(item?.productUniqueId).url + "?tab=0"
              )
            }
          >
            <div>
              <p className="product_title">{item?.name_en}</p>
              <p>{item?.oneLineDescription}</p>
              <div>{/* 프로필 이미지 */}</div>
            </div>
            <img
              className="widget-product-component-thumbnail"
              src={item?.productImage ?? productDefaultProfile.src}
              alt="product_thumbnail_image"
            />
          </div>
        );
      })}
    </div>
  );
}

function activeLabel(isProduct, isActive) {
  if (isProduct) {
    return "👏 응원하기";
  }

  return isActive ? "팔로우 취소" : "팔로우하기";
}
