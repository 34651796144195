import { useRecoilState } from "recoil";
import CancelIcon from "../assets/icons/Cancel.icon";
import NotificationContainer from "../components/Notification";
import { notificationStore, notificationDefaultData } from "../recoils/notification";
import { BASIC_CONSTANT } from "../utils/basic.constants";
import useModal from "./useModal";
import { useHandleLoginConfirm } from "../utils/login.function";

export default function useHandleNotification() {
	const [state, setState] = useRecoilState(notificationStore);
	const { openModal, closeModal } = useModal();
	const loginConfirm = useHandleLoginConfirm();

	const notificationOpen = () => {
		const token = localStorage.getItem(BASIC_CONSTANT.CLIENT_TOKEN);

		if (!token) {
			loginConfirm();
			return;
		}

		const data = {
			contents: <NotificationContainer />,
			className: "notification-modal",
			showConfirmBtn: false,
			cancelText: <CancelIcon />,
			btns: [],
		};

		openModal(data);
		// setState((prev) => ({ ...prev, open: true }));
	};

	const notificationClose = () => {
		closeModal();
		// setState({ ...notificationDefaultData, open: false });
	};

	const handleSetNotificationData = (data) => {
		setState((prev) => ({ ...prev, ...data }));
	};

	return {
		notificationData: state,
		notificationOpen,
		notificationClose,
		handleSetNotificationData,
	};
}
