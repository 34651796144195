import Router from "next/router";
import ButtonComponent from "../../Button/Button.item";
import ArrowIcon from "../../../../assets/icons/Arrow.icon";
import css from "./style.css";
import ProfileImage from "../../ProfileImage";
import Text from "../../Text";
import { PATH_USER } from "../../../../paths";
import DefaultProfile from "../../../../assets/images/profileThumb.png";

export default function WidgetMadeUser({ users = [], data }) {
  return (
    <div css={css}>
      {users.length === 0 ? (
        <>
          <p>
            {data?.name}의 만든이가 등록되지 않았습니다.
            <br />
            아래의 버튼을 통해 메이커 인증을 해주세요.
          </p>
          <ButtonComponent
            className="widget-made-user-btn"
            onClick={() => {
              alert(
                `메이커 인증은 현재 문의로만 진행이 가능해요.😿\n우측 하단의 토글 (채널톡)으로 문의 주시면 간단한 확인 후 인증을 도와드릴게요.`
              );
            }}
            label={
              <div>
                <p>메이커 인증</p>
                <ArrowIcon />
              </div>
            }
          />
        </>
      ) : (
        users.map((user) => {
          return <MadeUser key={`widget_made_user_${user.id}`} user={user} />;
        })
      )}
    </div>
  );
}

function MadeUser({ user }) {
  return (
    <div
      className="made-user"
      onClick={() => {
        Router.push(PATH_USER.user(user?.nickname)?.url + "?tab=0");
      }}
    >
      <ProfileImage
        img={user.image ?? DefaultProfile.src}
        width={48}
        height={48}
        isCircle
      />
      <div>
        <p>
          <span className="widget-component-username">{user.name}</span>
          <span className="widget-component-nickname">@{user.nickname}</span>
        </p>

        {/* 위젯 만든이의 한줄 소개 */}
        <p>
          {user.oneLineIntroduction ?? (
            <Text
              className="widget-no-data"
              value="아직 한 줄 소개가 없다옹."
            />
          )}
        </p>
      </div>
    </div>
  );
}
