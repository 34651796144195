import css from "./style.css";

export default function WidgetTextComponent({ data }) {
  return (
    <div css={css}>
      {data.map((item) => {
        return (
          <div className="widget-text-component" key={item.title}>
            <h4>{item.title}</h4>
            {Array.isArray(item.description) ? (
              item.description.map((text) => {
                return <p key={text}>{text}</p>;
              })
            ) : (
              <p>{item.description}</p>
            )}
          </div>
        );
      })}
    </div>
  );
}
