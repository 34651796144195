export default function ArrowIcon() {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.679 0.866117L16.9672 7.1544C17.4554 7.64256 17.4554 8.43401 16.9672 8.92217L10.679 15.2105C10.1908 15.6986 9.39934 15.6986 8.91119 15.2105C8.42303 14.7223 8.42303 13.9308 8.91119 13.4427L13.0656 9.28829H1.91669C1.22633 9.28829 0.666687 8.72864 0.666687 8.03829C0.666687 7.34793 1.22633 6.78829 1.91669 6.78829H13.0656L8.91119 2.63388C8.42303 2.14573 8.42303 1.35427 8.91119 0.866117C9.39934 0.377961 10.1908 0.377961 10.679 0.866117Z"
        fill="black"
      />
    </svg>
  );
}
