import axiosWrapper from "../utils/axios-wrapper";
import queryString from "query-string";

const { post, get, patch } = axiosWrapper();

export const createUser = (data, query) => {
	try {
		const response = post(`user/signUp?provider=${query}`, null, data);

		return response;
	} catch (e) {
		throw e;
	}
};

export const getMe = (token) => {
	try {
		const response = get("user/me", token);
		return response;
	} catch (error) {
		throw error;
	}
};

export const patchUser = ({ token, data }) => {
	try {
		const response = patch("user", token, data);
		return response;
	} catch (e) {
		throw e;
	}
};

export const searchUserByEmail = (email, token) => {
	const response = get(`user/search?email=${email}`, token);
	return response;
};

export const getUserList = (query) => {
	const queryData = queryString.stringify(query);

	const response = get(`user/list?${queryData}`);
	return response;
};

export const followUser = (userId, token) => {
	const response = post(`user/follow/${userId}`, token);
	return response;
};

export const getUserListByText = (text, token) => {
	const response = get(`user/tag/list?text=${text}`, token);
	return response;
};

export const getUserById = (id) => {
	const response = get(`user/${id}`);
	return response;
};

export const getUserListBySearch = (query) => {
	const queryData = queryString.stringify(query);

	const response = get(`user/list/search?${queryData}`);
	return response;
};
