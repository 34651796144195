import Image from "next/image";
import Router, { useRouter } from "next/router";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import validationUrl from "is-url";

import { postArticle } from "../../../apis/article.api";
import CancelIcon from "../../../assets/icons/Cancel.icon";
import useModal from "../../../hooks/useModal";

import { userInfo } from "../../../recoils/user";
import ModalHome from "../../Home/Modal.Home";

import Input from "../Input";

import style from "./widjet.css";

import {
  PATH_ARTICLE,
  PATH_COMMUNITY,
  PATH_PAGE,
  PATH_PRODUCT,
  PATH_SEARCH,
  PATH_USER,
} from "../../../paths";
import WidgetComponent from "./WidgetComponent";

import rocketImg from "../../../assets/images/rocket.png";

import { useEffect, useState } from "react";
import WidgetTextComponent from "./WidgetTextComponent";
import { bottomLinks, productModifyCreate } from "./constants";
import { likeAPI } from "../../../utils/common-api";
import usePopup from "../../../hooks/usePopup";
import { FORM_TYPE } from "../../../utils/constants";
import { followUser } from "../../../apis/user.api";
import ChannelService from "../../../utils/ChannelService";

import Heart from "../../../assets/images/heart.svg";
import gemstone from "../../../assets/images/gemstone.png";
import WidgetButtons from "./WidgetButtons";
import VcLogoIcon from "../../../assets/images/philosophia_vc_logo.png";
import { WIDGET_CONSTANTS_PAGENAME } from "./widget.constants";
import { widgetDataStore } from "../../../recoils/widgetData";
import { widgetDataTypeStore } from "../../../recoils/widgetDataType";
import { widgetTextDataStore } from "../../../recoils/widgetTextData";
import { defaultDataStore } from "../../../recoils/defaultData";
import CreateCommunityModal from "../Community/CreateCommunityModal";
import { BASIC_CONSTANT } from "../../../utils/basic.constants";
import { useHandleLoginConfirm } from "../../../utils/login.function";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { refetchStore } from "../../../recoils/refetchRecoil";
import { postProductCount } from "../../../apis/product.api";
import { PRODUCT_COUNT_TYPE } from "../../../utils/product.constants";
import useAlert from "../../../hooks/useAlert";
import {
  ARTICLE_INPUT_ERROR,
  ARTICLE_SUBMIT_COMPLETE,
} from "../Alert/utils/alert.constants";
import {
  convertProductTagValues,
  convertTagValues,
  currentPageHandle,
} from "../../../utils/common-function";

export default function Widget() {
  const user = useRecoilValue(userInfo);
  const router = useRouter();

  const defaultData = useRecoilValue(defaultDataStore);
  const setRefetch = useSetRecoilState(refetchStore);
  const [widgetData, setWidgetData] = useRecoilState(widgetDataStore);
  const [widgetDataType, setWidgetDataType] =
    useRecoilState(widgetDataTypeStore);
  const [widgetTextData, setWidgetTextData] =
    useRecoilState(widgetTextDataStore);
  const { openModal, modalData, closeModal } = useModal();
  const { openPopup } = usePopup();
  const { openAlert } = useAlert();
  const loginConfirm = useHandleLoginConfirm();

  const [isShowCancel, setIsShowCancel] = useState(false);
  const [isHideBtn, setIsHideBtn] = useState(false);
  const [btnLabel, setBtnLabel] = useState();

  const queryClient = useQueryClient();

  const pagename = currentPageHandle(router.pathname);

  const isActive =
    pagename === WIDGET_CONSTANTS_PAGENAME.USER ||
    pagename === WIDGET_CONSTANTS_PAGENAME.COMMUNITY
      ? widgetData?.isFollow
      : widgetData?.isLike;

  // postArticle
  const postArticleMutation = useMutation(postArticle, {
    mutationKey: "postArticle",

    onSuccess: (data) => {
      const events = [
        {
          id: "confirm",
          onClick: () => {
            setRefetch(true);
            closeModal();
            return queryClient.invalidateQueries({
              queryKey: "article/list/init",
            });
          },
        },
      ];

      openAlert({
        ...ARTICLE_SUBMIT_COMPLETE,
        events,
      });
    },
  });

  const submitNewArticle = async () => {
    let id = user?.id ? user?.id : "public";
    const data = { userId: id };
    const modalComponent = [
      "url",
      "myArticle",
      "categoryId_tags",
      "tags",
      "products",
    ];

    try {
      for (let key of modalComponent) {
        const element = document.getElementById(key);

        if (element) {
          let value = key === "myArticle" ? element?.checked : element?.value;

          if (key === "tags") value = convertTagValues(element);
          if (key === "products") value = convertProductTagValues(element);
          if (key === "myArticle" && value === true) {
            if (id === "public") {
              loginConfirm();
              break;
            } else data.userId = id;
          } else {
            if (key === "categoryId_tags") {
              const value = element
                ?.querySelector(".tag")
                .getAttribute("value");

              const item = defaultData.categories.find(
                (item) => item.value === value
              );
              data["categoryId"] = item.id;
            } else data[key] = value;
          }
        }
      }

      if (!data.url && !data.categoryId) {
        openAlert({
          ...ARTICLE_INPUT_ERROR,
          title: `입력되지 않은 내용이 있는 것 같아요. 내용을 입력해주세요.`,
        });
        return;
      }
      if (data.url && !data.categoryId) {
        openAlert({
          ...ARTICLE_INPUT_ERROR,
          title: "카테고리를 선택해주세요.",
        });
        return;
      }
      if (!data.url && data.categoryId) {
        openAlert({
          ...ARTICLE_INPUT_ERROR,
          title: "아티클 URL을 입력해주세요.",
        });

        return;
      }

      if (data?.url && data?.categoryId) {
        if (!validationUrl(data.url)) {
          openAlert({
            ...ARTICLE_INPUT_ERROR,
            title: "URL 형식을 다시 한번 확인해주세요.",
          });

          return;
        }

        postArticleMutation.mutate(data);
      }
    } catch (error) {
      openAlert({
        ...ARTICLE_INPUT_ERROR,
        title:
          "아티클 URL이 저희가 가져올 수 없는 URL인 것 같아요. 😢 다른 URL을 입력해주세요.",
      });

      // console.log(error);
    }
  };

  const btnClickEvent = () => {
    if (router.pathname === PATH_ARTICLE.root.url) {
      if (modalData?.isOpen) {
        closeModal();
        return;
      } else {
        const modalData = {
          title: "모두에게 소개하고 싶은\n멋진 글을 알고계신가요?",
          contents: <ModalHome categories={defaultData?.categories} />,
          confirmText: "❤️ 모두에게 글 소개하기",
          cancelText: <CancelIcon />,
          confirmClassName: "black-btn",
          isConfirmModal: true,
          onConfirm: submitNewArticle,
        };
        openModal(modalData);
        Router.push(
          PATH_ARTICLE.root.url,
          `${PATH_ARTICLE.root.url}?type=article`,
          {
            shallow: true,
          }
        );
        return;
      }
    }

    if (isShowCancel) {
      if (widgetDataType !== FORM_TYPE.GET) {
        const btn = document.getElementById("hook-form-btn");

        btn.click();
        return;
      }
    }

    if (pagename === WIDGET_CONSTANTS_PAGENAME.PRODCUT) {
      if (!user?.token) {
        loginConfirm();
        return;
      }
      router.push(PATH_PRODUCT.create.url);
      return;
    }

    if (pagename === WIDGET_CONSTANTS_PAGENAME.COMMUNITY) {
      if (!user?.token) {
        loginConfirm();
        return;
      }

      const modalData = {
        title: "게시글 작성하기",
        contents: <CreateCommunityModal data={null} />,
        className: "create-community-modal modal-community",
        isNoButtons: true,
        isConfirmModal: true,
      };
      openModal(modalData);
      Router.push(
        `${PATH_COMMUNITY.root.url}`,
        `${PATH_COMMUNITY.root.url}/create?type=modify`,
        {
          shallow: true,
        }
      );
    }
  };

  async function handleCount(type) {
    await postProductCount(widgetData.id, type, user?.token);
  }

  function widgetClickHandle(type, params, e, isProduct) {
    if (type === FORM_TYPE.GET) {
      if (pagename === "community") {
        Router.push(`${PATH_USER.user(user.profile.nickname).url}?type=edit`);
      } else {
        setWidgetDataType(FORM_TYPE.UPDATE);
      }
    }
    if (type === FORM_TYPE.UPDATE) {
      setWidgetDataType(FORM_TYPE.GET);
    }

    if (type === "links") {
      if (isProduct) {
        params = params?.map((item) => {
          const newItem = { ...item };
          if (newItem?.id === "website") {
            newItem.onClick = () => handleCount(PRODUCT_COUNT_TYPE.WEBSITE);
          } else {
            newItem.onClick = () => handleCount(PRODUCT_COUNT_TYPE.EXTERNAL);
          }

          return newItem;
        });
      }

      openPopup("menu", params, e);
    }
  }

  function cancelBtn() {
    if (FORM_TYPE.CREATE === widgetDataType) {
      router.back();
    }

    if (FORM_TYPE.UPDATE === widgetDataType) {
      setWidgetDataType(FORM_TYPE.GET);
    }
  }

  async function like(id, type, users) {
    if (!user?.token) {
      loginConfirm();
      return;
    }

    if (type === "product") {
      const userIds = users.map((user) => user?.id);
      try {
        likeAPI(user?.token, id, type, userIds);
        setWidgetData({
          ...widgetData,
          isLike: !widgetData.isLike,
        });
      } catch (error) {
        loginConfirm();
        return;
      }
    }

    if (type === "user" || type === "community") {
      await followUser(id, user?.token);
      let followers = [...widgetData.followers];
      if (widgetData.isFollow) {
        followers = followers.filter(
          (follower) => follower?.followingId !== user?.id
        );
      }
      if (!widgetData.isFollow) {
        followers.push({
          followingId: user?.id,
        });
      }

      setWidgetData({
        ...widgetData,
        followers,
        isFollow: !widgetData.isFollow,
      });
    }
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      Router.replace(
        `${PATH_SEARCH.root.url}?keyword=${e.target.value}&type=product`,
        undefined,
        {
          shallow: true,
        }
      );
    }
  };

  useEffect(() => {
    // hide btn
    if (pagename === WIDGET_CONSTANTS_PAGENAME.USER) {
      if (widgetDataType === FORM_TYPE.GET) setIsHideBtn(true);
      if (widgetDataType !== FORM_TYPE.GET) setIsHideBtn(false);
    }

    if (pagename === WIDGET_CONSTANTS_PAGENAME.SEARCH) {
      setIsHideBtn(true);
    }

    // widget text, show cancel
    if (widgetDataType === FORM_TYPE.GET) {
      setWidgetTextData(null);
      setIsShowCancel(false);
    }
    if (widgetDataType !== FORM_TYPE.GET) {
      setIsShowCancel(true);
      setWidgetTextData(productModifyCreate);
    }

    const label = labelHandle(router.pathname, pagename, widgetDataType);

    setBtnLabel(label);
  }, [router.asPath, widgetDataType]);

  useEffect(() => {
    const channelService = new ChannelService();
    let memberId = null,
      name = null,
      email = null;
    if (user?.id) {
      memberId = user?.id;
      name = user?.profile?.name;
      email = user?.email;
    }

    channelService.boot({
      pluginKey: "ee5ee1c5-0a6a-4bc8-acbe-d71b023f8706",
      memberId,
      profile: {
        name,
        email,
      },
    });
  }, [user]);

  // useEffect(() => {
  //   if (widgetTextData) {
  //     setWidgetTextData(widgetTextData);
  //   }
  // }, [widgetTextData]);

  return (
    <div css={style}>
      <div className="widget_bottom">
        {!isHideBtn && (
          <WidgetButtons
            isShowCancel={isShowCancel}
            cancelBtn={cancelBtn}
            btnClickEvent={btnClickEvent}
            label={btnLabel}
          />
        )}
        {widgetDataType === FORM_TYPE.GET && (
          <Input
            className="mainSearch"
            placeholder="검색어를 입력해주세요"
            onKeyDown={onKeyDown}
          />
        )}

        {widgetDataType === FORM_TYPE.GET &&
          widgetData &&
          Object.keys(widgetData)?.length > 0 && (
            <WidgetComponent
              data={widgetData}
              dataType={widgetDataType}
              onClick={widgetClickHandle}
              like={like}
              type={pagename}
              isActive={isActive}
              user={user}
            />
          )}

        {widgetTextData && <WidgetTextComponent data={widgetTextData} />}

        {/* 정적 광고 이미지 */}
        <div className="pc-ads-wrapper">
          <div className="pc-ad img2">
            <a href="https://info.sofans.io/notice/2" target="__blank">
              <Image
                id="pc-ad-img2"
                src="https://teamflow.kr/img/sofans/pc_ad_banner2.webp"
                alt="sofans-ad-image2"
                width={320}
                height={230}
              />
            </a>
          </div>
          <div className="pc-ad img1">
            <a href="https://sofans.io/product/create" target="__blank">
              <Image
                id="pc-ad-img1"
                src="https://teamflow.kr/img/sofans/pc_ad_banner1.webp"
                alt="sofans-ad-image1"
                width={320}
                height={230}
              />
            </a>
          </div>
          <div className="pc-ad img3">
            <a href="https://open.kakao.com/o/gXwZ5zkf" target="__blank">
              <Image
                id="pc-ad-img3"
                src="https://teamflow.kr/img/sofans/kakaotalk.webp"
                alt="sofans-ad-image3"
                width={320}
                height={40}
              />
            </a>
          </div>
        </div>

        <footer>
          {bottomLinks.map((item) => {
            return (
              <a key={item.label} target="__blank" href={item.url}>
                <span>{item.label}</span>
              </a>
            );
          })}
        </footer>
        <div className="vc-logo">
          <p>Backed by</p>
          <Image
            id="philosophia-vc-logo"
            src={VcLogoIcon}
            alt="philosophia-vc-logo"
            width={840}
            height={110}
          />
        </div>
        <p>© 2023. Teamflow Inc. all rights reserved.</p>
      </div>
    </div>
  );
}

function labelHandle(pathname, pagename, widgetDataType) {
  if (widgetDataType !== FORM_TYPE.GET) return "저장하기";

  if (pathname === PATH_ARTICLE.root.url) {
    return (
      <div className="label-btn article-submit-widget">
        <Heart />
        <span>멋진 아티클 제보하기</span>
      </div>
    );
  }

  if (
    pagename === WIDGET_CONSTANTS_PAGENAME.PRODCUT ||
    pagename === PATH_PRODUCT.root.url
  ) {
    return (
      <div className="label-btn product-widget-button">
        {/* <Image src={rocketImg} width={20} height={20} /> */}
        <img src={rocketImg?.src} alt="button_icon" />
        <span>프로덕트 등록하기</span>
      </div>
    );
  }

  if (pagename === WIDGET_CONSTANTS_PAGENAME.COMMUNITY) {
    return (
      <div className="label-btn user-widget-button">
        <img src={gemstone?.src} alt="button_icon" />
        <span>게시글 작성하기</span>
      </div>
    );
  }
}
