export const handleAlertButtons = ({ buttons = [], events = [] }) => {
	const newbuttons = [];

	if (buttons?.length > 0) {
		buttons.forEach((button) => {
			const { id, label } = button;

			const event = events.find((event) => event?.id === id);

			const newbutton = {
				id,
				label,
			};

			if (event) {
				Object.assign(newbutton, {
					onClick: event?.onClick,
				});
			}

			newbuttons.push(newbutton);
		});
	}

	return newbuttons;
};
