import { css } from "@emotion/react";

export default css`
  position: relative;
  cursor: pointer;
  /* z-index: -1; */

  .icon {
    position: absolute;
    bottom: -10px;
    right: -20px;
  }

  .background-image {
    width: inherit;
  }
`;
