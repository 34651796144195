import css from "./grid.css";
import CaenCelIcon from "../../../assets/icons/Cancel.icon";
import useModal from "../../../hooks/useModal";
import ImageGalleryComponent from "../ImageGallery";
import CancelIcon from "../../../assets/icons/Cancel.icon";
import { GALLERY_TYPES } from "../../../utils/basic.constants";

export default function Grid({
  data = [],
  remove,
  readonly = true,
  className = "",
}) {
  const gridClassName = `grid-${data.length}`;

  const { openModal } = useModal();
  const handleOpenModal = (e) => {
    // e.preventDefault();
    e.stopPropagation();

    const newData = data.map((item) => item.file);

    const modalData = {
      contents: (
        <ImageGalleryComponent
          data={newData}
          gallery_type={GALLERY_TYPES.ORIGINAL}
          slidesPerView={1}
          spaceBetween={0}
        />
      ),
      className: "modal-gallery",
      showConfirmBtn: false,
      cancelText: <CancelIcon />,
    };

    openModal(modalData, "gallery");
  };

  return (
    <div css={css} className={`${gridClassName} ` + className}>
      {data.map((item) => {
        return (
          <div
            key={`grid_${item.id}`}
            className="background"
            style={{ backgroundImage: `url(${item.url})` }}
            onClick={handleOpenModal}
          >
            {!readonly && (
              <div className="remove-btn" onClick={(e) => remove(e, item.id)}>
                <CaenCelIcon size="medium" />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
