import style from "./profile-image.css";

import profileImg from "../../../assets/images/profileThumb.png";

export default function ProfileImage({
	img,
	width = 80,
	height = 80,
	onClick,
	className,
	isAction = false,
	icon = null,
	isCircle = false,
}) {
	const handleOnclick = (e) => {
		if (onClick && isAction) onClick(e);
	};

	return (
		<div
			css={style}
			style={{ width, height, borderRadius: isCircle ? "50%" : "0" }}
			onClick={handleOnclick}
			className={className}
		>
			<div
				style={{
					backgroundImage: `url(${img})`,
					height,
					borderRadius: isCircle ? "50%" : "0",
				}}
				onError={(e) => {
					console.log("e", e);
				}}
				className="background-image"
			/>

			{icon && <div className="icon">{icon}</div>}
		</div>
	);
}
