import Image from "next/image";
import Router from "next/router";
import TextComponent from "./Text.component";
import ProductDefaultImage from "../../../assets/images/productDefaultThumbnail.png";

export default function TextLinkImage({
	name,
	link,
	image,
	className = "",
	target = "_blank",
	rel = "noopener noreferrer",
	onClick,
	type,
}) {
	function onClick(e) {
		e.preventDefault();
		if (!link) return;

		window.open(link, target, rel);
	}

	return (
		<div className={`text-image-form-item ${className} ${type}`} onClick={onClick}>
			{type === "inner" && (
				<Image src={image ?? ProductDefaultImage} alt={name} width={28} height={28} />
			)}
			<TextComponent name={name} />
		</div>
	);
}
