import { css } from "@emotion/react";
import {
  black,
  blur_bg,
  border,
  catYellow,
  fontBlack,
  white,
} from "../../../../../styles/common.css";

export default css`
  margin-top: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #777777;

  .widget-made-user-btn {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: ${border};
    border-radius: 40px;
    padding: 11.5px 16px;
    background-color: ${white};
    opacity: 0.7;

    &:hover {
      opacity: 1;
      background: ${blur_bg};
      border: ${border};
    }

    div {
      display: flex;
      align-items: center;
      flex-direction: row;
      p {
        margin-right: 9.5px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: ${fontBlack};
        opacity: 0.7;
      }
    }
  }

  .made-user {
    display: flex;
    cursor: pointer;

    &:hover {
      .widget-component-username {
        color: ${catYellow};
        transition: 0.2s ease;
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }

    > div {
      &:last-of-type {
        margin-left: 12px;
        width: 260px;
      }

      p {
        &:first-of-type {
          font-weight: 600;
          line-height: 19px;
          color: ${fontBlack};
          font-size: 16px;
          margin-bottom: 8px;
        }
        &:last-of-type {
          color: #777777;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;

          width: 260px;
          white-space: normal;
          word-wrap: break-word;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  @media screen and (max-width: 1212px) {
    .made-user > div p:last-of-type {
      max-width: 160px;
    }
  }

  @media screen and (max-width: 800px) {
    margin-top: 12px;

    .widget-made-user-btn {
      margin: 20px 0 0 0;
    }

    .made-user {
      > div:first-of-type {
        width: 40px !important;

        > div {
          height: 40px !important;

          p:first-of-type {
            font-size: 14px;
          }
        }
      }

      > div:last-of-type {
        width: 100%;
      }
    }
  }
`;
