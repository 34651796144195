import Router from "next/router";
import { PATH_USER } from "../../../../paths";
import ProfileImage from "../../ProfileImage";
import css from "./widgetLikeUser.css";
import DefaultProfile from "../../../../assets/images/profileThumb.png";

export default function WidgetLikeUser({ likeUsers = [], productName, user }) {
  return (
    <div css={css}>
      {likeUsers.length === 0 ? (
        <>
          <p>
            {user?.profile.name && `${user.profile.name}님이`} {productName}의
            첫 번째 팬이 되어 주세요!
            <br />
            응원하기👏 로 팬이 될 수 있어요.
          </p>
        </>
      ) : (
        <div>
          <div>
            {likeUsers.map((user, idx) => {
              if (idx <= 21)
                return <LikeUser key={`like_user_${user.id}`} user={user} />;
            })}
          </div>
          <p>+ {likeUsers.length} users</p>
        </div>
      )}
    </div>
  );
}

function LikeUser({ user }) {
  return (
    <div
      className="like-user"
      onClick={() => Router.push(PATH_USER.user(user?.nickname).url + "?tab=0")}
    >
      <ProfileImage
        className="like-user-profile"
        width={40}
        height={40}
        img={user.image ?? DefaultProfile.src}
        isCircle
      />
    </div>
  );
}
