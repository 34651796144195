import { css } from "@emotion/react";
import {
  border,
  catYellow,
  fontBlack,
  layoutBorder,
} from "../../../../../styles/common.css";

// default 1670px 기준
export default css`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  /* justify-content: center; */
  max-width: 1690px;
  min-height: 100vh;
  margin: 0 auto;

  #g_id_onload {
    &.hide {
      display: none;
    }
  }

  h1,
  h2,
  h3 {
    font-weight: 800;
  }
  &.error-page {
    .wrap {
      border: none;
    }
  }

  .notification {
    position: relative;
    filter: grayscale(1);

    &.active-notification {
      filter: grayscale(0);

      &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 2px solid rgba(255, 255, 255, 0.84);
        background: rgb(255, 121, 0);
        box-shadow: rgb(255 121 0 / 60%) 2px 2px 12px;

        @media screen and (max-width: 800px) {
          width: 8px;
          height: 8px;
        }
      }
    }
  }

  &.mobile-layout {
    flex-direction: column;
  }

  &.only-image {
    nav {
      border: none;
    }
  }

  .header-layout {
    position: fixed;
    top: 0;
    width: 100%;
    background: #ffffff;
    display: flex;
    justify-content: center;
    z-index: 10;

    > div {
      position: relative;
      width: calc(100% - 32px);
      padding: 11px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .header-right {
        display: flex;
        align-items: center;
        gap: 20px;

        p {
          font-size: 20px;
          font-weight: 700;
          line-height: 28px;
          color: #222222;
        }
      }
    }
  }

  main {
    width: 100%;
    max-height: 100%;
    max-width: 940px;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-right: ${border};
      /* justify-content: center; */

      > div {
        display: flex;
        /* justify-content: center; */
        flex-direction: column;
        /* align-items: center; */
        width: 100%;
      }
    }
  }

  .content-wrapper {
    padding-bottom: 24vh;
  }
  .right-side-bar {
    &.only-image {
      width: 320px;
    }
    > img {
      position: fixed;
      width: 20vw;
      bottom: 24px;
      right: 24px;
      max-width: 360px;
      /* z-index: -1; */
      animation-duration: 8s;
      animation-name: helloKitty;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
  }

  .login-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-size: 16px;
    font-weight: 800;
    line-height: 1.5em;
    color: ${fontBlack};

    margin-top: 40%;

    a {
      color: ${catYellow};
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 1690px) {
    /*  */
  }

  @media screen and (max-width: 1404px) {
    /*  */
  }

  @media screen and (max-width: 800px) {
    main {
      display: flex;
      justify-content: center;

      > div {
        border: none;
        width: 100%;
      }
    }
    .right-side-bar {
      img {
        opacity: 0.5;
      }
    }
  }

  @keyframes helloKitty {
    0% {
      transform: rotate(6deg) scale(1);
    }
    50% {
      transform: rotate(-6deg) scale(1.1);
    }
    100% {
      transform: rotate(6deg) scale(1);
    }
  }
`;
