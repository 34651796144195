import { css } from "@emotion/react";
import {
  black,
  border,
  fontBlack,
  fontBody,
  hoverBlue,
  boxHover,
  white,
  catYellow,
  catYellowHover,
} from "../../../../styles/common.css";

export default css`
  position: relative;
  [role="select"] {
    min-width: 100px;
    padding: 12px 14px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    background-color: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;

    .placeholder {
      color: #777777;
      font-weight: 500;
    }

    &:hover {
      ${boxHover}
    }
  }

  .select-container {
    .tag-container.isEdit {
      margin: 0 auto;
    }

    div[role="select"] {
      /* padding: 8px 10px; */

      .tag {
        background-color: inherit;
        pointer-events: none;

        span {
          color: ${fontBody};
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }

  .select-list-container {
    display: none;
    position: absolute;
    background-color: ${white};
    width: 100%;
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    max-height: 216px;
    overflow-y: auto;
    z-index: 1;

    &.show {
      display: flex;
    }

    .select-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow-x: hidden;

      li {
        color: ${fontBody};
        width: 100%;
        padding: 14px 20px;
        cursor: pointer;
        &:hover {
          ${boxHover}
        }

        &[aria-selected="true"] {
          color: ${fontBlack};
          background-color: ${catYellowHover};
          font-weight: 800;
        }

        &[aria-selected="true"]::after {
          content: "✓";
          font-size: 12px;
          background-color: ${catYellow};
          color: ${white};
          padding: 2px 4px;
          border-radius: 50%;
          margin-left: 12px;
        }
      }
    }
  }
`;
