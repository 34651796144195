import { useEffect, useRef, useState } from "react";

import style from "./upload.css";
import ProfileImage from "../ProfileImage";

import defaultImg from "../../../assets/images/profileThumb.png";
import backgroundDefault from "../../../assets/images/backgroundDefault.png";
import { useMutation } from "@tanstack/react-query";

import { fileMultipleUpload } from "../../../apis/file.api";
import { UPLOAD_TYPE } from "../../../utils/type";
import ImageEditIcon from "../../../assets/icons/Image.edit.icon";
import useAlert from "../../../hooks/useAlert";
import { ALERT_UPLOAD } from "../Alert/utils/alert.constants";

export default function Upload(data) {
	const { isEdit, icon = null, id = "", url = null } = data;
	const accept = data.accept ?? "image/png, image/jpeg, image/jpg, image/webp, image/gif";
	const className = data.className ?? "profile-image";
	const type = data.type ?? UPLOAD_TYPE.PROFILE;
	const defaultImage =
		data.defaultImage ?? type === UPLOAD_TYPE.BACKGROUND ? backgroundDefault : defaultImg;

	const [query, setQuery] = useState(null);

	const [img, setImg] = useState(defaultImage);
	const [item, setItem] = useState(null);
	const { openAlert } = useAlert();
	const ref = useRef(null);

	const { mutate } = useMutation((data) => fileMultipleUpload(data, query), {
		onSuccess: (result) => {
			const fileUrl = result.data?.thumbnail ?? result.data?.original;

			data?.register.onChange({
				target: { value: fileUrl, name: data.name, id: result.data?.id },
			});

			setImg({ src: fileUrl });
		},
		onError: (error) => {
			openAlert({
				...ALERT_UPLOAD,
				description: error,
			});
		},
	});

	const onChagne = async (e) => {
		try {
			const files = e.target.files;

			if (files.length > 0) mutate(files);
			setItem(e.target.value);
		} catch (e) {
			console.log(e);
		}
	};

	const openInput = () => {
		ref.current.click();
	};

	const handleClick = () => {
		if (isEdit) openInput();
		else {
			if (url) window.open(`${url}?utm_source=sofans.io&utm_medium=referral`, "_blank");
		}
	};

	useEffect(() => {
		if (data.value) setImg({ src: data.value });
	}, [data.value]);

	useEffect(() => {
		setQuery({
			type: data?.uploadType,
			category: type,
		});
	}, [data?.uploadType, type]);

	return (
		<div css={style} className={className} id={id}>
			{data.label && <label htmlFor={data.name}>{data.label}</label>}
			{!data?.hidden && type === UPLOAD_TYPE.PROFILE && (
				<ProfileImage
					width={100}
					height={100}
					img={img.src}
					isAction={true}
					icon={icon}
					onClick={openInput}
					isCircle={data?.isCircle ?? false}
				/>
			)}
			{type === UPLOAD_TYPE.BACKGROUND && (
				<div
					className={`background-image${isEdit ? " pointer" : ""}`}
					data-clickable={!!url}
					style={{ backgroundImage: `url(${img?.src})` }}
					onClick={handleClick}
				/>
			)}
			<input
				ref={ref}
				// className={type === UPLOAD_TYPE.PROFILE ? "" : "hidden"}
				className="hidden upload-input"
				// onBlur={data?.register.onBlur}
				type="file"
				id={data.name}
				name={data.name}
				accept={accept}
				onChange={onChagne}
				multiple={data.multiple}
				value={item}
			/>
			{isEdit && (
				<button
					className="edit-button"
					type="button"
					aria-label="이미지 수정 버튼"
					onClick={openInput}
				>
					<ImageEditIcon />
				</button>
			)}
		</div>
	);
}
