// import HotIcon from "../../assets/icons/Hot.icon";
// import RocketIcon from "../../assets/icons/Rocket.icon";
// import TalkIcon from "../../assets/icons/Talk.icon";
// import CupIcon from "../../assets/icons/Cup.icon";
// import BellIcon from "../../assets/icons/Bell.icon";
// import MoreIcon from "../../assets/icons/More.icon";
import Image from "next/image";

import bgImg from "../../assets/images/plus.png";
import Union from "../../assets/images/Union.png";
import rocket from "../../assets/images/rocket.webp";
import fire from "../../assets/images/fire.webp";
import gemstone from "../../assets/images/gemstone.webp";
import eye from "../../assets/images/512.webp";
import dollar from "../../assets/images/money.webp";
import bell from "../../assets/images/bell.png";

import defaultRocket from "../../assets/images/rocket.png";
import defaultFire from "../../assets/images/fire.png";
import defaultGemstone from "../../assets/images/gemstone.png";
import defaultBell from "../../assets/images/bell.webp";
import DollarIcon from "../../assets/images/dollar.svg";
import defaultEye from "../../assets/images/eye-default.png";

import {
  PATH_ARTICLE,
  PATH_COMMUNITY,
  PATH_PAGE,
  PATH_PRODUCT,
  PATH_USER,
} from "../../paths";

export const CLIENT_MENU = [
  {
    label: "프로덕트",
    icon: <Image src={rocket} width={28} height={28} alt="rocket-icon" />,
    defaultIcon: (
      <Image src={defaultRocket} width={28} height={28} alt="fire-icon" />
    ),
    link: PATH_PRODUCT.root.url,
    name: "product",
  },
  {
    label: "커뮤니티",
    icon: <Image src={gemstone} width={28} height={28} alt="gemstone-icon" />,
    defaultIcon: (
      <Image src={defaultGemstone} width={28} height={28} alt="fire-icon" />
    ),
    link: PATH_COMMUNITY.root.url,
    name: "community",
  },
  {
    label: "HOT 이슈",
    icon: <Image src={fire} width={28} height={28} alt="fire-icon" />,
    defaultIcon: (
      <Image src={defaultFire} width={28} height={28} alt="fire-icon" />
    ),
    link: PATH_ARTICLE.root.url,
    name: "article",
  },
  {
    label: "알림",
    icon: <Image src={bell} width={28} height={28} alt="bell-icon" />,
    defaultIcon: <Image src={bell} width={28} height={28} alt="bell-icon" />,
    type: "notification",
  },
];

export const CLIENT_MOBILE_MENU = [
  {
    label: "프로덕트",
    icon: <Image src={rocket} width={28} height={28} alt="rocket-icon" />,
    defaultIcon: (
      <Image src={defaultRocket} width={28} height={28} alt="fire-icon" />
    ),
    link: PATH_PRODUCT.root.url,
    name: "product",
  },
  {
    label: "커뮤니티",
    icon: <Image src={gemstone} width={28} height={28} alt="gemstone-icon" />,
    defaultIcon: (
      <Image src={defaultGemstone} width={28} height={28} alt="fire-icon" />
    ),
    link: PATH_COMMUNITY.root.url,
    name: "community",
  },
  {
    icon: <Image src={Union} width={15} height={15} alt="plus-icon" />,
    className: "padding-0 plus-icon",
    type: "plus",
  },
  {
    label: "HOT 이슈",
    icon: <Image src={fire} width={28} height={28} alt="fire-icon" />,
    defaultIcon: (
      <Image src={defaultFire} width={28} height={28} alt="fire-icon" />
    ),
    link: PATH_ARTICLE.root.url,
    name: "article",
  },
  {
    label: "내 페이지",
    type: "profile",
    link: PATH_USER.user().url,
    name: "user",
  },
];

export const PRODUCT_NAVIGATION = [
  {
    label: "기본 정보 *",
    icon: <Image src={rocket} width={28} height={28} alt="rocket-icon" />,
    defaultIcon: (
      <Image
        src={defaultRocket}
        width={28}
        height={28}
        alt="default-rocket-icon"
      />
    ),
    link: "",
    name: "product_basic",
    type: "inner",
  },
  {
    label: "상세한 설명",
    icon: <Image src={eye} width={28} height={20.84} alt="eye-icon" />,
    defaultIcon: (
      <Image
        src={defaultEye}
        width={28}
        height={20.84}
        alt="default-eye-icon"
      />
    ),
    link: "",
    name: "product_detail",
    type: "inner",
  },
  {
    label: "레퍼런스",
    icon: <Image src={fire} width={28} height={28} alt="fire-icon" />,
    defaultIcon: (
      <Image src={defaultFire} width={28} height={28} alt="fire-icon" />
    ),
    link: "",
    name: "product_more_information",
    type: "inner",
  },
  {
    label: "프로모션",
    icon: <Image src={dollar} width={28} height={23.48} alt="dollar-icon" />,
    defaultIcon: <DollarIcon />,
    link: "",
    name: "product_promotion",
    type: "inner",
  },
];
