import PopupMenu from "./Components/Menu";
import PopupInput from "./Components/Input";
import PopupEmoji from "./Components/Emoji";

import css from "./popup.css";

export default function Popup({ type, data, left, top, close, width }) {
  const Component = components[type];

  // const isTag = data?.isTag ?? false;

  return (
    <div css={css} id="popup_container" className={type}>
      <div
        style={{
          top,
          left,
        }}
      >
        <Component list={data} close={close} />
      </div>
    </div>
  );
}

const components = {
  menu: PopupMenu,
  input: PopupInput,
  emoji: PopupEmoji,
};
