import { PRODUCT_NAVIGATION } from "../../../../../utils/json/menu.json";

export const activeCurrentNaviItemInProductPage = (e, setActiveNaviItemProduct) => {
	PRODUCT_NAVIGATION.forEach((item) => {
		const targetElement = document.getElementById(item.name);
		const top = targetElement?.getBoundingClientRect().top - 80;

		if (top <= 154 && -targetElement.offsetHeight + 80 <= top) {
			setActiveNaviItemProduct(item.name);
		}

		const scrollY = window.scrollY;
		const viewportHeight = window.innerHeight;
		const documentHeight = document.body.scrollHeight;

		// 스크롤이 문서의 맨 아래에 도달했는지 확인합니다.
		if (scrollY + viewportHeight >= documentHeight) {
			setActiveNaviItemProduct("product_promotion");
		}
	});
};

export const isActiveNavitem = (
	currentRoute,
	item,
	notificationData,
	isProductPage,
	activeNaviItemProduct
) => {
	if (isProductPage) {
		const isActive = activeNaviItemProduct === item.name;
		const activeClass = isActive ? "active" : "";
		const icon = isActive ? item?.icon : item?.defaultIcon;

		return {
			activeClass,
			activeNotification: "",
			icon,
		};
	}

	const isActive = currentRoute === item.link;
	const activeClass = isActive ? "active" : "";
	const activeNotification =
		item.type === "notification" && notificationData?.isActive
			? "active-notification"
			: "";
	const icon = isActive ? item?.icon : item?.defaultIcon ?? item?.icon;

	return {
		activeClass,
		activeNotification,
		icon,
	};
};
