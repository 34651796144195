import { css } from "@emotion/react";
import {
  black,
  catYellow,
  catYellowHover,
  fontBlack,
  fontBody,
} from "../../../../../styles/common.css";

export default css`
  margin-top: 40px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .widget-data-wrapper {
    display: flex;
    flex-direction: column;
  }

  .widget-component-grey {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${fontBody};
    margin-bottom: 12px;
  }

  .subtitle {
    margin-bottom: 20px;
  }

  .widget-component-nickname {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${fontBody};
    margin-left: 5px;
  }

  .widget-component-thumbnail {
    margin-bottom: 20px;

    &.user-profile {
      border-radius: 50%;
    }
  }

  .widget-component-by-user {
    display: flex;
    gap: 20px;
    p {
      span {
        font-size: 14px;
        line-height: 21px;

        &:first-of-type {
          font-weight: 700;
          font-size: 13px;
          line-height: 21px;
          color: ${black};
          margin-right: 4px;
        }

        &:last-of-type {
          font-weight: 500;
          color: #777777;
        }
      }
    }
  }
  p {
    &.widget-component-linedescription {
      font-weight: 700;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #777777;

      width: 320px;
      white-space: normal;
      word-wrap: break-word;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      margin: 20px 0 28px 0;
    }
  }

  .divider {
    margin: 40px 0;
  }

  .widget-component {
    &-thumbnail {
      width: 100px;
      height: 100px;
      border-radius: 6px;
    }
  }

  .widget-component-btns {
    display: flex;
    gap: 4px;

    > div {
      &:not(:last-of-type) {
      }
    }
  }

  .widget-component-btn {
    padding: 0;

    position: relative;

    &.active {
      /* &:hover {
        background-color: ${catYellowHover};
      } */

      div {
        background-color: ${catYellow};
      }
    }

    > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: #000000;
      border-radius: 40px;
      padding: 10px 16px;
      transition: 0.2s ease;

      p {
        color: #ffffff;
        opacity: 0.8;
        line-height: 17px;
        font-weight: 600;
        transition: 0.2s ease;
      }

      &:hover {
        /* transform: scale(1.07); */

        p {
          opacity: 1;
        }
      }
    }
    &-border {
      display: flex;
      align-items: center;
      border: 1px solid #0000001a;
      border-radius: 40px;
      padding: 11.5px 16px;
      background-color: #ffffff;
      color: ${fontBlack};
      opacity: 0.7;
      font-size: 14px;
      line-height: 17px;
      font-weight: 600;

      &:hover {
        color: #222222;
        opacity: 1;
        background: rgba(231, 237, 242, 0.5);
        border: 1px solid rgba(0, 0, 0, 0.1);
        transition: 0.2s ease;
      }
    }

    &.link-btn {
      > div {
        padding: 11.67px;
      }
    }

    img {
      position: relative;
      width: 16.67px;
      height: 16.67px;
    }
  }

  .widget-product-components {
    position: relative;

    > .widget-product-item {
      position: relative;
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      .product_title {
        color: ${fontBlack};
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }

      > div {
        display: flex;
        flex-direction: column;

        &:hover {
          p:first-of-type {
            color: ${catYellow};
            transition: 0.2s ease;
          }
        }

        p {
          &:first-of-type {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
          }

          &:last-of-type {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: ${fontBody};
            width: 260px;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      img {
        width: 48px;
        height: 48px;
        border-radius: 6px;
      }
    }
  }
  @media screen and (max-width: 1212px) {
    .widget-data-wrapper,
    .fans,
    .makers {
      max-width: 280px;
    }
    .widget-data-wrapper h3,
    p.widget-component-linedescription,
    .made-user > div p:last-of-type {
      width: 200px;
    }
    .widget-component-thumbnail {
      width: 80px;
      height: 80px;
    }
    .widget-product-components > .widget-product-item > div p:last-of-type {
      width: 160px;
    }

    .widget-component-by-user p span {
      font-size: 13px;
    }
  }

  @media screen and (max-width: 800px) {
    .widget-data-wrapper,
    .fans,
    .makers {
      min-width: 100%;
    }
    .subtitle {
      margin: 0;
    }

    .fans,
    .makers {
      margin-top: 20px;
    }
    .widget-data-wrapper h3,
    .made-user > div p:last-of-type,
    p.widget-component-linedescription {
      min-width: 100%;
    }

    p.widget-component-linedescription {
      margin: 12px 0;
    }

    .widget-component-btns {
      margin: 12px 0;
      gap: 8px;
      justify-content: flex-end;
    }
  }
`;
