import SOFANS_DEFAULT_IMG from "/public/images/sofans_og_img.jpg";

const DEFAULT_CONFIRM_BUTTON = {
  id: "confirm",
  label: "확인",
};

export const PRODUCT_USE_DELETE_ALERT = {
  title: "해당 활용법을 삭제할까요?",
  buttons: [
    {
      id: "cancel",
      label: "취소",
    },
    {
      id: "remove",
      label: "삭제하기",
    },
  ],
};

export const REVIEW_DELETE_ALERT = {
  title: "리뷰를 삭제할까요?",
  buttons: [
    {
      id: "cancel",
      label: "취소",
    },
    {
      id: "remove",
      label: "삭제하기",
    },
  ],
};

export const ALERT_DEFAULT = {
  title: "이곳에 제목이 나옵니다. ☺️",
  // image: SOFANS_DEFAULT_IMG,
  description:
    "이곳에 설명글이 나옵니다. 이곳에 설명글이 나옵니다. 이곳에 설명글이 나옵니다. 이곳에 설명글이 나옵니다. 이곳에 설명글이 나옵니다.",

  buttons: [
    {
      id: "cancel",
      label: "취소",
    },
    DEFAULT_CONFIRM_BUTTON,
  ],
};

export const ALERT_LOGIN = {
  title: "로그인 알림",
  // image: SOFANS_DEFAULT_IMG,
  description:
    "소팬즈에 로그인하고 자유롭게 이용하세요!\n로그인 페이지로 이동할까요?",
  buttons: [
    {
      id: "cancel",
      label: "취소",
    },
    {
      id: "confirm",
      label: "로그인 하기",
    },
  ],
};

export const ALERT_PRODUCT = {
  title: "프로덕트 알림",
  //   image: SOFANS_DEFAULT_IMG,
  // description: "",
  buttons: [DEFAULT_CONFIRM_BUTTON],
};

export const ALERT_COMMUNITY = {
  title: "커뮤니티 알림",
  //   image: SOFANS_DEFAULT_IMG,
  // description: "",
  buttons: [DEFAULT_CONFIRM_BUTTON],
};

export const ALERT_COMMUNITY_DELETE = {
  ...ALERT_COMMUNITY,
  title: "게시글을 삭제할까요?",
  // description: "게시글을 삭제할까요?",
  buttons: [
    {
      id: "cancel",
      label: "취소",
    },
    {
      id: "confirm",
      label: "삭제하기",
    },
  ],
};

export const ALERT_COMMENT = {
  title: "댓글 알림",
  //   image: SOFANS_DEFAULT_IMG,
  description: "",
  buttons: [DEFAULT_CONFIRM_BUTTON],
};

export const ALERT_COMMENT_DELETE = {
  ...ALERT_COMMENT,
  title: "댓글을 삭제할까요?",
  // description: "댓글을 삭제할까요?",
  buttons: [
    {
      id: "cancel",
      label: "취소",
    },
    {
      id: "confirm",
      label: "삭제하기",
    },
  ],
};

export const ALERT_LINK = {
  title: "링크가 복사되었어요! 👏",
  // image: SOFANS_DEFAULT_IMG,
  // description: "",
  buttons: [DEFAULT_CONFIRM_BUTTON],
};

export const ALERT_TAGS = {
  title: "태그는 최대 5개까지만 등록이 가능해요.",
  // image: SOFANS_DEFAULT_IMG,
  // description: "태그는 최대 5개까지만 등록이 가능해요.",
  buttons: [DEFAULT_CONFIRM_BUTTON],
};

export const ALERT_NOTIFICATION = {
  title: "알림",
  // image: SOFANS_DEFAULT_IMG,
  // description: "",
  buttons: [DEFAULT_CONFIRM_BUTTON],
};

export const ALERT_NOTIFICATION_DELETE = {
  title: "알림을 모두 삭제할까요?",
  buttons: [
    {
      id: "cancel",
      label: "취소",
    },
    {
      id: "confirm",
      label: "삭제하기",
    },
  ],
};

export const ALERT_SIGNUP_COMPLETE = {
  title: "회원가입이 완료되었어요! 🎉",
  //   image: SOFANS_DEFAULT_IMG,
  description: "소팬즈 가입을 환영합니다!",
  buttons: [DEFAULT_CONFIRM_BUTTON],
};

export const ALERT_SIGNUP_ERROR = {
  title: "알림",
  //   image: SOFANS_DEFAULT_IMG,
  // description: "회원가입이 완료되었어요!🎉",
  buttons: [DEFAULT_CONFIRM_BUTTON],
};

export const ALERT_USER = {
  title: "유저 알림",
  //   image: SOFANS_DEFAULT_IMG,
  description: "",
  buttons: [DEFAULT_CONFIRM_BUTTON],
};

export const ALERT_MODAL_DEFAULT = {
  title: "작성 중인 내용이 있을 경우 없어질 수 있어요. 팝업을 닫을까요?",
  buttons: [
    {
      id: "cancel",
      label: "취소",
    },
    {
      id: "confirm",
      label: "닫기",
    },
  ],
};

export const ALERT_UPLOAD = {
  title: "업로드 알림",
  //   image: SOFANS_DEFAULT_IMG,
  // description: "",
  buttons: [DEFAULT_CONFIRM_BUTTON],
};

export const ARTICLE_INPUT_ERROR = {
  // title: "아티클 오류",
  buttons: [DEFAULT_CONFIRM_BUTTON],
};

export const ARTICLE_SUBMIT_COMPLETE = {
  title: "와우! 아티클 제보가 완료되었어요. 🎉",
  buttons: [DEFAULT_CONFIRM_BUTTON],
};

export const REVIEW_BUTTONS = [
  {
    id: "cancel",
    label: "다음에 작성하기",
  },
  {
    id: "confirm",
    label: "제출하기",
  },
];
