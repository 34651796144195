import axios from "axios";
import { BASIC_CONSTANT } from "./basic.constants";
import { ERROR_MESSAGE } from "./json/error.message.json";

const REQUEST_TYPES = {
	GET: "get",
	POST: "post",
	PUT: "put",
	PATCH: "patch",
	DELETE: "delete",
};

export default function axiosWrapper() {
	try {
		return {
			get: request(REQUEST_TYPES.GET),
			post: request(REQUEST_TYPES.POST),
			put: request(REQUEST_TYPES.PUT),
			patch: request(REQUEST_TYPES.PATCH),
			remove: request(REQUEST_TYPES.DELETE),
		};
	} catch (error) {
		throw error;
	}

	function request(method) {
		try {
			return (path, token, body, isMultipart = false) => {
				const url = `${BASIC_CONSTANT.BACKEND_URL}/${path}`;
				const requestOptions = {
					method,
					headers: authHeader(token),
					body: null,
				};

				if (!isMultipart && body) {
					requestOptions.headers["Content-Type"] = "application/json";
					if (body) requestOptions.body = JSON.stringify(body);
				}

				if (isMultipart) {
					// delete body.length;
					const formData = new FormData();
					requestOptions.headers["Content-Type"] = "multipart/form-data";
					for (const item of body) {
						formData.append("files", item);
					}
					body = formData;
				}

				if (method === REQUEST_TYPES.GET || method === REQUEST_TYPES.DELETE) {
					return axios[method](url, requestOptions)
						.then(handleResponse)
						.catch(handleError);
				}

				// POST
				return axios[method](url, body, requestOptions)
					.then(handleResponse)
					.catch(handleError);
			};
		} catch (error) {
			throw error;
		}
	}

	function authHeader(token) {
		const isLoggedIn = !!token;

		if (isLoggedIn) {
			return { Authorization: `Bearer ${token}` };
		} else {
			return {};
		}
	}

	function handleResponse(response) {
		if (response?.data?.statusCode >= 400) {
			throw {
				...response?.data,
				// status: response.status,
				text: response.statusText,
			};
		}

		if (
			(response?.config?.method === REQUEST_TYPES.POST ||
				response?.config?.method === REQUEST_TYPES.DELETE) &&
			process.env.NEXT_PUBLIC_ENV === "production"
		) {
			const reqUrl = response.config.url;
			let type = null,
				targetId = null;

			if (reqUrl === `${BASIC_CONSTANT.BACKEND_URL}/item`) {
				type = "community";
				targetId = response.data?.data?.id;
			}
			if (reqUrl.includes("user/signUp")) {
				type = "user";
				targetId = response.data?.data?.profile.nickname;
			}
			if (reqUrl === `${BASIC_CONSTANT.BACKEND_URL}/product`) {
				type = "product";
				targetId = response.data?.data?.productUniqueId;
			}

			if (type && targetId) {
				axios[response?.config?.method](
					`${BASIC_CONSTANT.SITE_URL}/api/${response?.config?.method}?type=${type}&targetId=${targetId}`
				);
			}
		}

		return response.data;
	}

	function handleErrorMessage(data) {
		const newErrorData = {
			message: ERROR_MESSAGE[data?.statusCode] ?? data?.message,
		};

		throw newErrorData;
	}

	function handleError(error) {
		const { response } = error;

		if (error?.response?.status === 401) {
			localStorage.removeItem(BASIC_CONSTANT.CLIENT_TOKEN);
			// router.push(PATH_AUTH.login.url);
		}

		if (response?.data?.data) throw handleErrorMessage(response?.data?.data);

		throw response?.data ?? response ?? error;
	}
}
