import { css } from "@emotion/react";
import { catYellow, fontBlack, fontBody } from "../../../../styles/common.css";

export const alertCss = ({ open, maxWidth = "360px" }) => css`
  display: ${open ? "flex" : "none"};
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background-color: rgba(210, 210, 210, 0.4);
  justify-content: center;
  align-items: center;
  z-index: 101;

  .alert {
    display: flex;
    flex-direction: column;
    min-width: 360px;
    max-width: ${maxWidth};
    box-shadow: 0px -8px 32px 0px #00000040;
    border-radius: 20px;
    background-color: #ffffff;

    &__contents {
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      &__title {
        font-size: 20px;
        font-weight: 800;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        color: ${fontBlack};
        white-space: pre-wrap;
      }

      &__image {
        padding: 8px 0;
        max-width: 312px;
        width: 100%;

        > span {
          width: 100% !important;
        }

        .alert_image {
          border-radius: 8px;
        }
      }

      &__description {
        color: ${fontBody};
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        white-space: pre-wrap;
      }
    }

    &__buttons {
      padding: 8px 0;
      border-top: 1px solid #00000012;
      display: flex;
      flex-direction: row;
      gap: 2px;

      .alert__button {
        width: 100%;
        padding: 0px 16px;
        height: 40px;

        span {
          font-size: 14px;
          font-weight: 500;
          color: ${fontBody};
        }

        /* confirm button */
        &:last-of-type {
          span {
            font-weight: 700;
            color: ${catYellow};
            opacity: 1;
          }
        }
      }
    }
  }

  /* mobile */
  @media screen and (max-width: 800px) {
    align-items: flex-end;

    .alert {
      min-width: 100%;
      min-height: 200px;
      justify-content: space-between;

      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      &__contents {
        &__image {
          max-width: 100%;
        }
      }
    }
  }
`;
