import Router from "next/router";
import useHandleNotification from "../../hooks/useNotification";
import { PATH_COMMUNITY, PATH_PRODUCT, PATH_USER } from "../../paths";
import { elapsedTime } from "../../utils/common-function";
import ProfileImage from "../common/ProfileImage";
import DefaultProfile from "../../assets/images/profileThumb.png";
import {
  notificationAcitveCss,
  notificationContentCss,
  notificationIconsCss,
  notificationItemWrapperCss,
} from "./notification.css";

// type mention like comment

function ActiveIcon() {
  return <div className="active-icon" css={notificationAcitveCss} />;
}

function Icons({ type, isActive, fromUser, userRoute }) {
  const notificationType =
    type === "like"
      ? "👏"
      : type === "mention"
      ? "@"
      : type === "follow"
      ? "❤️"
      : "✏️";

  return (
    <div className="notification-icons" css={notificationIconsCss}>
      <div className="notification-type">
        {isActive && <ActiveIcon />}
        <p>{notificationType}</p>
      </div>
      <ProfileImage
        isCircle
        width={40}
        height={40}
        img={fromUser?.profile.image ?? DefaultProfile.src}
        isAction
        className="profile-image"
        onClick={() => userRoute(fromUser?.profile.nickname)}
      />
    </div>
  );
}

function Contents({
  type,
  fromUser,
  contentsType,
  comment,
  item,
  product,
  toUserNickname,
  createdAt,
  handleRoute,
}) {
  const routeValue = product
    ? product?.productUniqueId
    : contentsType === "user"
    ? toUserNickname
    : item?.id;

  function getMentionData() {
    if (product) return `@${product.productUniqueId}`;

    return `@${toUserNickname}`;
  }

  function getContentsHandle() {
    const notificationType =
      type === "like"
        ? "를 응원 함"
        : type === "mention"
        ? product
          ? " 멘션 함"
          : " 님을 멘션 함"
        : type === "follow"
        ? "님을 팔로우 함"
        : "에 댓글을 남김";
    return notificationType;
  }

  function getContentValueHandle() {
    if (type === "like") {
      if (contentsType === "item") return item.title;
      if (contentsType === "comment" && comment?.content) {
        return (
          <p
            className="comment-wrapper"
            dangerouslySetInnerHTML={{ __html: comment?.content }}
          />
        );
      }
      if (contentsType === "product") return `@${product.productUniqueId}`;
    }

    if (type === "follow") return `@${toUserNickname}`;

    if (type === "mention") {
      if (contentsType === "item") return `${item.title}`;
      if (contentsType === "comment") {
        if (comment?.content) {
          return (
            <p
              className="comment-wrapper"
              dangerouslySetInnerHTML={{ __html: comment?.content }}
            />
          );
        }
      }
    }
    if (type === "comment") {
      return item.title;
    }
  }

  return (
    <div css={notificationContentCss} className="notification-item">
      <div className="notification-item-contents">
        <span
          className="cat-yellow"
          onClick={() =>
            handleRoute({ type: "user" }, fromUser?.profile.nickname)
          }
        >
          @{fromUser?.profile.nickname}
        </span>
        님이 {"'"}
        <span
          className="cat-yellow"
          onClick={() =>
            handleRoute({ type, contentsType }, routeValue, comment?.id)
          }
        >
          {getContentValueHandle()}
        </span>
        {"'"}
        {type === "mention" && `에서 `}
        {type === "mention" && (
          <span
            className="cat-yellow"
            onClick={() => handleRoute({ type, contentsType }, routeValue)}
          >
            {getMentionData()}
          </span>
        )}
        {getContentsHandle()}
      </div>
      <div className="notification-item-date">{elapsedTime(createdAt)}</div>
    </div>
  );
}

export default function NotificationItem(params) {
  const {
    type = "like",
    isActive = true,
    fromUser,
    contentsType,
    product,
    comment,
    item,
    toUserNickname,
    createdAt,
  } = params;

  const { notificationClose } = useHandleNotification();

  const userRoute = (nickname) => {
    Router.push(PATH_USER.user(nickname).url);
    notificationClose();
  };

  const handleRoute = ({ type, contentsType }, value, commentId) => {
    if (type === "user") {
      Router.push(PATH_USER.user(value).url);
    }

    if (type === "mention" || type === "comment") {
      let url = PATH_COMMUNITY.detail(value).url;
      if (product) url = PATH_PRODUCT.detail(value).url;
      if (commentId) url += `?commentId=${commentId}`;

      Router.push(url);
    }

    if (type === "like") {
      let url = PATH_PRODUCT.detail(value).url;
      if (contentsType === "item") {
        url = PATH_COMMUNITY.detail(value).url;
      }
      if (contentsType === "user") {
        url = PATH_USER.user(value).url;
      }

      if (contentsType === "comment") {
        url = PATH_COMMUNITY.detail(value).url;
      }

      Router.push(url);
    }

    notificationClose();
  };

  return (
    <li
      className={`notification-item-wrapper ${isActive ? "" : "read"}`}
      css={notificationItemWrapperCss}
    >
      <Icons
        type={type}
        isActive={isActive}
        fromUser={fromUser}
        userRoute={userRoute}
      />
      <Contents
        type={type}
        fromUser={fromUser}
        contentsType={contentsType}
        product={product}
        item={item}
        comment={comment}
        toUserNickname={toUserNickname}
        createdAt={createdAt}
        userRoute={userRoute}
        handleRoute={handleRoute}
      />
    </li>
  );
}
