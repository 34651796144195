import { css } from "@emotion/react";
import {
  BG_gray_02,
  layoutBorder,
  fontBlack,
  fontBody,
  blur_bg,
  catYellowHover,
} from "../../../../styles/common.css";

export default css`
  position: relative;
  background-color: #ffffff;
  max-height: 100%;
  height: 100%;
  width: 940px;
  right: 4%;
  display: flex;
  justify-content: center;
  overflow-y: auto;

  &.modal-gallery {
    width: 100%;

    .modal-contents {
      height: calc(100vh - 80px);

      .slide-arrow-wrapper {
        bottom: 45%;
        transform: -45%;
      }

      .swiper {
        height: 100%;
        .swiper-slide {
          cursor: default;

          .gallery-item-image {
            height: 100%;
            background-size: contain;
          }
        }
      }
    }
  }

  .modal {
    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .cancel-btn {
        padding: 0;
        margin: 0;
        z-index: 10;
        position: sticky;
        top: 0;
        width: 100%;
        display: block;
        height: 0;
        text-align: right;
      }
    }
    &-title {
      width: 100%;
      font-size: 32px;
      font-weight: 800;
      line-height: 48px;
      margin-bottom: 19px;
      white-space: pre;
      cursor: default;
    }

    &-contents {
      font-size: 16px;
      line-height: 1.5;
      font-weight: 500;
      width: 100%;
      color: ${fontBody};
      /* padding-bottom: 10vh; */
      display: flex;
      justify-content: center;

      &-wrap {
        margin: 40px 8 80px;
      }
    }

    &-buttons {
      position: sticky;
      margin-left: 5px;
      padding-top: 40px;
      gap: 8px;

      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .comment-btn {
        padding: 6px 8px;
      }

      button {
        padding: 0;
        span,
        p {
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
        }

        > div {
          display: flex;
          align-items: center;

          @media screen and (max-width: 800px) {
            width: 26px;
          }

          span,
          svg {
            margin-right: 8px;
          }
        }
      }

      .circle-icon {
        width: 40px;
        height: 40px;

        @media screen and (max-width: 800px) {
          width: 25px;
          height: 25px;
        }
      }

      .modal-vertical-btn {
        ${layoutBorder};
        border-radius: 30px;

        &.action-btn {
          button {
            padding: 0;
            background-color: transparent;
          }
        }

        &.like-btn {
          padding: 10px 16px;

          .like-icon {
            font-size: 22px;
          }

          &.active {
            &:hover {
              background-color: ${catYellowHover};
            }
            button {
              background-color: transparent;
            }
          }
        }

        button {
          background-color: transparent;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.06);
          transition: 0.2s ease;
        }
      }
    }

    &-home-description {
      background-color: ${BG_gray_02};
      padding: 32px;
      display: flex;
      gap: 12px;
      margin-top: 40px;
      flex-direction: column;
      ${layoutBorder}
      p {
        font-size: 14px;
      }
    }
  }
  /* ::-webkit-scrollbar { 
    display: none;
    -ms-overflow-style: none;
  }*/

  /* 커뮤니티 게시글 조회 모달 */
  &.modal-community {
    .modal-container-wrap {
      width: calc(100% - 260px);

      .modal-contents {
        padding-bottom: 10vh;
      }
    }
  }

  .modal-container-wrap {
    padding: 40px 0;
    box-sizing: border-box;
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    width: calc(100% - 134px);
  }

  .confirm-btn {
    width: fill-available;
    margin-top: 40px;
    margin-bottom: 16vh;
  }

  /* 게시글 작성 모달 */
  &.create-community-modal {
    .modal-title {
      font-size: 20px;
      line-height: 24px;
    }
    .modal-wrapper {
      width: unset;
    }

    .tag-container-list {
      padding-bottom: 10px;
    }
  }

  .community-body {
    cursor: default;

    h2 {
      -webkit-line-clamp: 10;
    }
  }

  @media screen and (max-width: 1074px) {
    min-width: 100%;
    right: 0;

    .modal-wrapper {
      margin: 40px 40px;
    }
    .modal-buttons {
      top: 40px;
    }
  }

  @media screen and (max-width: 800px) {
    &.notification-modal {
      .modal-wrapper {
        padding-top: 0;

        .notification-header {
          .bolder {
            padding: 0 6px;
            p {
              display: none;
            }
          }
        }
      }
    }

    .modal-wrapper {
      width: 90%;
      margin: 0;
      padding-top: 0;

      .cancel-btn {
        left: 20px;
        border: none;
        display: block;
        backdrop-filter: unset;
        background-color: transparent;
      }

      .modal-contents {
        /*  */
      }
      .confirm-btn {
        width: 90%;
      }
    }

    .modal-container-wrap {
      width: 100% !important;
      margin: 0;
    }

    /* 게시글 조회 모달 */
    .modal {
      /* 상단 버튼 */
      &-buttons {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0;

        width: 90%;
        height: 55px;
        padding: 0 20px;

        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        background: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(16px);

        .modal-vertical-btn {
          border: none;
          padding: 0;

          &.like-btn {
            padding: 0;

            &.active {
              &:hover {
                background-color: transparent;
              }
            }
          }

          button > div span {
            display: none;
          }

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
    &.modal-community {
      .modal-wrapper {
        width: 100%;
        .community-contents {
          .create-comment {
            position: sticky;
            bottom: 0px;

            border-top: 1px solid rgba(0, 0, 0, 0.1);

            padding: 10px 0;
            background: rgb(255, 255, 255);
          }
        }
      }
    }

    /* 게시글 작성 모달 */
    &.create-community-modal {
      width: 100%;

      &.modal-community {
        .modal-container-wrap {
          width: 90% !important;

          .modal-wrapper {
            width: 100%;
            padding-top: 40px;

            .modal-contents {
              .bottom {
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }
`;
