import { useEffect, useState } from "react";
import Router, { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import validationUrl from "is-url";
import { postArticle } from "../../../../apis/article.api";
import useModal from "../../../../hooks/useModal";
import { PATH_COMMUNITY, PATH_PAGE, PATH_PRODUCT, PATH_USER } from "../../../../paths";
import { defaultDataStore } from "../../../../recoils/defaultData";

import { userInfo } from "../../../../recoils/user";
import { widgetDataTypeStore } from "../../../../recoils/widgetDataType";
import { BASIC_CONSTANT } from "../../../../utils/basic.constants";
import { FORM_TYPE } from "../../../../utils/constants";

import {
	CLIENT_MENU,
	CLIENT_MOBILE_MENU,
	PRODUCT_NAVIGATION,
} from "../../../../utils/json/menu.json";
import ModalHome from "../../../Home/Modal.Home";
import CreateCommunityModal from "../../Community/CreateCommunityModal";
// import HorizentalNavigation from "./Horizental";
// import VerticalNavigation from "./Vertical";
const HorizentalNavigation = dynamic(() => import("./Horizental"), { ssr: false });
const VerticalNavigation = dynamic(() => import("./Vertical"), { ssr: false });

import CancelIcon from "../../../../assets/icons/Cancel.icon";
import usePopup from "../../../../hooks/usePopup";
import { USER_LOGINNED } from "../../Popup/popup.json";
import { POPUP_CONSTANTS } from "../../Popup/constants";
import { useHandleLoginConfirm } from "../../../../utils/login.function";
import useAlert from "../../../../hooks/useAlert";
import {
	ARTICLE_INPUT_ERROR,
	ARTICLE_SUBMIT_COMPLETE,
	ALERT_LOGIN,
} from "../../Alert/utils/alert.constants";
import { useQueryClient } from "@tanstack/react-query";
import { refetchStore } from "../../../../recoils/refetchRecoil";
import {
	convertProductTagValues,
	convertTagValues,
	currentPageHandle,
} from "../../../../utils/common-function";
import { WIDGET_CONSTANTS_PAGENAME } from "../../Widget/widget.constants";
import { activeCurrentNaviItemInProductPage } from "./utils/nav.common.function";

export const NAVIGATION_TYPE = {
	VERTICAL: "vertical",
	HORIZONTAL: "horizontal",
};

export default function Navigations({
	variant = NAVIGATION_TYPE.VERTICAL,
	hideMenu,
	notificationOpen,
	notificationData,
}) {
	const [user, setUser] = useRecoilState(userInfo);
	const router = useRouter();
	const { openModal, closeModal, modalData } = useModal();
	const { openPopup } = usePopup();
	const defaultData = useRecoilValue(defaultDataStore);
	const setRefetch = useSetRecoilState(refetchStore);
	const loginConfirm = useHandleLoginConfirm();
	const { openAlert } = useAlert();
	const queryClient = useQueryClient();

	const [widgetDataType, setWidgetDataType] = useRecoilState(widgetDataTypeStore);

	// 카카오톡 브라우저 확인
	const [isKakaoBrowser, setKakaoBrower] = useState(false);
	const currentPage = currentPageHandle(router.pathname, true);
	const [activeNaviItemProduct, setActiveNaviItemProduct] = useState("product_basic");

	const isNotReadType = widgetDataType !== FORM_TYPE.GET;
	const isEditProductPage =
		currentPage === WIDGET_CONSTANTS_PAGENAME.PRODCUT && isNotReadType;
	const NAVIGATION_DATA = isEditProductPage ? PRODUCT_NAVIGATION : CLIENT_MENU;
	const NAVIGATION_MOBILE_DATA = isEditProductPage ? [] : CLIENT_MOBILE_MENU;

	useEffect(() => {
		const isKakao = navigator.userAgent.match("KAKAOTALK");
		setKakaoBrower(Boolean(isKakao));
	}, []);

	useEffect(() => {
		if (isEditProductPage) {
			window.addEventListener("scroll", (e) =>
				activeCurrentNaviItemInProductPage(e, setActiveNaviItemProduct)
			);
		} else {
			window.removeEventListener("scroll", (e) =>
				activeCurrentNaviItemInProductPage(e, setActiveNaviItemProduct)
			);
		}

		return () => {
			window.removeEventListener("scroll", (e) =>
				activeCurrentNaviItemInProductPage(e, setActiveNaviItemProduct)
			);
		};
	}, [isEditProductPage]);

	// OS 종류가 Android 환경인지 확인
	const isAndroid = () => {
		const ua = navigator.userAgent.toLowerCase();
		return ua.indexOf("android") > -1;
	};

	// OS 종류가 iOS 환경인지 확인
	const isIos = () => {
		const ua = navigator.userAgent.toLowerCase();
		return ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
	};

	const handleDropdown = (e) => {
		if (!!user?.id) {
			const NEW_USER_LOGINNED = USER_LOGINNED.map((item, idx) => {
				if (idx === 2) {
					const data = {
						...item,
						url: PATH_USER.user(user?.profile?.nickname).url + "?tab=0",
					};
					return data;
				}
				return item;
			});

			openPopup(POPUP_CONSTANTS.MENU, NEW_USER_LOGINNED, e);
		} else {
			// isAndroid가 true이면서 isKakaoBrowser가 true일 때, intent://로 시작하는 url로 이동
			if (isAndroid() && isKakaoBrowser) {
				openAlert({
					...ALERT_LOGIN,
					description:
						"카카오톡 브라우저에서는 Google로그인이 지원되지 않아요.\n다른 브라우저로 접속을 도와드릴게요.",
					events: [
						{
							id: "confirm",
							onClick: () => {
								window.location.href =
									"intent://sofans.io#Intent;scheme=https;package=com.android.chrome;end";
							},
						},
					],
				});
			}
			// isIOS가 true이면서 isKakaoBrowser가 true일 때, alert창을 띄워준다.
			else if (isIos() && isKakaoBrowser) {
				openAlert({
					...ALERT_LOGIN,
					description: `iOS의 카카오톡 브라우저에서는 Google로그인이 지원되지 않아요.😿\n기본 브라우저를 통해 접속을 부탁드립니다.`,
					buttons: [
						{
							id: "confirm",
							label: "확인",
						},
					],
				});
			}
			// 그 외의 모든 브라우저는 backend url로 이동
			else {
				window.location.href = `${BASIC_CONSTANT.BACKEND_URL}/auth/google`;
			}
		}
	};

	const submitNewArticle = async () => {
		let id = user?.id ? user?.id : "public";
		const data = { userId: id };
		const modalComponent = ["url", "myArticle", "categoryId_tags", "tags", "products"];

		try {
			for (let key of modalComponent) {
				const element = document.getElementById(key);

				if (element) {
					let value = key === "myArticle" ? element?.checked : element?.value;

					if (key === "tags") value = convertTagValues(element);
					if (key === "products") value = convertProductTagValues(element);
					if (key === "myArticle" && value === true) {
						if (id === "public") {
							loginConfirm();
							break;
						} else data.userId = id;
					} else {
						if (key === "categoryId_tags") {
							const value = element?.querySelector(".tag").getAttribute("value");

							const item = defaultData.categories.find((item) => item.value === value);
							data["categoryId"] = item.id;
						} else {
							data[key] = value;
						}
					}
				}
			}

			if (!data.url && !data.categoryId) {
				openAlert({
					...ARTICLE_INPUT_ERROR,
					title: `입력되지 않은 내용이 있는 것 같아요. 내용을 입력해주세요.`,
				});
				return;
			}
			if (data.url && !data.categoryId) {
				openAlert({
					...ARTICLE_INPUT_ERROR,
					title: "카테고리를 선택해주세요.",
				});
				return;
			}
			if (!data.url && data.categoryId) {
				openAlert({
					...ARTICLE_INPUT_ERROR,
					title: "아티클 URL을 입력해주세요.",
				});
				return;
			}

			if (data?.url && data?.categoryId) {
				if (!validationUrl(data.url)) {
					openAlert({
						...ARTICLE_INPUT_ERROR,
						title: "URL 형식을 다시 한번 확인해주세요.",
					});
					return;
				}
				const result = await postArticle(data);

				if (result) {
					const events = [
						{
							id: "confirm",
							onClick: () => {
								setRefetch(true);
								closeModal();
								return queryClient.invalidateQueries({
									queryKey: "article/list/init",
								});
							},
						},
					];

					openAlert({
						...ARTICLE_SUBMIT_COMPLETE,
						events,
					});
				}
			}
		} catch (error) {
			openAlert({
				...ARTICLE_INPUT_ERROR,
				title:
					"아티클 URL이 저희가 가져올 수 없는 URL인 것 같아요. 😢 다른 URL을 입력해주세요.",
			});

			// console.log(error);
		}
	};

	const handleClick = () => {
		if (router.pathname === PATH_PAGE.root.url) {
			if (modalData?.isOpen) {
				closeModal();

				return;
			} else {
				const modalData = {
					title: "모두에게 소개하고 싶은\n멋진 글을 알고계신가요?",
					contents: <ModalHome categories={defaultData?.categories} />,
					confirmText: "❤️ 모두에게 글 소개하기",
					cancelText: <CancelIcon />,
					confirmClassName: "black-btn",
					isConfirmModal: true,
					onConfirm: submitNewArticle,
				};
				openModal(modalData);
				Router.push(PATH_PAGE.root.url, `${PATH_PAGE.root.url}?type=article`, {
					shallow: true,
				});
				return;
			}
		}

		if (isNotReadType) {
			const btn = document.getElementById("hook-form-btn");

			btn.click();
			return;
		}

		// if (router.pathname.includes("/user")) {
		//   if (!user?.token) {
		//     alert("소팬즈에 로그인하고 자유롭게 이용하세요! 😻");
		//     return;
		//   }
		//   setWidgetDataType(FORM_TYPE.UPDATE);
		//   return;
		// }

		if (router.pathname.includes(PATH_PRODUCT.root.url)) {
			if (!user?.token) {
				loginConfirm();
				return;
			}
			router.push(PATH_PRODUCT.create.url);
			return;
		}

		if (
			router.pathname.includes(PATH_COMMUNITY.root.url) ||
			router.pathname.includes("/user")
		) {
			if (!user?.token) {
				loginConfirm();
				return;
			}

			const modalData = {
				title: "게시글 작성하기",
				contents: <CreateCommunityModal data={null} />,
				className: "create-community-modal modal-community",
				isNoButtons: true,
				isConfirmModal: true,
			};
			openModal(modalData);
			Router.push(router.asPath, `${router.asPath}?type=comm-create`, {
				shallow: true,
			});
		}
	};

	const route = (path, type, e, name) => {
		if (!type) {
			Router.push(path);
			return;
		}
		if (type === "notification") {
			if (!user) {
				loginConfirm();
				return;
			}

			notificationOpen();
		}
		if (type === "profile") {
			handleDropdown(e);
		}

		if (type === "plus") handleClick();
		if (type === "inner") {
			const targetElement = document.getElementById(name);

			window.scrollTo({
				top: targetElement.offsetTop - 80,
				behavior: "smooth",
			});
		}
	};

	if (variant === NAVIGATION_TYPE.HORIZONTAL) {
		return (
			<>
				<HorizentalNavigation
					data={NAVIGATION_MOBILE_DATA}
					hideMenu={hideMenu}
					route={route}
					user={user}
					setUser={setUser}
					notificationData={notificationData}
					activeNaviItemProduct={activeNaviItemProduct}
					isProductPage={isEditProductPage}
				/>
				{!user && (
					// google one tap login
					<div
						id="g_id_onload"
						data-client_id="740537245051-sgcn0lrnniq49t9v2361086pq3m4i57g.apps.googleusercontent.com"
						data-context="signin"
						data-itp_support="true"
						data-login_uri={`${BASIC_CONSTANT.BACKEND_URL}/auth/google`}
					/>
				)}
			</>
		);
	}

	return (
		<>
			<VerticalNavigation
				data={NAVIGATION_DATA}
				route={route}
				hideMenu={hideMenu}
				notificationData={notificationData}
				user={user}
				setUser={setUser}
				handleDropdown={handleDropdown}
				activeNaviItemProduct={activeNaviItemProduct}
				isProductPage={isEditProductPage}
			/>
			{!user && (
				// google one tap login
				<div
					id="g_id_onload"
					className={!!user ? "hide" : ""}
					data-client_id="740537245051-sgcn0lrnniq49t9v2361086pq3m4i57g.apps.googleusercontent.com"
					data-context="signin"
					data-itp_support="true"
					data-login_uri={`${BASIC_CONSTANT.BACKEND_URL}/auth/google`}
				/>
			)}
		</>
	);
}
