import axiosWrapper from "../utils/axios-wrapper";

const { post, get } = axiosWrapper();
export const createTag = async (data) => {
  try {
    const response = await post("tag", null, data);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getListTag = async (query) => {
  const response = await get(`tag?name=${query}`);
  return response;
};
