import { useRecoilState } from "recoil";
import { defaultDataStore } from "../recoils/defaultData";

export default function useDefaultData() {
  const [defaultDataState, updateState] = useRecoilState(defaultDataStore);

  const setDefaultData = (key, data) => {
    updateState({
      ...defaultDataState,
      [key]: data,
    });
  };

  return { defaultDataState, setDefaultData };
}
