import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { GALLERY_TYPES } from "../../../utils/basic.constants";
import { GalleryComponentsCss } from "./imageGallery.css";
import ArrowIcon from "../../../assets/icons/Arrow.icon";
import LabelComponent from "../LabelComponent";
import Text from "../Text";
import CancelIcon from "../../../assets/icons/Cancel.icon";
import useModal from "../../../hooks/useModal";
import { fontBlack } from "../../../../styles/common.css";
import { useRecoilValue } from "recoil";
import { isMobileStore } from "../../../recoils/isMobile";

SwiperCore.use([Navigation, Pagination]);

function ImageGalleryComponent({
  label,
  name,
  type,
  data = [],
  gallery_type = GALLERY_TYPES.THUMBNAIL,
  isEdit = false,
  onRemove,
  required,
  slidesPerView = 2.5,
  spaceBetween = 12,
}) {
  const { openModal } = useModal();
  const [images, setImages] = useState([]);
  const [perView, setPerView] = useState(2.5);

  const isMobileValue = useRecoilValue(isMobileStore);

  const swiperRef = useRef(null);

  useEffect(() => {
    if (slidesPerView) setPerView(slidesPerView);
  }, [slidesPerView]);

  useEffect(() => {
    if (slidesPerView !== 1 && isMobileValue) {
      setPerView(1.3);
    } else {
      setPerView(slidesPerView);
    }
  }, [isMobileValue]);

  const convertData = (data) => {
    if (Array.isArray(data)) {
      if (gallery_type === GALLERY_TYPES.THUMBNAIL) {
        const thumbnails = data?.map(({ id, thumbnail }) => {
          return { id, src: thumbnail };
        });
        setImages(thumbnails);
      }

      if (gallery_type === GALLERY_TYPES.ORIGINAL) {
        const originals = data?.map(({ id, original }) => {
          return { id, src: original };
        });
        setImages(originals);
      }
    }
  };

  const removeData = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    setImages((prev) => prev.filter((image) => image.id !== id));
    onRemove(id);
    // remove data api
  };

  const handleOpenModal = (e) => {
    if (gallery_type === GALLERY_TYPES.THUMBNAIL) {
      e.preventDefault();
      e.stopPropagation();

      const modalData = {
        contents: (
          <ImageGalleryComponent
            data={data}
            gallery_type={GALLERY_TYPES.ORIGINAL}
            slidesPerView={1}
            spaceBetween={0}
          />
        ),
        className: "modal-gallery",
        showConfirmBtn: false,
        cancelText: <CancelIcon />,
      };

      openModal(modalData, "gallery");
    }
  };

  useEffect(() => {
    if (data.length > 0) convertData(data);
  }, [type, data]);

  return (
    <section css={GalleryComponentsCss}>
      {label && (
        <LabelComponent label={label} name={name} required={required} />
      )}

      {images?.length > 0 ? (
        <>
          <Swiper
            className="swiper"
            // modules={[Navigation, Pagination]}
            navigation
            spaceBetween={spaceBetween}
            slidesPerView={perView}
            pagination={{ type: "fraction" }}
            ref={swiperRef}
            style={{
              "--swiper-navigation-color": fontBlack,
            }}
          >
            {images.map((item, idx) => {
              return (
                item?.src && (
                  <SwiperSlide
                    key={`swiper_${idx}`}
                    className="gallery-item"
                    onClick={handleOpenModal}
                  >
                    {item?.src && (
                      <div
                        className="gallery-item-image"
                        style={{ backgroundImage: `url(${item?.src})` }}
                      />
                    )}
                    {item?.src && isEdit && (
                      <button
                        aria-label="이미지 삭제 버튼"
                        onClick={(e) => removeData(e, item?.id)}
                      >
                        <CancelIcon size="medium" />
                      </button>
                    )}
                  </SwiperSlide>
                )
              );
            })}
          </Swiper>
        </>
      ) : isEdit ? null : (
        <Text />
      )}
    </section>
  );
}

export default ImageGalleryComponent;
