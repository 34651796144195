import dynamic from "next/dynamic";
import { memo, useEffect, useId, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { useMutation } from "@tanstack/react-query";

import Iframe from "../../Iframe";
import { createItem, modifyItem } from "../../../../apis/item.api";

import useModal from "../../../../hooks/useModal";
import usePopup from "../../../../hooks/usePopup";
import ButtonIcon from "../../Button/Button.Icon";
import PictureIcon from "../../../../assets/icons/Picture.icon";
import VideoIcon from "../../../../assets/icons/Video.icon";
import EmojiIcon from "../../../../assets/icons/Emoji.icon";
import MentionIcon from "../../../../assets/icons/Mention.icon";
import HashTagIcon from "../../../../assets/icons/HashTag.icon";
import { userInfo } from "../../../../recoils/user";

import { POPUP_CONSTANTS } from "../../Popup/constants";
import { TAG_POPUP, VIDEO_POPUP } from "../../Popup/popup.json";
import Upload from "../../Upload";
import { UPLOAD_TYPE } from "../../../../utils/type";
import Grid from "../../Grid";
import Tags from "../../Tags";
import { postOGS } from "../../../../apis/item.api";
import css from "./createCommunityModal.css";
import EmbededWeb from "../../EmbededWeb";
import { handleMentionData } from "../../CKEditor/editor.utils";
import SelectTags from "../../SelectTags";
import Router from "next/router";
import { PATH_COMMUNITY, PATH_PRODUCT } from "../../../../paths";
import useAlert from "../../../../hooks/useAlert";
import {
  ALERT_COMMUNITY,
  ALERT_MODAL_DEFAULT,
} from "../../Alert/utils/alert.constants";
const Editor = dynamic(() => import("../../CKEditor"), { ssr: false });

function CreateCommunityModal({
  data,
  productId = null,
  productUniqueId = null,
  onCancel,
  onConfirm,
}) {
  const randomId = useId();
  const user = useRecoilValue(userInfo);
  const [editorData, setEditorData] = useState("");
  const ref = useRef();
  const [video, setVideo] = useState(null);
  const [imgs, setImgs] = useState([]);
  const [tags, setTags] = useState([]);
  const [metaData, setMetaData] = useState(null);
  const [url, setUrl] = useState();
  const [width, setWidth] = useState(0);

  const { openAlert } = useAlert();

  const { closeModal } = useModal();
  const { openPopup } = usePopup();

  const { mutate } = useMutation((data) => createItem(user?.token, data), {
    onSuccess: async () => {
      const events = [
        {
          id: "confirm",
          onClick: async () => {
            closeModal();

            if (
              Router.pathname?.includes("/community") ||
              Router.pathname?.includes("/user")
            ) {
              const routing = await Router.push(
                PATH_COMMUNITY.root.url + "?submit=true"
              );
              if (routing) Router.reload();
              return;
            }

            if (Router.pathname?.includes("/product")) {
              const routing = await Router.replace(
                PATH_PRODUCT.detail(productUniqueId).url + "?tab=1"
              );
              if (routing) Router.reload();
              return;
            }
          },
        },
      ];

      openAlert({
        ...ALERT_COMMUNITY,
        description: "게시글이 등록되었어요! 👏",
        events,
      });
    },
  });

  const { mutate: modifyMutate } = useMutation(
    (modifyData) => modifyItem(user?.token, data.id, modifyData),
    {
      onSuccess: (data) => {
        const events = [
          {
            id: "confirm",
            onClick: () => {
              closeModal();
              if (onConfirm) onConfirm();
            },
          },
        ];
        openAlert({
          ...ALERT_COMMUNITY,
          title: "게시글이 수정되었어요.",
          events,
        });
      },
    }
  );

  const handleItem = () => {
    const title = editorData.slice(4, editorData.search(/<\/h1>/g));
    const content = editorData.slice(
      editorData.search(/<\/h1>/g) + 5,
      editorData.length
    );

    if (!title || title === "&nbsp;") {
      openAlert({
        ...ALERT_COMMUNITY,
        title: "제목을 입력해주세요.",
      });
      return false;
    }

    const { products, users } = handleMentionData(randomId);

    if (productId) {
      products.push({ id: productId });
    }

    const data = {
      title,
      content,
      youtubeUrl: video ?? null,
      ...metaData,
      createdUser: user,
      file: imgs,
      products: products.length > 0 ? products : null,
      users: users.length > 0 ? users : null,
      tags: tags.length > 0 ? tags : null,
    };

    return data;
  };

  const handleClick = () => {
    const itemData = handleItem();

    if (itemData) {
      if (data) modifyMutate(itemData);
      else mutate(itemData);
    }
  };

  const handleOpenPopup = (type, data, e) => {
    openPopup(type, data, e);
  };

  const onChange = ({ target }) => {
    setImgs((prev) => {
      return [...prev, { id: target.id, url: target.value }];
    });
  };

  const onClickPicture = () => {
    const communityDom = document.getElementById(randomId);
    const dom = communityDom.querySelector(".upload-input");
    dom.click();
  };

  const remove = (e, id) => {
    e.stopPropagation();
    setImgs((prev) => {
      return prev.filter((item) => item.id !== id);
    });
  };

  const removeTags = (id) => {
    setTags((prev) => {
      return prev.filter((item) => item.id !== id);
    });
  };

  const handleOGS = async (data) => {
    const result = await postOGS(user?.token, { url: data });

    setMetaData({
      webTitle: result.title,
      webDescription: result.description,
      webImage: result?.image,
      webUrl: result.url,
      webDomain: result.domain,
    });
  };

  const handleTags = ({ target }) => {
    const { value } = target;
    setTags(value);
  };

  const handleCloseModal = () => {
    openAlert({
      ...ALERT_MODAL_DEFAULT,
      events: [
        {
          id: "confirm",
          onClick: () => {
            closeModal();
            if (onCancel) onCancel();
          },
        },
      ],
    });
  };

  useEffect(() => {
    const dom = document.getElementsByTagName("main");
    setWidth(dom[0].offsetWidth - 119 * 2);
  }, []);

  useEffect(() => {
    const data = editorData.match(/((href=")(.*))\"\>h/);

    if (data?.length > 0 && (url === undefined || url === null)) {
      const urlValue = data[0].split("=")[1].split(">")[0].slice(1, -1);

      setUrl(urlValue);
    }

    if (data === null && url) {
      setUrl(null);
    }
  }, [editorData]);

  useEffect(() => {
    if (url) handleOGS(url);
    if (url === null) setMetaData(null);
  }, [url]);

  useEffect(() => {
    if (data) {
      setEditorData(data.content);
      if (data.file?.length > 0) setImgs(data.file);
      if (data.tags?.length > 0) setTags(data.tags);
      if (data.youtubeUrl) setVideo(data.youtubeUrl);
      if (data.webUrl) {
        setUrl(data.webUrl);
        setMetaData({
          webTitle: data.webTitle,
          webDescription: data.webDescription,
          webImage: data.webImage,
          webUrl: data.webUrl,
          webDomain: data.webDomain,
        });
      }
    }
  }, [data]);

  return (
    <div className="community-edit-wrapper" css={css} id={randomId}>
      <Editor
        token={user?.token}
        value={editorData}
        setData={setEditorData}
        forwardedRef={ref}
      />
      {/* Contents Data */}
      <SelectTags
        label="관련 주제"
        value={tags}
        description="게시글의 관련 주제를 추가해주세요."
        placeholder="새로운 태그를 입력 후 엔터를 치거나, 추가할 태그를 선택해주세요."
        onChange={handleTags}
        removeTag={removeTags}
      />
      {video && <Iframe value={video} />}
      {imgs?.length > 0 && (
        <Grid data={imgs} remove={remove} readonly={false} />
      )}

      {metaData && <EmbededWeb {...metaData} />}
      {/* Contents Data  */}
      <Upload
        hidden={true}
        type={UPLOAD_TYPE.DETAIL}
        uploadType="product"
        register={{ onChange }}
      />
      <div
        className="bottom"
        style={{
          width: `${width}px`,
        }}
      >
        <div>
          <div className="editor-action-buttons">
            <ButtonIcon
              className="btn"
              ariaLabel={"이미지 첨부 버튼"}
              icon={<PictureIcon />}
              onClick={onClickPicture}
            />
            <ButtonIcon
              className="btn"
              ariaLabel={"유튜브 링크 첨부 버튼"}
              icon={<VideoIcon />}
              onClick={(e) =>
                handleOpenPopup(
                  POPUP_CONSTANTS.INPUT,
                  {
                    ...VIDEO_POPUP,
                    value: video,
                    onClick: (value) => setVideo(value),
                  },
                  e
                )
              }
            />
            <ButtonIcon
              className="btn emoji"
              ariaLabel={"이모지 추가 버튼"}
              icon={<EmojiIcon />}
              onClick={(e) =>
                handleOpenPopup(
                  POPUP_CONSTANTS.EMOJI,
                  {
                    onClick: (emojiData) => {
                      ref.current.addEmoji(emojiData.emoji);
                    },
                  },
                  e
                )
              }
            />
            {/* <ButtonIcon
              className="btn"
              icon={<MentionIcon />}
              onClick={(e) => ref.current.addMention()}
            />
            <ButtonIcon
              className="btn"
              icon={<HashTagIcon />}
              onClick={(e) =>
                handleOpenPopup(
                  POPUP_CONSTANTS.INPUT,
                  {
                    ...TAG_POPUP,
                    onClick: (tags) => setTags(tags),
                  },
                  e
                )
              }
            /> */}
          </div>
          <div className="buttons">
            <ButtonIcon
              className="cancel"
              icon="취소"
              ariaLabel={"게시글 작성 취소 버튼"}
              onClick={handleCloseModal}
            />
            <ButtonIcon
              className="black-btn"
              icon={"🚀 게시하기"}
              ariaLabel={"게시글 제출 버튼"}
              onClick={handleClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(CreateCommunityModal);
