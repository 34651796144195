import React from "react";
import { buttonIconStyle } from "./button.css";

export default function ButtonComponent(data) {
  const type = data.type ?? "button";
  const isActive = data.isActive ? "active" : "";

  const onCick = (e) => {
    if (data.onClick) data.onClick(e);
  };

  return (
    <button
      id={data.id ?? ""}
      type={type}
      css={buttonIconStyle}
      className={`${data.className} ${isActive}`}
      onClick={onCick}
      form={data.form ?? ""}
    >
      {data.label}
    </button>
  );
}

ButtonComponent.defaultProps = {
  className: "",
};
