import ReactDom from "react-dom";

const ModalPortal = ({ children, type = "modal" }) => {
  if (type === "modal") {
    const el = document.getElementById("modal_root");

    return ReactDom.createPortal(children, el);
  }

  if (type === "notification") {
    const el = document.getElementById("notification_root");

    return ReactDom.createPortal(children, el);
  }

  if (type === "gallery") {
    const el = document.getElementById("gallery_root");

    return ReactDom.createPortal(children, el);
  }
};

export default ModalPortal;
