import queryString from "query-string";

import axiosWrapper from "../utils/axios-wrapper";

const { get, post } = axiosWrapper();

export const getNotificationList = (token, query) => {
  const queryData = queryString.stringify(query);
  return get(`notification/list?${queryData}`, token);
};

export const getIsNotificationHaving = (token) => {
  return get(`notification/having`, token);
};

export const postRemoveNotification = (token, data) => {
  return post(`notification/remove`, token, data);
};

export const postReadNotification = (token, data) => {
  return post(`notification/read`, token, data);
};

export const getListCount = (token) => {
  return get(`notification/count`, token);
};
