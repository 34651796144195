import { css } from "@emotion/react";
import { fontBody } from "../../../../../styles/common.css";

export default css`
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${fontBody};
    margin-top: 20px;
  }

  > div {
    display: flex;
    flex-direction: column;
    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
    }
    p {
      margin-top: 12px;
      text-align: right;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      margin-top: 20px;
    }

    .like-user {
      &-profile {
        border: 1px solid #ffffff;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.12));
        box-sizing: border-box;
      }
    }
  }
  @media screen and (max-width: 1212px) {
    p,
    .WidgetLikeUser > div {
      max-width: 200px;
    }
  }

  @media screen and (max-width: 800px) {
    p,
    .WidgetLikeUser > div {
      min-width: 100%;
    }

    p {
      margin-top: 12px;
    }
  }
`;
