import axiosWrapper from "../utils/axios-wrapper";
import queryString from "query-string";
import { BASIC_CONSTANT } from "../utils/basic.constants";

const { post, get, put } = axiosWrapper();

export const createProduct = (data, token) => {
	try {
		const response = post("product", token, data);

		return response;
	} catch (error) {
		throw error;
	}
};

export const getProduct = async (id) => {
	const response = await get(`product/${id}`);
	return response;
};

export const getProductList = (id, query) => {
	const queryData = queryString.stringify(query);

	const response = get(`product/list/${id}?${queryData}`);
	return response;
};

export const updateProduct = (id, data, token) => {
	try {
		const response = put(`product/${id}`, token, data);

		return response;
	} catch (e) {
		throw e;
	}
};

export const getProductListByText = (token, query) => {
	const response = get(`product/tag/list?text=${query}`, token);
	return response;
};

export const postProductCount = (id, countType, token) => {
	const response = post(`product/${id}/count`, token, { countType });
	return response;
};

export const getRelatedProductTag = (search, token) => {
	const response = get(`product-related/tag/list?search=${search}`, token);
	return response;
};

export const postRelatedProductTag = (data) => {
	const token = localStorage.getItem(BASIC_CONSTANT.CLIENT_TOKEN);
	const response = post(`product-related/tag`, token, data);
	return response;
};
