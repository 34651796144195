export const goodArticle = {
  name: "good",
  value: true,
  label: "좋은 글을 소개할래요.",
};

export const myArticle = {
  name: "myArticle",
  value: true,
  label: "제가 직접 쓴 글이에요.",
};
