export function convertProductTagList(list = []) {
	const newList = list.map((item) => {
		return {
			...item,
			value: item.id,
		};
	});

	return newList;
}
