import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation,
} from "emoji-picker-react";

export default function PopupEmoji({ list, close }) {
  const { onClick } = list;

  const handleOnEmojiClick = (data) => {
    onClick(data);
    // close();
  };

  return (
    <div>
      <EmojiPicker
        onEmojiClick={handleOnEmojiClick}
        lazyLoadEmojis={true}
        emojiStyle="native"
        height={400}
        width={320}
        // theme={Theme.AUTO}
        //skinTonesDisabled="false"
      />
    </div>
  );
}
