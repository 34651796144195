import { css } from "@emotion/react";

export default css`
  position: relative;
  display: flex;

  .edit-button {
    position: absolute;
    z-index: 2;
  }

  &.profile-image {
    .edit-button {
      position: relative;
      right: 28px;
      bottom: -32px;
    }
  }

  &.background {
    max-height: 256px;
    width: 100%;
    .background-image {
      min-height: 160px;

      &[data-clickable="true"] {
        cursor: pointer;
      }
    }

    .edit-button {
      right: 45%;
      top: 50%;
      transform: translate(-45%, -50%);
    }
  }

  &.product {
    width: 120px;
    height: 120px;

    .edit-button {
      right: -15px;
      bottom: 0;
    }
  }

  .image {
    width: 100vw;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .pointer {
    cursor: pointer;
  }

  @media screen and (max-width: 800px) {
    &.background {
      margin-top: 50px;
      .edit-button {
        right: 36%;
      }
    }
  }
`;
