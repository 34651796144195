import { atom } from "recoil";

export const defaultPopup = {
  isOpen: false,
  title: "",
  data: null,
  top: 0,
  left: 0,
  width: 0,
};

export const popupStore = atom({
  key: "popup",
  default: defaultPopup,
});
