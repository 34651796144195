import { css } from "@emotion/react";
import {
  border,
  gray_01,
  black,
  fontBlack,
  fontBody,
  hoverBlue,
  boxHover,
} from "../../../../styles/common.css";

export default css`
  position: relative;
  .tag-wrapper {
  }

  .input-container {
    position: relative;
    display: flex;
    width: 100%;
    border: 1px solid black;

    input {
      width: 100%;
      border: ${border};
      border-radius: 6px;
      padding: 16px 20px;
      &:hover {
        ${boxHover}
      }
    }
  }

  .tag-input {
    margin-bottom: 10px;
    > .input-wrapper {
      border: none;
    }
  }

  .tag-container-list {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 520px;
    gap: 4px;
    li {
      display: flex;
      flex-direction: row;
      background-color: ${gray_01};
      border-radius: 30px;
      padding: 5px 8px;
      cursor: pointer;
      font-size: 12px;
      line-height: 18px;

      &:not(:last-of-type) {
      }
    }
  }

  @media screen and (max-width: 800px) {
    .tag-container-list {
      width: 100%;
      gap: 6px;
    }
  }
`;
