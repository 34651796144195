import { useEffect, useState } from "react";
import Head from "next/head";
import { NextSeo } from "next-seo";

import useModal from "../../hooks/useModal";
import useUser from "../../hooks/useUser";
import Layouts from "./Layouts";
import Modal from "./Modal";
import Router, { useRouter } from "next/router";
import Popup from "./Popup";
import usePopup from "../../hooks/usePopup";
import useDefaultData from "../../hooks/useDefaultData";

import Gaevaring from "../../assets/images/gaevaring.svg";
import { getIsNotificationHaving } from "../../apis/notification.api";
import useHandleNotification from "../../hooks/useNotification";
import defaultMetaImage from "../../../public/images/sofans_og_img.jpg";
import useAlert from "../../hooks/useAlert";
import AlertContainer from "./Alert";
import { ALERT_MODAL_DEFAULT } from "./Alert/utils/alert.constants";
import { BASIC_CONSTANT } from "../../utils/basic.constants";
import { PATH_AUTH, PATH_ERROR } from "../../paths";
import { getPublicNotice } from "../../apis/notice.api";

export default function Page({
  variant,
  children,
  isPublic = false,
  hideMenu,
  hideWidget,
  onlyImage,
  title = "소팬즈 - SaaS 유저 커뮤니티",
  description = "GPT, 노션, Figma 등 업무에 도움되는 생성 AI / 자동화 툴을 소팬즈에서 탐색하세요.",
  metaImage = defaultMetaImage.src,
  className = "",
}) {
  const user = useUser(isPublic);
  const { modalData, galleryState, closeModal } = useModal();
  const { popupData, closePopup } = usePopup();
  const alertData = useAlert();

  const router = useRouter();

  const { handleSetNotificationData } = useHandleNotification();
  const enabledIsNotificationHaving = !!user?.token;

  // const { setDefaultData, defaultDataState } = useDefaultData();

  function handleOutsideClick(e) {
    if (e.target.id === "modal_root") {
      if (modalData.isConfirmModal) {
        alertData.openAlert({
          ...ALERT_MODAL_DEFAULT,
          events: [
            {
              id: "confirm",
              onClick: () => {
                closeModal();
              },
            },
          ],
        });
      } else {
        closeModal();
      }
    } else if (e.target.id === "gallery_root") {
      closeModal("gallery");
    }

    if (e.target.id === "popup_container") {
      closePopup();
    }
  }

  async function getNotificationHaving() {
    const { data } = await getIsNotificationHaving(user.token);
    handleSetNotificationData({ isActive: data });
  }

  function scrollHandle(isOpen) {
    if (isOpen) document.body.style.overflowY = "hidden";

    if (!isOpen) document.body.style.overflowY = "auto";
  }

  function savePrevPath(aspath, pathname) {
    const authUrls = Object.keys(PATH_AUTH).map((key) => PATH_AUTH[key].url);
    const errors = Object.keys(PATH_ERROR).map((key) => PATH_ERROR[key].url);

    if (authUrls.includes(pathname) || errors.includes(pathname)) {
      localStorage.removeItem(BASIC_CONSTANT.PREV_SITE_URL);
      return;
    }

    localStorage.setItem(BASIC_CONSTANT.PREV_SITE_URL, aspath);
  }

  async function noticeApi() {
    const preventPopupId = localStorage.getItem(BASIC_CONSTANT.PREVENT_POPUP);
    const result = await getPublicNotice(preventPopupId);

    if (result?.data) {
      alertData.openAlert({
        title: "공지사항",
        description: result?.data?.description,
        image: result?.data?.image,
        buttons: [
          {
            id: "prevent",
            label: "다시는 보지 않기",
            onClick: () => {
              localStorage.setItem(
                BASIC_CONSTANT.PREVENT_POPUP,
                result?.data?.id
              );
            },
          },
          {
            id: "confirm",
            label: "확인",
          },
        ],
      });
    }
  }

  useEffect(() => {
    noticeApi();
  }, []);

  useEffect(() => {
    if (popupData.isOpen) {
      window.addEventListener("click", handleOutsideClick);
      scrollHandle(true);
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
      scrollHandle(false);
    };
  }, [popupData]);

  useEffect(() => {
    if (modalData?.isOpen) {
      window.addEventListener("click", handleOutsideClick);
      scrollHandle(true);
    } else {
      window.removeEventListener("click", handleOutsideClick);
      scrollHandle(false);
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
      scrollHandle(false);
    };
  }, [modalData]);

  useEffect(() => {
    if (enabledIsNotificationHaving) getNotificationHaving();
  }, [router.pathname]);

  useEffect(() => {
    savePrevPath(router.asPath, router.pathname);
  }, [router.asPath]);

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        openGraph={{
          type: "website",
          title: title,
          locale: "ko_KR",
          description: description,
          images: [
            {
              url: metaImage,
              width: 1200,
              height: 600,
              alt: "소팬즈",
            },
          ],
          site_name: "소팬즈",
        }}
      />
      <Head>
        <title>{title}</title>
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link
          rel="manifest"
          href="/manifest.json"
          crossOrigin="use-credentials"
        />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="description" content={description} />
      </Head>
      <Layouts
        variant={variant}
        hideMenu={hideMenu}
        hideWidget={hideWidget}
        onlyImage={onlyImage}
        className={className}
      >
        {children}
      </Layouts>
      {/* {defaultDataState?.openBrowser ? (
        <Layouts
          variant={variant}
          hideMenu={hideMenu}
          hideWidget={hideWidget}
          onlyImage={onlyImage}
        >
          {children}
        </Layouts>
      ) : (
        <div id="gaevaring">
          <Gaevaring />
        </div>
      )} */}

      {/* TOAST */}
      {/* Modal */}
      {modalData?.isOpen && <Modal {...modalData} closeModal={closeModal} />}
      {galleryState?.isOpen && (
        <Modal {...galleryState} closeModal={closeModal} />
      )}
      {/* Popup */}
      {popupData?.isOpen && <Popup {...popupData} close={closePopup} />}
      {/* Alert */}
      <AlertContainer {...alertData} onClose={alertData.closeAlert} />
    </>
  );
}
