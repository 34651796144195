import { forwardRef, useEffect, useState } from "react";
import LabelComponent from "../LabelComponent";
import styles from "./input.css";

function classNameHandle(className, readOnly) {
	let classString = className;

	if (readOnly) classString += " read-only";

	return classString;
}

const Input = forwardRef(
	(
		{
			error,
			value,
			label,
			type,
			name,
			placeholder,
			helperText,
			style,
			onChange,
			onKeyDown,
			required = false,
			readOnly = false,
			description = "",
			className = "",
			loading,
			onClick,
			rightIcon,
			rightIconOnClick,
			onBlur,
		},
		ref
	) => {
		const [state, setState] = useState(null);
		const isHaveValue = !!state;

		const inputClassName = classNameHandle(className, readOnly);

		const preventEvents = (e) => {
			if (e.keyCode === 13) {
				e.preventDefault();
				e.stopPropagation();
			}

			if (onKeyDown) onKeyDown(e);
		};

		const handleChange = (e) => {
			setState(e.target.value);
			if (onChange) onChange({ target: { name, value: e.target.value } }, e);
		};

		const handleOnClick = (e) => {
			if (onClick) onClick(e);
		};

		useEffect(() => {
			setState(value);
		}, [value]);

		return (
			<div css={styles} className={inputClassName} onClick={handleOnClick}>
				{label && (
					<LabelComponent
						label={label}
						required={required}
						name={name}
						description={description}
					/>
				)}
				<div className="input-wrapper" aria-error={error} aria-have-value={isHaveValue}>
					<input
						ref={ref}
						enterKeyHint="enter"
						id={name}
						error={error}
						value={state}
						label={label}
						type={type}
						name={name}
						placeholder={placeholder}
						helpertext={helperText}
						onChange={handleChange}
						onKeyDown={preventEvents}
						style={style}
						readOnly={readOnly}
						onBlur={onBlur}
						tabIndex={0}
					/>
					{loading && (
						<div class="icon-container">
							<i class="loader"></i>
						</div>
					)}
					{rightIcon && (
						<div
							className="right-icon"
							onClick={(e) => {
								if (rightIconOnClick) rightIconOnClick(e);
							}}
						>
							{rightIcon}
						</div>
					)}
				</div>
			</div>
		);
	}
);

Input.displayName = "Input";

export default Input;
