import { useSetRecoilState } from "recoil";
import { widgetDataTypeStore } from "../recoils/widgetDataType";
import { FORM_TYPE } from "./constants";
import { ACTIVITIES_JSON, productCategoryTabs } from "./json/tab.json";
import { WIDGET_CONSTANTS_PAGENAME } from "../components/common/Widget/widget.constants";
import { PATH_PRODUCT } from "../paths";
import {
	LANGUAGE_JSON,
	PRODUCT_PRICE_JSON,
} from "../components/Product/json/product-static.json";

const convertImage = (item) => {
	if (!item?.images || item?.images?.length === 0) return [];

	return item?.images?.map((image) => {
		return {
			id: image.id,
			original: image.file?.original ?? null,
			thumbnail: image.file?.thumbnail ?? null,
			og: image.file?.og ?? null,
		};
	});
};

const convertProfile = (data) => {
	if (!data || data?.length === 0) return [];

	return data?.map((item) => {
		return {
			id: item.id,
			email: item?.email,
			name: item?.profile.name,
			nickname: item?.profile.nickname,
			image: item.profile.image,
			oneLineIntroduction: item?.profile.oneLineIntroduction,
		};
	});
};

export const commonConvertData = (data) => {
	const isArray = Array.isArray(data);

	if (isArray) {
		return data.map((item) => {
			item.isLike = false;
			const images = convertImage(item);
			return {
				...item,
				images,
			};
		});
	} else {
		data.isLike = false;
		const obj = new Object({
			platforms: {
				Web: data?.isWeb,
				ChromeExtension: data?.isChromeExtension,
				Android: data?.isAndroid,
				iOS: data?.isIos,
			},
		});

		for (const key in data) {
			if (key === "images" && data?.images?.length > 0) obj[key] = convertImage(data);
			else if (key === "users" && data?.users?.length > 0) {
				obj[key] = convertProfile(data?.users);
			} else if (key === "language" && !!data[key]) {
				const newLanguage = [];
				const language = data[key].split(",").map((item) => {
					const findItem = LANGUAGE_JSON.find((lang) => lang.value === item);

					if (findItem) newLanguage.push(findItem);

					return findItem?.name ?? "";
				});

				obj["read_language"] = language.join(", ");
				obj[key] = newLanguage;
			} else if (key === "activities") {
				const newActivities = [];

				ACTIVITIES_JSON.forEach((item) => {
					if (data?.[key]?.includes(item.value)) newActivities.push(item);
				});

				obj[key] = newActivities;
			} else if (key === "price") {
				const newValue = PRODUCT_PRICE_JSON.find((item) => item.value === data?.[key]);
				obj["read_price"] = newValue?.label ?? "";
				obj[key] = data?.[key];
			} else if (data[key]) {
				obj[key] = data[key];
			}
		}

		return obj;
	}
};

export function userConvertData(fetchData, recoilData) {
	const user = {
		...fetchData,
		isMe: fetchData?.id === recoilData?.id,
		token: recoilData?.token ?? null,
	};

	if (recoilData?.id) {
		const isFollow = fetchData?.followers?.some(
			(item) => item?.followingId === recoilData?.id
		);
		user.isFollow = isFollow;
	}

	return user;
}

export function isValidHttpUrl(string) {
	const pattern = new RegExp(
		"^(https?:\\/\\/)?" + // protocol
			"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
			"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
			"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
			"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
			"(\\#[-a-z\\d_]*)?$", // fragment locator
		"i"
	);
	return pattern.test(string);
}

export function errorAlert(message) {
	alert(message);
	return;
}

export function elapsedTime(date) {
	const stamp = +new Date(date);
	const start = +new Date(stamp) + 9 * 60 * 60 - 60000;
	const end = +new Date();

	const diff = (end - start) / 1000;

	const times = [
		{ name: "년", milliSeconds: 60 * 60 * 24 * 365 },
		{ name: "개월", milliSeconds: 60 * 60 * 24 * 30 },
		{ name: "일", milliSeconds: 60 * 60 * 24 },
		{ name: "시간", milliSeconds: 60 * 60 },
		{ name: "분", milliSeconds: 60 },
	];

	for (const value of times) {
		const betweenTime = Math.floor(diff / value.milliSeconds);

		if (betweenTime > 0) {
			return `${betweenTime}${value.name} 전`;
		}
	}
	return "방금 전";
}

export function useHandleBtns(btnsJson, isMobile = true, submitCallback) {
	const setDataType = useSetRecoilState(widgetDataTypeStore);

	return btnsJson.map((btn) => {
		if (btn.type === "submit" && submitCallback) {
			const className = `${btn.className} ${!isMobile ? "hidden-btn" : ""}`;
			return {
				...btn,
				className,
				onClick: () => {
					submitCallback();
				},
			};
		}

		if (btn.type === "button") {
			const className = `${btn.className} ${!isMobile ? "hidden-btn" : ""}`;
			return {
				...btn,
				className,
				onClick: () => {
					if (btn.id === "cancel-btn") {
						setDataType(FORM_TYPE.GET);
					}
				},
			};
		} else {
			return btn;
		}
	});
}

export function getValueFromCategory(category = []) {
	const value = category.reduce((acc, cur, index) => {
		if (index === 0) return cur.value;
		return `${acc},${cur.value}`;
	}, "");

	return value;
}

export const currentPageHandle = (pathname, isExcept = false) => {
	let pagename = "home";
	const isProduct =
		pathname === PATH_PRODUCT.detail("[id]").url ||
		pathname === PATH_PRODUCT.create.url ||
		(!isExcept && pathname === PATH_PRODUCT.root.url);

	const isUserPage = pathname.includes("user");

	const isCommunity = pathname.includes("community");
	const isSearch = pathname.includes("search");

	if (isProduct) pagename = WIDGET_CONSTANTS_PAGENAME.PRODCUT;
	if (isUserPage) pagename = WIDGET_CONSTANTS_PAGENAME.USER;
	if (isCommunity) pagename = WIDGET_CONSTANTS_PAGENAME.COMMUNITY;
	if (isSearch) pagename = WIDGET_CONSTANTS_PAGENAME.SEARCH;

	return pagename;
};

export const convertTagValues = (elements, type = null) => {
	try {
		const values = [];
		elements?.childNodes?.forEach((element) => {
			// const name = element.getAttribute("name");
			const id = element.id;
			if (type === "productUse") values.push({ id });
			else values.push(id);
		});

		return values;
	} catch (err) {
		console.log(err);
	}
};

export const convertProductTagValues = (elements) => {
	try {
		const values = [];
		elements?.childNodes?.forEach((element) => {
			const id = element.id;
			values.push({ id });
		});

		return values;
	} catch (err) {
		console.log(err);
	}
};

export const convertProductTagValue = (elements) => {
	const product = {};

	console.log(11, elements?.childNodes);
};
