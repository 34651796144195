import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import LabelComponent from "../LabelComponent";

function getYoutubeId(url) {
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
}

export default function Iframe({ data, value }) {
  const [id, setId] = useState();

  useEffect(() => {
    if (value) {
      const youtubeId = getYoutubeId(value);
      setId(youtubeId);
    }
  }, [value]);

  // const [props, setProps] = useState({});
  return !value ? (
    <></>
  ) : (
    <div>
      {data?.label && <LabelComponent label={data?.label} />}
      <div
        className="video-wrap"
        css={css`
          overflow: hidden;
          position: relative;

          padding-bottom: 56.25%; /* 16:9 */
          border-radius: 6px;
        `}
      >
        <iframe
          src={`https://www.youtube.com/embed/${id}`}
          allow="fullscreen"
          title="YouTube video player"
          css={css`
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          `}
        />
      </div>
    </div>
  );
}
