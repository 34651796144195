import { buttonsStyle } from "./button.css";
import ButtonComponent from "./Button.item";

export default function Button(params) {
  const isArrayJson = Array.isArray(params.data);
  return (
    <div css={buttonsStyle} className={params.className}>
      {isArrayJson ? (
        params.data.map((item, index) => (
          <ButtonComponent key={index} {...item} />
        ))
      ) : (
        <ButtonComponent {...params.data} />
      )}
    </div>
  );
}
