import { myArticle } from "../../utils/json/modal.json";
import Checkbox from "../common/Checkbox";
import Input from "../common/Input";
import Select from "../common/Select";
import SelectTags from "../common/SelectTags";

import css from "./home.modal.css";
import { getProductList } from "../../apis/product.api";
import { convertProductTagList } from "./article.utils";

export default function ModalHome({ categories }) {
  async function callProductList(search) {
    const { data } = await getProductList("public", {
      search,
      type: "all",
      limit: 10,
      offset: 0,
    });
    return convertProductTagList(data?.list);
  }

  return (
    <div css={css}>
      <p>
        당신이 알고 있는 멋진 글을 저희에게 알려주세요. 직접 쓴 글이 아니어도
        괜찮아요.
        {/* <br /> 우리는 모든 이에게 도움이 될만한 10개의 글을 매일 엄선하여 세상에
        알리고 있어요. */}
      </p>
      <Input
        name="url"
        placeholder="Page URL"
        label="글 URL"
        description="소개할 페이지의 URL을 입력해주세요."
        className="modal-input"
      />
      <Select
        className="modal-select"
        label="카테고리"
        name="categoryId"
        data={categories}
        placeholder="카테고리를 선택해주세요."
      />
      <SelectTags
        className="modal-tags"
        label="태그"
        description="글과 연관된 태그를 입력해주세요. (최대 3개)"
        placeholder="새로운 태그를 입력 후 엔터를 치거나, 추가할 태그를 선택해주세요."
        tagId="tags"
        length={3}
      />
      <SelectTags
        className="product-tags"
        label="관련 프로덕트"
        description="글과 연관된 프로덕트를 입력해주세요. (최대 3개)"
        placeholder="새로운 프로덕트를 입력 후 엔터를 치거나, 추가할 프로덕트를 선택해주세요."
        length={3}
        tagId="products"
        api={callProductList}
      />
      <Checkbox
        className="modal-checkbox"
        data={myArticle}
        {...myArticle}
        checked={false}
      />
      <div className="modal-home-description">
        {/* <h4>선정 기준 안내</h4> */}
        <h4>아티클 제출 안내</h4>
        <p>
          {/* SaaS와 연결고리가 있는 글 중에서 우리 커뮤니티 구성원에게 소개하고
          싶은 글을 선정하고 있어요.
          <br /> */}
          기업 문화, 최신 뉴스, 프로덕트 개발기, 기획, 디자인 등 다양한 주제의
          글을 저희에게 알려주세요.
          <br />
          제출해주신 글이 저희 커뮤니티 주제와 관련성이 없는 글일 경우 노출이
          제한될 수 있어요.
        </p>
      </div>
    </div>
  );
}
