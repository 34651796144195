import { useRecoilState } from "recoil";
import Router from "next/router";
import { useQuery } from "@tanstack/react-query";
import { BASIC_CONSTANT } from "../utils/basic.constants";

import useLocalstorage from "./useLocalstorage";
import { PATH_AUTH } from "../paths";
import { getMe } from "../apis/user.api";
import { userInfo } from "../recoils/user";
import { useEffect } from "react";

export default function useUser(isPublic) {
	const [user, setUser] = useRecoilState(userInfo);
	const token = useLocalstorage(BASIC_CONSTANT.CLIENT_TOKEN);

	const enabled = !!token && !user;

	const getMeFn = async () => {
		try {
			const { data } = await getMe(token);

			data.token = token;
			setUser(data);
		} catch (error) {
			console.log(111, error);
			if (error?.status === 401) {
				localStorage.removeItem(BASIC_CONSTANT.CLIENT_TOKEN);
				if (!isPublic) {
					Router.push(PATH_AUTH.login.url);
				}
			}
		}
	};

	useEffect(() => {
		if (enabled) {
			getMeFn();
		}
	}, [enabled]);

	return user;
}
