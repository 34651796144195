import { css } from "@emotion/react";
import {
  black,
  border,
  catYellow,
  fontBlack,
  fontBodyLight,
  white,
} from "../../../styles/common.css";

export const notificationPresenterCss = css`
  position: relative;
  width: 100%;

  .grid-image {
    position: relative;
    width: 40px;
    height: 40px;
  }

  .mobile {
    display: none;
  }

  .no-data-wrapper {
    position: relative;
    min-height: calc(100vh - 180px);

    border-top: ${border};
    padding-top: 20px;

    p {
      font-weight: 700;
      line-height: 19px;

      display: flex;
      align-items: center;

      color: ${fontBlack};
    }

    .no-data-img {
      position: absolute;
      bottom: 10vh;

      width: 100%;
      min-height: 400px;

      opacity: 0;

      animation-duration: 0.4s;
      animation-name: emptyCat;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      animation-delay: 0.2s;
      animation-fill-mode: forwards;
    }
  }

  .notification {
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .title {
        display: flex;
        align-items: center;
        h3 {
          font-weight: 800;
          font-size: 32px;
          line-height: 48px;

          color: ${black};
          margin-right: 17px;
        }
      }
      .bolder {
        margin-right: 44px;
      }
    }

    &-counts {
      margin-top: 8px;
      margin-bottom: 28px;

      font-weight: 500;
      font-size: 16px;
      line-height: 19px;

      display: flex;
      align-items: center;
      text-align: center;

      color: ${black};

      span {
        font-weight: 700;
        margin-left: 4px;
        color: ${catYellow};

        margin-right: 2px;
      }
    }

    &-list {
      position: relative;
      display: flex;
      flex-direction: column;

      padding-bottom: 24vh;
    }
  }

  @media screen and (max-width: 800px) {
    .grid-image {
      width: 28px;
      height: 28px;
    }
    .mobile {
      display: flex;
    }

    .no-data-wrapper {
      .no-data-img {
        width: 162px;
        min-height: 192px;

        left: 50%;
        bottom: 20vh;
        transform: translateX(-50%);
        /* opacity: 1; */
      }
    }

    .notification {
      &-header {
        align-items: flex-start;
        .title {
          align-items: flex-end;
          h3 {
            font-size: 24px;
            line-height: 36px;
            margin-right: 5px;
          }
        }
      }

      &-counts {
        margin: 20px 0;
      }
    }
  }
`;

export const notificationItemWrapperCss = css`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  gap: 24px;

  /* width: 700px;
  height: 81px; */

  border-top: ${border};

  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
    transition: 0.2s ease;
  }

  &.read {
    .notification-icons,
    .notification-item {
      opacity: 0.6;

      .cat-yellow {
        color: ${fontBlack};
      }
    }
  }

  @media screen and (max-width: 800px) {
    padding: 10px 0;
    gap: 10px;

    align-items: flex-start;

    &:hover {
      background-color: transparent;
    }
  }
`;

export const notificationIconsCss = css`
  display: flex;
  flex-direction: row;
  gap: 4px;

  .notification-type {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: ${fontBlack};
    cursor: default;

    p {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: ${white};
    }

    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;

    > div {
      &.profile-image {
        width: 28px !important;
        height: 28px !important;
        > div {
          height: 28px !important;
        }
      }
      z-index: 0;
    }

    .notification-type {
      width: 28px;
      height: 28px;
      p {
        font-size: 16px;
      }
    }
  }
`;

export const notificationAcitveCss = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;

  background: #ff7900;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 800px) {
    width: 8px;
    height: 8px;
  }
`;

export const notificationContentCss = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  .notification-item-contents {
    display: flex;
    white-space: pre;
    color: ${fontBlack};
    cursor: default;
    max-width: 500px;

    .cat-yellow {
      color: ${catYellow};
      cursor: pointer;
      max-width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      margin-right: 2px;

      &:hover {
        text-decoration: underline;
      }
    }
    .comment-wrapper {
      max-height: 20px;
      max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: break-spaces;
      > p {
        display: flex;
      }
    }
  }

  .notification-item-date {
    font-size: 12px;
    font-weight: 700;
    cursor: default;
    color: ${fontBodyLight};
  }

  @keyframes emptyCat {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media screen and (max-width: 800px) {
    .notification-item-contents {
      flex-wrap: wrap;
      max-width: 100%;
      font-size: 14px;

      .cat-yellow {
        max-width: 50%;
        font-size: 14px;
      }
    }
  }
`;
