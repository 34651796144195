import axiosWrapper from "../utils/axios-wrapper";
const { get } = axiosWrapper();

export const getPublicNotice = (preventPopupId) => {
	let url = "notice/public";

	if (preventPopupId) url += `?preventId=${preventPopupId}`;

	const response = get(url);
	return response;
};
