import { useEffect, useState } from "react";
import ButtonComponent from "../../Button/Button.item";

export default function WidgetButtons({
  label,
  btnClickEvent,
  isShowCancel = false,
  cancelBtn,
}) {
  const [btnLabel, setLabel] = useState(null);

  useEffect(() => {
    setLabel(label);
  }, [label]);

  return (
    <div
      className={`widget-component-top-btns ${isShowCancel ? "grid" : "flex"}`}
    >
      {isShowCancel && (
        <ButtonComponent className="cancel" label="취소" onClick={cancelBtn} />
      )}
      <ButtonComponent
        className="article-btn-post"
        label={btnLabel}
        onClick={btnClickEvent}
      />
    </div>
  );
}
