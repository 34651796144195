import { css } from "@emotion/react";
import {
  black,
  blackBtn,
  border,
  catHover,
  catYellow,
  fontBlack,
  fontBody,
} from "../../../../styles/common.css";

export default css`
  padding: 32px;
  /* overflow: auto; */
  width: 384px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  h3 {
    color: ${fontBlack};
    font-weight: 800;
    font-size: 20px;
    line-height: 30px;
  }

  .label-btn {
    display: flex;
    align-items: center;
    &:hover {
      span {
        opacity: 1;
        transition: 0.2s ease;
      }
    }
    > span {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      opacity: 0.8;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  .product-widget-button {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #ffffff;

    img {
      position: relative;
      width: 20px;
      height: 20px;
    }

    p {
      opacity: 0.8;
    }
  }

  .google-login-btn {
    color: #fff;
    ${blackBtn}
    border-radius: 50px;
    padding: 11px 30px;
    text-align: center;

    > span {
      font-size: 14px;
      line-height: 18px;
      opacity: 0.8;
      font-weight: 500;
    }

    margin-bottom: 20px;
  }

  .article-btn-post {
    ${blackBtn}
    border-radius: 50px;
    padding: 10px 16px;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 600;
    line-height: 18px;
    transition: 0.2s ease;

    &:hover {
      color: rgba(255, 255, 255, 1);
      transform: scale(1.07);

      span {
        opacity: 1;
      }
    }

    .article-submit-widget {
      display: flex;
      align-items: center;
      color: #ffffff;
      font-weight: 600;
    }
  }

  .widget_bottom {
    > div {
      display: flex;
      flex-wrap: wrap;

      a {
        cursor: pointer;

        &:not(:last-of-type) {
          margin-right: 10px;
        }
      }
    }

    a > span,
    > p {
      color: ${fontBody};
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
    }
  }

  a > span {
    &:hover {
      color: ${catYellow};
      transition: 0.2s ease;
    }
  }

  .widget-component-top-btns {
    margin-bottom: 12px;

    &.grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    &.flex {
      button {
        width: 100%;
      }
    }

    .cancel {
      margin-right: 10px;
      color: #222222;
    }
  }

  input {
    width: 100%;
  }

  .pc-ads-wrapper {
    margin-top: 40px;
    gap: 20px;
    .pc-ad {
      img {
        width: 100%;
        max-width: 324px;
        border-radius: 6px;
      }
    }
  }

  .vc-logo {
    width: 60%;

    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    > p {
      color: ${fontBody};
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    img {
      opacity: 0.7;
    }
  }

  @media screen and (max-width: 1212px) {
    max-width: 280px;
  }

  @media screen and (max-width: 1074px) {
    display: none;
  }
`;
