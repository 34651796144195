export const homeTabJson = [
  { title: "요즘 트렌드", value: 0 },
  { title: "기술", value: 0 },
];

export const userTab = [
  { title: "소개", value: 0 },
  // { title: "활동", value: 1 },
  { title: "작성한 글", value: 2 },
  { title: "북마크", value: 3 },
];

export const productTab = [
  {
    title: "소개",
    value: 0,
  },
  {
    title: "활용법",
    value: 2,
  },
  {
    title: "아티클",
    value: 3,
  },
  {
    title: "커뮤니티",
    value: 1,
  },
];

export const productCategoryTabs = [
  {
    name: "ChatGPT",
    value: "chatgpt",
    id: "chatgpt",
  },
  {
    name: "개발",
    value: "dev",
    id: "dev",
  },
  {
    name: "디자인",
    value: "design",
    id: "design",
  },
  {
    name: "마케팅",
    value: "marketing",
    id: "marketing",
  },
  {
    name: "커뮤니케이션",
    value: "communication",
    id: "communication",
  },
  {
    name: "PM",
    value: "pm",
    id: "pm",
  },
  {
    name: "경영/인사",
    value: "management",
    id: "management",
  },

  {
    name: "결제/인증",
    value: "payment",
    id: "payment",
  },
  {
    name: "노코드",
    value: "noCoding",
    id: "noCoding",
  },
  {
    name: "업무자동화",
    value: "automation",
    id: "automation",
  },
  {
    name: "문서/폼",
    value: "document",
    id: "document",
  },
  {
    name: "이메일",
    value: "email",
    id: "email",
  },
  {
    name: "AI",
    value: "ai",
    id: "ai",
  },

  {
    name: "챗봇",
    value: "chat",
    id: "chat",
  },
  {
    name: "CRM",
    value: "customer",
    id: "customer",
  },

  {
    name: "웹 빌더",
    value: "web",
    id: "web",
  },
  {
    name: "분석",
    value: "analytics",
    id: "analytics",
  },
  {
    name: "데이터",
    value: "data",
    id: "data",
  },
  {
    name: "커머스",
    value: "commerce",
    id: "commerce",
  },
  {
    name: "미디어",
    value: "media",
    id: "media",
  },
  {
    name: "VR/AR",
    value: "vr",
    id: "vr",
  },
  {
    name: "메타버스",
    value: "metaverse",
    id: "metaverse",
  },
  {
    name: "클라우드",
    value: "cloud",
    id: "cloud",
  },
  {
    name: "교육",
    value: "education",
    id: "education",
  },
  {
    name: "보안",
    value: "security",
    id: "security",
  },
];

export const SEARCH_TABS = [
  {
    name: "프로덕트",
    value: "product",
    id: "product",
  },
  {
    name: "커뮤니티",
    value: "community",
    id: "community",
  },
  {
    name: "유저",
    value: "user",
    id: "user",
  },
  {
    name: "HOT 이슈",
    value: "article",
    id: "article",
  },
];

export const ACTIVITIES_JSON = [
  {
    name: "프로모션 중",
    value: "promotion",
    id: "promotion",
  },
  {
    name: "MVP 개발 중 🔧",
    value: "mvp",
    id: "mvp",
  },
  {
    name: "유저 모집 중 🔥",
    value: "recruiting",
    id: "recruiting",
  },
  {
    name: "사전 예약 모집 중 ⚡",
    value: "preReservation",
    id: "preReservation",
  },
];
