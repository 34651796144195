import { css } from "@emotion/react";

export default css`
  width: 100%;
  > p {
    margin-bottom: 40px;
  }

  .modal-input,
  .modal-tags,
  .modal-select {
    margin-bottom: 28px;
  }
`;
