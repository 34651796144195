import { css } from "@emotion/react";
import {
	catYellow,
	catYellowHover,
	fontBlack,
	fontBody,
	layoutBorder,
	white,
} from "../../../../styles/common.css";

export const buttonIconStyle = css`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&.bolder {
		${layoutBorder}
		background-color: ${white};
		border-radius: 40px;
		padding: 0 16px;
		height: 40px;

		button {
			display: flex;
			gap: 8px;
			background-color: transparent;

			p {
				font-weight: 600;
				font-size: 14px;
				line-height: 17px;

				display: flex;
				align-items: center;
				text-align: center;

				color: ${fontBlack};

				opacity: 0.7;
			}
		}

		&:hover {
			background-color: rgba(0, 0, 0, 0.1);
			transition: 0.2s ease;

			p {
				opacity: 1;
			}
		}
	}

	&.bold {
		font-weight: 700;
		color: ${fontBody};
		font-size: 16px;
		line-height: 19px;
	}

	&.black-btn {
		width: 100%;
		/* max-width: 670px; */

		background-color: ${fontBlack};
		color: ${white};
		font-weight: 500;
		line-height: 17px;
		font-size: 14px;
		cursor: pointer;
		border-radius: 40px;
		padding: 17px 16px;
		transition: 0.2s ease;

		&.signup-btn {
			color: rgba(255, 255, 255, 0.8);
			transition: 0.2s ease;
		}

		button {
			width: 100%;
			justify-content: center;
			border-radius: 40px;
			background-color: transparent;
		}
		span {
			font-size: 14px;
			font-weight: 500;
			text-align: center;
			color: #fff;
			opacity: 0.8;
			transition: 0.2s ease;
		}

		&:hover {
			transform: scale(1.07);

			span {
				opacity: 1;
			}

			&.signup-btn {
				transform: scale(1.05);
				color: rgba(255, 255, 255, 1);
			}
		}
	}

	button {
		display: flex;
		align-items: center;
	}

	&.action-btn {
		border-radius: 20px;
		transition: 0.2s ease;

		&:hover {
			background-color: rgba(0, 0, 0, 0.1);
			transform: scale(1.15);
		}
		button {
			background-color: transparent;
			padding: 7px 9.5px;

			p {
				font-weight: 500;
				color: ${fontBlack};
			}
		}

		&.active {
			&:hover {
				background-color: ${catYellowHover};
			}
			button {
				span,
				p {
					color: ${catYellow};
				}
			}
		}
	}

	&.vertical-btn {
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 24px;
		width: 110px;
		height: 45px;
		/* justify-content: center;
    float: right; */

		&.like-btn {
			button {
				background-color: transparent;
			}
		}

		&:hover {
			background-color: rgba(0, 0, 0, 0.06);
			transform: scale(1.15);
		}

		&.active {
			&:hover {
				background-color: ${catYellowHover};
			}

			button {
				&:hover {
					background-color: transparent;
				}
			}
		}

		> button {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 8px;
			background-color: transparent;

			span {
				margin-right: 0;
			}
			p {
				font-weight: 700;
				font-size: 14px;
				line-height: 16px;
				text-align: center;

				color: ${fontBlack};
			}
		}
	}

	&.action-btn-like {
		button {
			width: inherit;
			border-radius: 30px;
			padding: 4px 8px;
		}
	}

	span {
		font-size: 21px;
		line-height: 1em;
		font-weight: 500;
		margin-right: 4px;
	}

	@media screen and (max-width: 800px) {
		/* like-icon */
		&.vertical-btn {
			width: 80px;
			height: 40px;

			border: none;

			/* clap-icon */
			> button span {
				font-size: 18px;
			}

			/* like-count */
			> button p {
				font-weight: 500;
			}
		}

		&.action-btn {
			&:hover,
			&:focus {
				background-color: transparent;
			}
			&.active {
				&:hover {
					background-color: transparent;
				}
			}
		}
	}
`;

export const buttonComponentStyle = css``;

export const buttonsStyle = css`
	display: flex;

	&.between {
		justify-content: space-between;
	}

	.hidden-btn {
		display: none;
	}
`;
