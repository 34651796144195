import { atom } from "recoil";

export const defaultAlert = {
	open: false,
	title: "",
	description: "",
	buttons: [],
	image: null,
	events: [],
};

export const alertStore = atom({
	key: `alert_${+new Date()}`,
	default: defaultAlert,
});
