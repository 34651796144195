import { useEffect, useState } from "react";
import LabelComponent from "../LabelComponent";
import { checkboxes } from "./checkbox.css";
import CheckboxItem from "./Checkbox.item";

export default function Checkbox(params) {
  const isArrayJson = Array.isArray(params.data.data);
  const [checked, setChecked] = useState();
  const className = params?.className ?? "";

  const onChangeCheckbox = (check, name) => {
    if (isArrayJson) checked[name] = check;

    if (params?.onChange) {
      if (isArrayJson) {
        params?.onChange({
          target: { name: params.name, value: checked },
        });
      } else {
        params?.onChange({
          target: { name, value: check },
        });
      }
    } else {
      if (isArrayJson) {
        setChecked((prev) => ({ ...prev, [name]: check }));
      } else {
        setChecked(check);
      }
    }
  };

  useEffect(() => {
    setChecked(params?.checked);
  }, [params?.checked]);

  return (
    <fieldset className={className} css={checkboxes}>
      {params.data?.title && (
        <LabelComponent
          label={params.data?.title}
          required={params.data?.required}
          description={params.data?.description}
        />
      )}
      {isArrayJson ? (
        params.data.data.map((item) => {
          let value = false;
          if (checked) value = checked[item?.name];

          return (
            <CheckboxItem
              key={item.name}
              {...item}
              checked={value}
              className={item?.className || "checkbox-item"}
              onChagne={onChangeCheckbox}
            />
          );
        })
      ) : (
        <Single
          data={params.data}
          register={params?.register}
          name={params.data.name}
          onChagne={onChangeCheckbox}
          checked={checked}
        />
      )}
    </fieldset>
  );
}

function Single({ data, register, name, onChagne, checked }) {
  let value = false;
  const isNotUndefined = checked !== undefined;

  if (isNotUndefined) {
    if (typeof checked === "boolean") value = checked;
    if (Object.keys(checked).length > 0) value = checked[name];
  }

  return (
    <CheckboxItem
      {...data}
      register={register}
      checked={checked}
      onChagne={onChagne}
    />
  );
}
