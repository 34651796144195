import { useRecoilState } from "recoil";
import { POPUP_CONSTANTS } from "../components/common/Popup/constants";
import { defaultPopup, popupStore } from "../recoils/popup";

export default function usePopup() {
  const [popupState, updatePopupState] = useRecoilState(popupStore);

  function openPopup(type, data, e) {
    const { pageY, pageX } = e;
    const isMobile = window.innerWidth <= 800;
    let modalLength = 1;

    const elem = e.currentTarget;
    const rect = elem.getBoundingClientRect();

    const main = document.getElementsByTagName("main");
    const width = main[0].offsetWidth;

    if (Array.isArray(data)) {
      modalLength =
        data.length === 1 || data.length === 0 || !data ? 1.7 : data.length - 1;
    } else {
      if (type === "input") {
        // popup type is input의 top 위치 조정
        modalLength = 5;
      } else {
        // popup type is not input의 top 위치 조정
        modalLength = 6;
      }
    }

    // popup menu의 top 위치 조정
    let top =
        rect.top - rect.height * (modalLength === 2 ? 3.3 : modalLength) - 10,
      left = rect.x;

    // popup menu의 left 위치 조정
    if (isMobile && left >= window.innerWidth / 2) {
      left = window.innerWidth / 2 + 20;
    }

    if (popupState.isOpen) {
      closePopup();
      return;
    }

    if (type === POPUP_CONSTANTS.EMOJI) {
      top = `calc(100% - 480px)`;
    }

    updatePopupState({
      data,
      type,
      left,
      top,
      isOpen: true,
      width: width - 110,
    });
  }

  function closePopup() {
    updatePopupState(defaultPopup);
  }

  return { openPopup, closePopup, popupData: popupState };
}
