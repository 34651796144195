import { useEffect, useState } from "react";

export default function useLocalstorage(keys) {
  const [data, setData] = useState(null);

  const handleGetLocalStorage = () => {
    if (typeof keys === "string") {
      const value = localStorage.getItem(keys);

      if (value) setData(value);
      return;
    }

    if (Array.isArray(keys)) {
      const data = keys.reduce((acc, key) => {
        const value = localStorage.getItem(key);
        if (value) acc[key] = value;

        return acc;
      }, {});
      setData(data);
      return;
    }
  };

  useEffect(() => {
    handleGetLocalStorage();
  }, [keys]);

  return data;
}
